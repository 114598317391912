/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { withSnackbar } from "notistack";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import axios from "axios";
import Iframe from "react-iframe";

class PortfolioOnDemand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      tableData: {},
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: false,
      investorTab2: true,
      screenloader: false,
      pooldetails: {},
      formData1: {
        "First Payment Date": "",
      },
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickStandard = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
    });
    this.props.history.push("/investor/standardAnalytics");
  };

  handleClickOnDemand = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
    });
    this.props.history.push("/investor/ondemandAnalytics");
  };

  bdbapi = async () => {
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(8, 10);
    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-US", { month: "short" });
    }

    const getMonth = getMonthName(month); // Jan

    let poolidold = JSON.stringify({
      "Deal Name": {
        type: "in",
        value: [this.state.DealName],
      },
      MonthandYear: {
        type: "in",
        value: [`${getMonth}-${year}`],
      },
    });
    console.log("poolidold", poolidold);
    let mailid = this.state.Email;
    let password = this.state.Password;
    const res = await axios.get(
      process.env.react_app_base_url +
        "backendapi_wsfs_bdb/IA/reportlink?" +
        "mailid=" +
        mailid +
        "&password=" +
        password +
        "&type=On Demand"
    );
    if (res.status == 204) {
      this.setState({ bdbUrl: "UrlBdbNew", bdb_loader: false });
    } else {
      let UrlBdbNew =
        "https://analytics.demo-iaedge.intainabs.com/home/#/opendocument?data=" +
        res.data;
      console.log("111", res.data);
      this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    this.bdbapi();
  }

  render() {
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Portfolio"} />
          <div className="content">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <h3 className="headerdashboard-analytics">PORTFOLIO</h3>
                    </div>
                  </div>
                  <div className="PortfolioStds">
                    <button
                      type="button"
                      onClick={() => this.handleClickStandard()}
                      className={
                        this.state.investorTab1 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      Standard
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickOnDemand()}
                      className={
                        this.state.investorTab2 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      On-Demand
                    </button>
                  </div>
                  {/* <React.Fragment>
                    <Iframe
                      allowtransparency="true"
                      frameborder="0px"
                      border="none!important"
                      url={this.state.bdbUrl}
                      // url={pdffile}
                      width="1500px"
                      height="940px"
                      marginRight="50px"
                      id="myId"
                      className="bdb-charts"
                      display="initial"
                      position="relative"
                      scrolling="no"
                    />
                  </React.Fragment> */}
                  <div className="BdbChart">
                    <iframe
                      // allowtransparency="true"
                      // frameborder="0"
                      // style="background: #FFFFFF;"
                      src={this.state.bdbUrl}
                      // url={pdffile}
                      width="100%"
                      height="100%"
                      id="myId"
                      className="bdb-charts iframe-no-horizontal-scroll"
                      // display="block"
                      // position="absolute"
                      // overflow="hidden"
                    ></iframe>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(PortfolioOnDemand);
