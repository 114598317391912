/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import axios from "axios";
import  Iframe  from "react-iframe";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    customStylesauto
  } from "../../../../../components/customscripts/customscript";
import CloseIcon from "@material-ui/icons/Close";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";

import {
    InvestorReportData
} from "../../../../../servies/services";
import NumberComp2 from "../../../../../components/NumberComp2";
import * as moment from "moment";
import ReactModal from "react-modal";
import LinearLoader from "../../../../../components/loader/LinearLoader";

class BDBReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      bdbUrl: "",
      screenloader: false,
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      TableName: "PaymentRules",
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),
      formData: {
        Name: "",
        LastModified: "",
      },
      formData1: {
        Name: "",
        LastModified: "",
      },
      deleteRowVal: {
        Name: "",
      },
      getLoansLoader: false,
      openPopup: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      searchText: "",
      rowsSelected: null,
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
    };
  }
  showPrev = () => {
    this.props.history.push("/admin/payment_recurring");
  };
  showNext = () => {
    // this.props.history.push("/admin/tranches_recurring");
  };
  popoverBottom = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={() => this.PaymentRules(this.state.DealName)}>
          Edit Deal
        </button>
        <hr className="servicer-popover-hr" />
        <button
          onClick={() =>
            this.Loans(this.state.DealName, this.state?.NextPaymentDate)
          }
        >
          Loan Tape
        </button>
        <hr className="servicer-popover-hr" />
        <button>Change Period</button>
      </Popover>
    );
  };

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  onOpenModal = () => {
    this.setState({ open1: true });
  };
  onCloseModal = () => {
    this.setState({ open1: false });
  };
//   onOpenModal1 = () => {
//     this.setState({ open2: true });
//   };
  onCloseModal1 = () => {
    this.setState({ open2: false });
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/admin/reports",
    });
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/general_recurring");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/tranches_recurring");
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/fees_recurring");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/expenses_recurring");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/accounts_recurring");
  };
  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/tests_recurring");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsights9: false,
    });
    this.props.history.push("/admin/variable_recurring");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsights9: false,
    });
    this.props.history.push("/admin/payment_recurring");
  };
  handleClickReports = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsights9: true,
    });
  };


  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
      },
    });
     

    bdbapi = async () => {
        let x = moment(this.state.NextPaymentDate).subtract(1, "months").format('MM/DD/YYYY').toString();
        let month = x.slice(0, 2);
        let year = x.slice(8, 10);
        function getMonthName(monthNumber) {
          const date = new Date();
          date.setMonth(monthNumber-1);
        
          return date.toLocaleString('en-US', { month: 'short' });
        }
        
        const getMonth = getMonthName(month) // Jan

        let poolidold = JSON.stringify({
            "Deal Name": {
                type: "in",
                'value': this.state.DealName
            },
            Month: {
                type: "in",
                value: [`${getMonth}-${year}`],
              }
        });
        console.log("poolidold", poolidold);
        let mailid = this.state.Email;
        let password = this.state.Password;
        const res = await axios.get(process.env.react_app_base_url + "backendapi_wsfs_bdb/IA/link?"
         + 'mailid=' + mailid + '&password=' + password);
        if (res.status == 204) {
          this.setState({ bdbUrl: "UrlBdbNew", bdb_loader: false });
        } else {
          let UrlBdbNew =
            "https://analytics.intainabs.com/home/#/opendocument?data=" + res.data + "&customGlobalFilter=" +
            encodeURIComponent(poolidold);
          this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false });
        }
      };


      async componentDidMount() {
        var component = window.location.pathname;
        sessionStorage.setItem("component", component);
        this.bdbapi();
      }

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };



    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Dashboard"} />
          <div className="content">
            
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
            <div className="page-contentofpool1">
              <div className="row1">
                <div
                  className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1.5rem",
                    width: "90rem",
                  }}
                >
                  <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                    <KeyboardBackspaceIcon
                      onClick={this.goBackToDashboard}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">ANALYTICS</h3>
                  </div>
                  
                </div>
              
              </div>

              <React.Fragment>
                    <Iframe
                      // allowtransparency="true"
                      // frameborder="0"
                      // style="background: #FFFFFF;"
                      url={this.state.bdbUrl}
                      // url={pdffile}
                      width="100%"
                      height="665px"
                      id="myId"
                      className="bdb-charts"
                      display="initial"
                      position="relative"
                    />
                  </React.Fragment>

             
            </div>)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(BDBReport);
