import React, { useState, useEffect } from "react";
import { Table } from "antd";
import ThumbUp from "../../../../../images/thumb_up_alt.svg";
import ThumbDown from "../../../../../images/thumb_down_alt.svg";

function QC_Facility() {
  const [tableData, setTableData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let previousData = null; // To track the previous state of data and prevent unnecessary updates

    const fetchData = () => {
      try {
        const finalData = JSON.parse(
          sessionStorage.getItem("finalDataSummary")
        );

        if (
          finalData &&
          JSON.stringify(finalData) !== JSON.stringify(previousData)
        ) {
          previousData = finalData;

          // Process and update table data dynamically
          const dynamicTableData = {};
          ["QC"].forEach((key) => {
            if (finalData[key]) {
              dynamicTableData[key] = finalData[key].map((row, index) => ({
                key: index,
                ...row,
              }));
            }
          });
          setTableData(dynamicTableData);
          setIsLoading(false); // Mark as loaded
        }
      } catch (error) {
        console.error("Error parsing JSON from sessionStorage:", error);
        setTableData({});
        setIsLoading(false); // Stop loading on error
      }
    };

    fetchData(); // Initial fetch
  }, []);

  const generateColumns = (data) => {
    if (!data || !data.length) return [];
    const keys = Object.keys(data[0]).filter((key) => key !== "key");

    return keys.map((key) => {
      if (key === "Results" || key === "Result") {
        return {
          title: key.replace(/_/g, " "), // Replace underscores with spaces
          dataIndex: key,
          key,
          width: 150,
          onHeaderCell: () => ({
            style: {
              textAlign: "left", // Custom header alignment
            },
          }),
          render: (value, record) => {
            const isDollarColumn = key.toLowerCase().includes("($)");
            const isCountColumn = key.toLowerCase().includes("count");
            const isPercentColumn = key.toLowerCase().includes("%");
            const isNA = value === "NA";

            // Handle "isTotal" row separately
            if (record.isTotal) {
              const textAlign = isDollarColumn
                ? "right"
                : isCountColumn || isPercentColumn
                ? "center"
                : "left";

              return (
                <strong style={{ textAlign, display: "block" }}>
                  {isNA ? "N/A" : value}
                </strong>
              );
            }

            // Handle specific value formatting for non-total rows
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  src={value === "PASS" ? ThumbUp : ThumbDown}
                  alt={value === "PASS" ? "Pass" : "Fail"}
                  style={{ width: "16px", height: "16px" }}
                />
                {value}
              </div>
            );
          },
        };
      }

      // Default column configuration for other keys
      return {
        title: key.replace(/_/g, " "), // Replace underscores with spaces
        dataIndex: key,
        key,
        width: 150,
      };
    });
  };

  return (
    <div className="portfolioDash">
      <>
        <div className="workbench-table-container">
          <div className="table-section">
            {/* <label className="tableHeaders">QC</label> */}
            <Table
              columns={generateColumns(tableData["QC"])}
              dataSource={tableData["QC"]}
              pagination={false}
              className="performance-table"
              scroll={{ x: "max-content", y: 500 }}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </div>
  );
}

export default QC_Facility;
