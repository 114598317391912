import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import Strats from "./Strats";
import {
  fetchPaymentDateFilters,
  fetchPrimaryStartFilters,
} from "../../../../../servies/services";
import WaveLoad from "../../../../../images/Wave_Load.svg";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};

export default function StratsDropDowns({
  selectedDeals,
  selectedIssuer,
  selectedAssetClass,
  selectedDates,
  startDate,
  endDate,
}) {
  const [OptionsPaymentDates, setOptionsPaymentDates] = useState([]);
  const [OptionsPrimaryStarts, setOptionsPrimaryStarts] = useState([]);
  const [selectedAsOf, setSelectedAsOf] = useState(null); // Initially null
  const [selectedPrimary, setSelectedPrimary] = useState([]);
  const [downloadExcelPrimary, setDownloadExcelPrimary] = useState([]);
  const [selectedSecondary, setSelectedSecondary] = useState();
  const [selectedTertiary, setSelectedTertiary] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set to true

  // Fetch filters when selectedDeals change
  useEffect(() => {
    const fetchInitialPaymentDates = async () => {
      setLoading(true);
      try {
        const dealId = selectedDeals[0]?.value;

        if (!dealId) {
          setLoading(false);
          return;
        }

        const requestData = { dealId };

        // Fetch payment date filters
        const response = await fetchPaymentDateFilters(requestData);
        const resultData = response?.data?.result || [];
        const paymentDateOptions = resultData.map(
          ({ field_name, field_value }) => ({
            label: field_name,
            value: field_value,
          })
        );
        setOptionsPaymentDates(paymentDateOptions);

        // Set the first option as default for selectedAsOf if not set
        if (paymentDateOptions.length > 0 && !selectedAsOf) {
          setSelectedAsOf(paymentDateOptions[0]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching initial payment dates:", error);
        setLoading(false);
      }
    };

    // Run only on component mount
    fetchInitialPaymentDates();
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const fetchPrimaryFilters = async () => {
      setLoading(true);
      try {
        const dealId = selectedDeals[0]?.value;
        if (!dealId || !selectedAsOf) {
          setLoading(false);
          return;
        }

        const requestDataFilter = { dealId, month: selectedAsOf.label || "" };

        // Fetch primary strat filters
        const primaryResponse = await fetchPrimaryStartFilters(
          requestDataFilter
        );
        const primaryResultData = primaryResponse?.data?.result || [];
        const primaryStartsOptions = primaryResultData.map((field_value) => ({
          label: field_value,
          value: field_value,
        }));
        console.log({ primaryResultData });

        setDownloadExcelPrimary(primaryResultData);

        setOptionsPrimaryStarts(primaryStartsOptions);

        // Set selectedPrimary based on API response
        const defaultSelected = [
          { label: "Current Interest Rate", value: "Current Interest Rate" },
          {
            label: "Current Principal Balance",
            value: "Current Principal Balance",
          },
          { label: "Loan Status", value: "Loan Status" },
          { label: "Property State", value: "Property State" },
        ];

        const newSelectedPrimary = defaultSelected.filter((defaultOption) =>
          primaryStartsOptions.some(
            (option) => option.value === defaultOption.value
          )
        );

        // If no default options, use the first available option
        if (
          newSelectedPrimary.length === 0 &&
          primaryStartsOptions.length > 0
        ) {
          newSelectedPrimary.push(primaryStartsOptions[0]);
        }

        setSelectedPrimary(newSelectedPrimary);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching primary filters:", error);
        setLoading(false);
      }
    };

    // Run when selectedAsOf or selectedDeals change after initial mount
    if (selectedAsOf && selectedDeals.length > 0) {
      fetchPrimaryFilters();
    }
  }, [selectedAsOf, selectedDeals]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "1px solid var(--tableBorder)",
      borderRadius: "8px",
      minHeight: "38px",
      boxShadow: "none",
      color: "var(--text_Color)",
      backgroundColor: "var(--dropdown-bg)",
      '&:hover': {
        border: "1px solid var(--tableBorder)", // Keep the same border on hover
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#adaeaa",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "var(--text_Color)",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown appears above other elements
      backgroundColor: "var(--dropdown-bg)", // Background color for dropdown
      color: "var(--text_Color)", // Text color for dropdown
    }),
    option: (provided, state) => ({
      ...provided,
      whiteSpace: "nowrap", // Prevent text from wrapping in the dropdown
      backgroundColor: state.isSelected
        ? "var(--dropdown-selected-bg)" // Background for the selected option
        : state.isFocused
        ? "var(--dropdown-hover-bg)" // Background for hovered or focused option
        : "var(--dropdown-bg)", // Default background color
      color: "var(--text_Color)", // Text color for options
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the vertical separator line
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#018e82", // Set the color of the dropdown arrow
      "&:hover": {
        color: "#018e82", // Maintain the same color on hover
      },
    }),
    clearIndicator: () => ({
      display: "none", // Completely hide the clear (X) icon
    }),
  };

  const selectedValues = [
    ...selectedPrimary.map((item) => item.value),
    selectedSecondary?.value,
    selectedTertiary?.value,
  ].filter(Boolean);

  return (
    <div>
      <div className="align-strats-dropdowns">
        <div className="align-strat-side-dropdowns">
          <div>
            <h6 className="as_of_Labels">As of</h6>
            <div className="align-strat-tabs">
              <Select
                options={OptionsPaymentDates}
                value={selectedAsOf}
                onChange={setSelectedAsOf}
                isClearable
                placeholder="Select any one"
                styles={customStyles}
                components={{ DropdownIndicator }}
              />
            </div>
          </div>
          <div className="side-three-starts">
            <div className="side-prim-strat">
              <h6 className="as_of_Labels">Primary Strat</h6>
              <div className="align-strat-tabs-primary">
                <MultiSelect
                  options={OptionsPrimaryStarts}
                  value={selectedPrimary}
                  onChange={setSelectedPrimary}
                  labelledBy="Select"
                  valueRenderer={customValueRenderer}
                  className="custom-multiselect"
                />
              </div>
            </div>
            <div className="side-sec-strat">
              <h6 className="as_of_Labels">Secondary Strat</h6>
              <div className="align-strat-tabs">
                <Select
                  options={OptionsPrimaryStarts.filter(
                    (option) => !selectedValues.includes(option.value)
                  )}
                  value={selectedSecondary}
                  onChange={setSelectedSecondary}
                  isClearable
                  placeholder="Select any one"
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>
            <div>
              <h6 className="as_of_Labels">Tertiary Strat</h6>
              <div className="align-strat-tabs">
                <Select
                  options={OptionsPrimaryStarts.filter(
                    (option) => !selectedValues.includes(option.value)
                  )}
                  value={selectedTertiary}
                  onChange={setSelectedTertiary}
                  isClearable
                  placeholder="Select any one"
                  styles={customStyles}
                  components={{ DropdownIndicator }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Conditionally render Strats component */}
      {!loading && (
        <Strats
          selectedAsOf={selectedAsOf}
          selectedPrimary={selectedPrimary}
          selectedSecondary={selectedSecondary}
          selectedTertiary={selectedTertiary}
          selectedDeals={selectedDeals}
          selectedIssuer={selectedIssuer}
          selectedAssetClass={selectedAssetClass}
          selectedDates={selectedDates}
          startDate={startDate}
          endDate={endDate}
          loading={loading}
          downloadExcelPrimary={downloadExcelPrimary}
        />
      )}
      {loading && (
        <div>
          <img
            src={WaveLoad}
            style={{
              width: "100%",
              height: "10vh",
              backgroundColor: "var(--white)",
            }}
            alt="Loading Animation"
          />
        </div>
      )}
    </div>
  );
}
