import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";

function PaymentsFacility() {
  const [tableData, setTableData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let previousData = null; // To track the previous state of data and prevent unnecessary updates

    const fetchData = () => {
      try {
        const finalData = JSON.parse(
          sessionStorage.getItem("finalDataSummary")
        );

        if (
          finalData &&
          JSON.stringify(finalData) !== JSON.stringify(previousData)
        ) {
          previousData = finalData;

          // Process and update table data dynamically
          const dynamicTableData = {};
          ["Payment"].forEach((key) => {
            if (finalData[key]) {
              dynamicTableData[key] = finalData[key].map((row, index) => ({
                key: index,
                ...row,
              }));
            }
          });
          setTableData(dynamicTableData);
          setIsLoading(false); // Mark as loaded
        }
      } catch (error) {
        console.error("Error parsing JSON from sessionStorage:", error);
        setTableData({});
        setIsLoading(false); // Stop loading on error
      }
    };

    fetchData(); // Initial fetch
  }, []);

  const generateColumns = (data) => {
    if (!data || !data.length) return [];
    const keys = Object.keys(data[0]).filter((key) => key !== "key");

    return keys.map((key) => ({
      title: key.replace(/_/g, " "), // Replace underscores with spaces for better readability
      dataIndex: key,
      key,
      width: 150,
      onHeaderCell: () => ({
        style: {
          textAlign: "left", // Custom header alignment
        },
      }),
      // filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
      //   <CustomFilterComponent
      //     columnKey={key}
      //     onSort={handleSort}
      //     onFilter={handleFilter}
      //     columnValues={getUniqueValues(data, key)}
      //     closeDropdown={close}
      //   />
      // ),
      // filterIcon: () => (
      //   <FilterFilled
      //     style={{
      //       color: filteredClrChangeColumns[key] ? "green" : undefined,
      //     }}
      //   />
      // ),
      render: (text, record) => {
        const isDollarColumn = key.toLowerCase().includes("($)");
        const isWholeOrIntegerWithZeroDecimal =
          /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text);
        const isCountColumn = key.toLowerCase().includes("count");
        const isPercentColumn = key.toLowerCase().includes("%");
        const isNA = text === "NA";

        if (record.isTotal) {
          if (isNA) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          if (isDollarColumn) {
            return (
              <strong style={{ textAlign: "right", display: "block" }}>
                {text}
              </strong>
            );
          }
          if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
            return (
              <strong style={{ textAlign: "center", display: "block" }}>
                {text}
              </strong>
            );
          }
          if (isPercentColumn) {
            return (
              <strong style={{ textAlign: "center", display: "block" }}>
                {text}
              </strong>
            );
          }
          return (
            <strong style={{ textAlign: "left", display: "block" }}>
              {text}
            </strong>
          );
        }

        if (isNA) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
        if (isDollarColumn) {
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }
        if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
        if (isPercentColumn) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
        return <div style={{ textAlign: "left" }}>{text}</div>;
      },
    }));
  };

  return (
    <div className="portfolioDash">
      <>
        <div className="workbench-table-container">
          <div className="table-section">
            <label className="tableHeaders">Payments</label>
            <Table
              columns={generateColumns(tableData["Payment"])}
              dataSource={tableData["Payment"]}
              pagination={false}
              className="performance-table"
              scroll={{ x: "max-content", y: 500 }}
            />
          </div>
        </div>
      </>
    </div>
  );
}

export default PaymentsFacility;
