import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import "./DataDisplaySummary.css";

export default function DataDisplaySummary() {
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      const storedData = sessionStorage.getItem("finalDataTableSummary");
      const finalData = storedData ? JSON.parse(storedData) : [];

      // Transform the data
      const transformedData = finalData
        .map((item) =>
          Object.entries(item).map(([key, value]) => ({
            name: key,
            value: value !== "NA" ? value : "NA", // Replace "NA" with "N/A" for consistency
          }))
        )
        .flat(); // Flatten the array if the finalData has multiple objects

      setData(transformedData);
    } catch (error) {
      console.error("Error parsing JSON from sessionStorage:", error);
      setData([]);
    }
  }, []); // Only fetch once when the component mounts

  return (
    <div className="data-display">
      {data.map((item, index) => (
        <div key={index} className="data-item">
          <div className="data-value">
            {item.value}
            {item.percentage ? (
              <span className="percentage">
                {item.percentage.includes("-") ? (
                  <span className="negative-percentage">
                    <FontAwesomeIcon icon={faArrowDown} />
                    {item.percentage}
                  </span>
                ) : (
                  <span className="positive-percentage">
                    <FontAwesomeIcon icon={faArrowUp} />
                    {item.percentage}
                  </span>
                )}
              </span>
            ) : null}
          </div>
          <div className="data-label">{item.name}</div>
        </div>
      ))}
    </div>
  );
}
