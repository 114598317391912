// import React from 'react';
// import packageJson from '../package.json';
// global.appVersion = packageJson.version;
 
// // version from response - first param, local version second param
// const semverGreaterThan = (versionA, versionB) => {
//  const versionsA = versionA.split(/\./g);
 
//  const versionsB = versionB.split(/\./g);
//  while (versionsA.length || versionsB.length) {
//    const a = Number(versionsA.shift());
 
//    const b = Number(versionsB.shift());
//    // eslint-disable-next-line no-continue
//    if (a === b) continue;
//    // eslint-disable-next-line no-restricted-globals
//    return a > b || isNaN(b);
//  }
//  return false;
// };
 
// class CacheBuster extends React.Component {
//  constructor(props) {
//    super(props);
//    this.state = {
//      loading: true,
//      isLatestVersion: false,
//       refreshCacheAndReload: () => {
//         var timeout1 = 100; // clear cache
//         var timeout2 = 6000; // refresh browser
//         if (caches) {
//         setTimeout(function () {
//           // Service worker cache should be cleared with caches.delete()

//            console.log('Refresh Cache : Step 1');

//            if (caches) {

//                console.log(caches.keys().length + ' keys found');

//               caches.keys().then(async(names) => {
//                   // eslint-disable-next-line no-restricted-syntax
//                   for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
//                       var name_1 = await names_1[_i];
//                       await caches.delete(name_1);
//                       console.log(name_1 + ' keys removed');
//                   }
//               });
//           }
//       }, timeout1);
//     }

//       setTimeout(function () {
                    
//         console.log('Refresh Cache : Step 2');
//             // clear browser cache and reload page                
//             window.location.reload();
        
//     }, timeout2);
//       //  console.log('Clearing cache and hard reloading...')
//       //  if (caches) {
//       //    // Service worker cache should be cleared with caches.delete()
//       //    caches.keys().then(function(names) {
//       //      for (let name of names) caches.delete(name);
//       //    });
//       //  }
 
//       //  // delete browser cache and hard reload
//       //  window.location.reload(true);
//      }
//    };
//  }
 
//  componentDidMount() {
 
//    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
//      .then((response) => response.json())
//      .then((meta) => {
//        const latestVersion = meta.version;
//        const currentVersion = global.appVersion;
 
//        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
//        if (shouldForceRefresh) {
//          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
//          this.setState({ loading: false, isLatestVersion: false });
//        } else {
//          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
//          this.setState({ loading: false, isLatestVersion: true });
//        }
//      });
//  }
//  render() {
//    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
//    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
//  }
// }
 
// export default CacheBuster;

import React from 'react';
import packageJson from '../package.json';
global.appVersion = packageJson.version;
 
// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
 const versionsA = versionA.split(/\./g);
 
 const versionsB = versionB.split(/\./g);
 while (versionsA.length || versionsB.length) {
   const a = Number(versionsA.shift());
 
   const b = Number(versionsB.shift());
   // eslint-disable-next-line no-continue
   if (a === b) continue;
   // eslint-disable-next-line no-restricted-globals
   return a > b || isNaN(b);
 }
 return false;
};
 
class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Refreshing Cache: Step 1");
      
        if (caches) {
          caches.keys().then((cacheKeys) => {
            return Promise.all(
              cacheKeys.map((name) => {
                console.log(`Removing cache key: ${name}`);
                return caches.delete(name);
              })
            );
          })
          .then(() => {
            console.log("Refreshing Cache: Step 2");
            window.location.replace('/'); // Redirect after clearing cache
          })
          .catch((error) => {
            console.error("Error during cache refresh:", error);
          });
        }
      }
      
      // refreshCacheAndReload: () => {
      //   var timeout1 = 100; // clear cache
      //   var timeout2 = 6000; // refresh browser
 
      //   if (caches) {
      //     setTimeout(function () {
      //       console.log('Refreshing Cache: Step 1');
      //       if (caches) {
      //         caches.keys().then(async (names) => {
      //           for (let name of names) {
      //             await caches.delete(name);
      //             console.log(`${name} key removed`);
      //           }
      //         });
      //       }
      //     }, timeout1);
 
      //     setTimeout(function () {
      //       console.log('Refreshing Cache: Step 2');
      //       window.location.replace('/');  // Redirect to the home page.3

            
      //     }, timeout2);
      //   }
      // }
    };
  }
 
  componentDidMount() {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
 
        console.log(`Comparing versions: Current - ${currentVersion}, Latest - ${latestVersion}`);
 
        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`New version found: ${latestVersion}. Triggering cache refresh.`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`No update required. Current version (${currentVersion}) is up-to-date with ${latestVersion}`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch((error) => {
        console.error('Failed to fetch version info:', error);
        this.setState({ loading: false, isLatestVersion: true });
      });
  }
 
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
 }
 
 
export default CacheBuster;