import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Card,
  Typography,
  Row,
  Col,
  Input,
  AutoComplete,
  Button,
  Space,
} from "antd";
import save from "../../images/save.svg";
import download from "../../images/download.svg";
import feedback from "../../images/feedback.svg";
import IDA from "../../images/IDA.svg";
import BETA from "../../images/BETA.svg";
import Curved_Back from "../../images/Curved_Back.svg";
import Curved_Front from "../../images/Curved_Front.svg";
import Send_Btn_Msg from "../../images/Send_Btn_Msg.svg";
import Go_Back from "../../images/Go_Back.svg";
import Delete_Chat from "../../images/Delete_Chat.svg";
import "./ChatWindow.css";
import { Context } from "../../context/Context";
import ChatPlots from "./ChatPlots";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;
const { TextArea } = Input;

const ChatWindow = (props) => {
  const {
    onSent,
    recentPrompt,
    showResult,
    setShowResult,
    loading,
    resultData,
    setInput,
    input,
    prevPrompts,
    setPrevPrompts,
    setRecentPrompt,
    currentPromptIndex,
    setCurrentPromptIndex,
    showPrevPrompts,
    handleSaveClick,
    displayedPrompts,
    setDisplayedPrompts,
    selectedPrompt,
    setSelectedPrompt,
    loadPrompt,
    isModalFeedbackVisible,
    comment,
    setDocid,
    setComment,
    handleOk,
    handleCancelFeedback,
    showModal,
    setIsModalFeedbackVisible,
  } = useContext(Context);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [askQuestion, setAskQuestion] = useState(null);
  const [unsavedPrompts, setUnsavedPrompts] = useState([]);

  // Function to send user input to WebSocket server
  const checkSuggestion = (value) => {
    if (askQuestion && askQuestion.readyState === WebSocket.OPEN) {
      console.log("Sending question to WebSocket:", value);
      askQuestion.send(JSON.stringify({ query: value }));
    } else {
      console.log("WebSocket is not open or not ready.");
    }
  };

  // Function to open WebSocket connection
  useEffect(() => {
    const socket = new WebSocket(
      "wss://www.ask.intainabs.com/ida/autocomplete"
    );

    socket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        // console.log("Received message from WebSocket:", data);

        // Assuming data contains suggestions
        if (data.suggestions) {
          // console.log("Suggestions:", data.suggestions);
          const getQuestions = data.suggestions.map((item) => item.Question);
          // console.log({ getQuestions });

          setSuggestions(getQuestions);
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed.");
    };

    setAskQuestion(socket);

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  // Function to filter suggestions based on input value
  const handleSearch = (value) => {
    if (value !== "") {
      setSuggestions(
        suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        )
      );
      setInput(value);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (e.target.value !== "") {
      checkSuggestion(e.target.value);
    }
  };

  const handleSelect = (value) => {
    setInput(value);
    setSelectedPrompt(null); // Reset the selected prompt
    // onSent(value); // Call the existing method with the selected value
  };

  const handleDeleteChat = () => {
    setDocid(""); // Reset the selected prompt

    if (selectedPrompt) {
      // Filter out the selected prompt from prevPrompts
      const updatedPrompts = prevPrompts.filter(
        (prompt) => prompt !== selectedPrompt
      );

      const updatedDisplayedPrompts = displayedPrompts.filter(
        (prompt) => prompt !== selectedPrompt
      );

      // Update the context or state that holds the previous prompts
      setPrevPrompts(updatedPrompts); // Assuming this function updates the previous prompts in context
      setDisplayedPrompts(updatedDisplayedPrompts); // Update displayedPrompts
      setRecentPrompt("");
      setShowResult(false);
      setSelectedPrompt(null); // Reset the selected prompt
    } else {
      // If no selectedPrompt, remove the last item from prevPrompts
      if (prevPrompts.length > 0) {
        const updatedPrompts = prevPrompts.slice(0, -1); // Remove the last item
        setPrevPrompts(updatedPrompts); // Update the context or state
        setRecentPrompt("");
        setShowResult(false);
      }
    }
  };

  // Function to check if a string is JSON formatted
  const isJSON = (str) => {
    try {
      const parsed = JSON.parse(str);
      return parsed && typeof parsed === "object";
    } catch (error) {
      return false;
    }
  };

  // Check if resultData contains a valid JSON string
  let parsedResultData = null;
  if (resultData && typeof resultData === "string") {
    if (isJSON(resultData)) {
      parsedResultData = JSON.parse(resultData);
    } else {
      parsedResultData = resultData; // plain string
    }
  }

  // Safely extract headers and rows if parsedResultData is an object
  const headers = parsedResultData?.response?.[0] || [];
  const rows = parsedResultData?.response?.slice(1) || [];

  const getGraphs = parsedResultData?.graph || [];

  // console.log({ parsedResultData });

  // console.log("repsonse", onSent);

  useEffect(() => {
    if (props.visible) {
      setIsModalVisible(true);
    }
  }, [props.visible]);

  // useEffect(() => {
  //   if (props.visible && prevPrompts.length > 0) {
  //     setCurrentPromptIndex(prevPrompts.length - 1); // Set to the last prompt in history
  //   }
  // }, [prevPrompts]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const handleSaveClick = () => {
  //   const finalPrompt = recentPrompt;
  //   savePromptToHistory(finalPrompt); // Save prompt to history
  //   // if (finalPrompt.trim() !== "") {
  //   //   console.log("Saved prompt to history:", finalPrompt);
  //   // }
  // };
  const handlePrevPrompt = () => {
    // console.log({ prevPrompts, currentPromptIndex });

    if (currentPromptIndex !== null && currentPromptIndex > 0) {
      const prevPrompt = prevPrompts[currentPromptIndex - 1];
      loadPrompt(prevPrompt, currentPromptIndex - 1);
    } else {
      console.log("No previous prompt available");
    }
  };

  const handleNextPrompt = () => {
    if (
      currentPromptIndex !== null &&
      currentPromptIndex < prevPrompts.length - 1
    ) {
      const nextPrompt = prevPrompts[currentPromptIndex + 1];
      loadPrompt(nextPrompt, currentPromptIndex + 1);
    } else {
      console.log("No next prompt available");
    }
  };

  return (
    <div>
      <Modal
        title=""
        open={isModalVisible}
        onCancel={handleCancel}
        destroyOnClose={false}
        closable={false}
        style={{ padding: "20px 0" }}
        width="auto"
        height="100vh"
        centered
        footer={null}
        className="modalStyle"
        modalRender={(modalContent) => (
          <div
            className="modal-content"
            style={{
              // minHeight: "95vh",
              minWidth: "95vw",
            }}
          >
            {modalContent}
          </div>
        )}
      >
        {/* <button
          onClick={() =>
            onSent(
              "What is the average loan balance of loans which are 180+ dq for Test P deal?"
            )
          }
        >
          Send Question
        </button>
        <p>Response from server: {response}</p> */}
        {/* Header Content */}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <Col className="top_left_Icons">
            <span className="icon-spacing">
              <img src={Go_Back} alt="Go_Back" onClick={handleCancel} />
            </span>
            {/* <span className="icon-spacing">Credit Capital Corporation</span> */}
          </Col>
          <Col justify="space-between">
            <span className="icon-spacing" onClick={handleDeleteChat}>
              <img src={Delete_Chat} alt="Delete Chat" />
            </span>
            <span className="icon-spacing">
              <img src={download} alt="download" />
            </span>
          </Col>
        </Row>

        {/* Bottom Input Section */}
        <Row
          justify="space-between"
          align="start"
          style={{ marginTop: "20px" }}
          // className="BottomChat"
          gutter={24}
        >
          <Col span={6}>
            <Text className="history" strong>
              History
            </Text>
            <div style={{ padding: "20px 0" }} className="chat-msgs">
              {/* {showPrevPrompts &&
                prevPrompts
                  .slice()
                  .reverse()
                  .map((item, index) => {
                    const actualIndex = prevPrompts.length - 1 - index; // Calculate the original index before reversing
                    return (
                      <div key={actualIndex}>
                        <Text
                          className="recent-entry"
                          onClick={() => loadPrompt(item, actualIndex)} // Pass the actual index
                          style={{
                            color: selectedPrompt === item ? "#018E82" : "",
                            cursor: "pointer",
                          }}
                        >
                          {item}
                        </Text>
                        <hr className="horizontal_seperator" />
                      </div>
                    );
                  })} */}

              {showPrevPrompts && displayedPrompts.length > 0 && (
                <div>
                  {displayedPrompts
                    .slice() // Create a shallow copy of the array
                    .reverse() // Reverse the order of the elements
                    .map((prompt, index) => (
                      <div key={index}>
                        <Text
                          className="recent-entry"
                          onClick={() => loadPrompt(prompt, index)} // Add the onClick handler here
                          style={{
                            color: selectedPrompt === prompt ? "#018E82" : "", // Conditional color for selectedPrompt
                            cursor: "pointer",
                          }}
                        >
                          {prompt} {/* Display the current prompt */}
                        </Text>
                        <hr className="horizontal_seperator" />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </Col>

          {/* Middle section - Graphs */}
          <Col
            span={18}
            style={{
              borderRadius: "10px", // Smooth border-radius for the container
              border: "1px solid var(--tableBorder)", // Optional border for visual clarity
              height: "75vh", // Height for the scrollable area (adjust as needed)
              overflowY: "clip",
              position: "relative", // Required for positioning the fixed element
              paddingBottom: "100px", // Bottom padding to ensure the content doesn't overlap with IDA_Virtual
              backgroundColor: "var(--chatWindow_Color)",
            }}
          >
            {/* Scrollable content */}
            <Row style={{ marginBottom: "20px", paddingTop: "10px" }}>
              <Col span={2}>
                <span className="icon-spacing">
                  <img
                    src={Curved_Back}
                    alt="Curved_Back"
                    onClick={handlePrevPrompt}
                  />
                </span>
                <span className="icon-spacing">
                  <img
                    src={Curved_Front}
                    alt="Curved_Front"
                    onClick={handleNextPrompt}
                  />
                </span>
              </Col>

              <Col span={16}>
                <Title level={5}>
                  <span className="as_of_Labels">Interpreted As :</span>
                  <span className="as_of_Labels">
                    {loading ? (
                      // Render loader while loading is true
                      <div className="loader-chat">
                        {/* This is where the loader (like a spinner) appears */}
                        <hr />
                      </div>
                    ) : showResult ? (
                      // Render the result if loading is false and showResult is true
                      parsedResultData?.inferredAs || "N/A" // Show parsed data or "N/A" if undefined
                    ) : // Render nothing if showResult is false
                    null}
                  </span>
                </Title>
              </Col>

              <Col
                justify="space-between"
                span={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <span className="icon-spacing" onClick={handleSaveClick}>
                  <img src={save} alt="save" />
                </span>
                <span className="icon-spacing" onClick={showModal}>
                  <img src={feedback} alt="feedback" />
                </span>
              </Col>
            </Row>
            <div className="scrollable-content-chat">
              <div style={{ padding: "10px" }}>
                {!showResult ? (
                  <div className="suggested-questions">
                    <Text
                      className="suggested-question-needed"
                      onClick={() =>
                        onSent("Can you please provide portfolio summary")
                      }
                    >
                      Can you please provide portfolio summary
                    </Text>

                    <Text
                      className="suggested-question-needed"
                      onClick={() =>
                        onSent("What is the Pool WAC for my portfolio?")
                      }
                    >
                      What is the Pool WAC for my portfolio?
                    </Text>

                    <Text
                      className="suggested-question-needed"
                      onClick={() =>
                        onSent(
                          "What is the top 5 property State wise distribution?"
                        )
                      }
                    >
                      What is the top 5 property State wise distribution?
                    </Text>

                    <Text
                      className="suggested-question-needed"
                      onClick={() =>
                        onSent("Can you please provide Bond Summary")
                      }
                    >
                      Can you please provide Bond Summary
                    </Text>
                  </div>
                ) : (
                  <div className="result">
                    <div className="result-title">
                      <img
                        src={IDA}
                        alt="IDA"
                        style={{
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                        }} // Avatar style
                      />
                      <h4 className="as_of_Labels">{recentPrompt}</h4>
                    </div>
                    <div className="result-data">
                      {loading ? (
                        <div className="loader-chat">
                          <hr />
                          <hr />
                          <hr />
                        </div>
                      ) : (
                        <div>
                          {typeof parsedResultData === "string" ? (
                            <p>{parsedResultData}</p> // Show plain string if not JSON
                          ) : headers.length > 0 ? (
                            <>
                              <table className="styled-table specific-table">
                                <thead>
                                  <tr>
                                    {Array.isArray(headers) &&
                                      headers.map((header, index) => (
                                        <th key={index}>{header}</th>
                                      ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(rows) &&
                                    rows.map((row, rowIndex) => (
                                      <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                          <td key={cellIndex}>{cell}</td>
                                        ))}
                                      </tr>
                                    ))}
                                </tbody>
                              </table>

                              {/* Check if graph data is present and not an empty array, then render the chart */}
                              {parsedResultData?.graph &&
                                parsedResultData.graph.length > 0 && (
                                  <ChatPlots
                                    graphData={JSON.stringify(
                                      parsedResultData.graph
                                    )} // Ensure to pass as a string
                                  />
                                )}
                            </>
                          ) : (
                            <p>No data available.</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Fixed row at the bottom of the container */}
            <Row
              className=""
              style={{
                position: "absolute", // Keep the row fixed at the bottom
                bottom: 0, // Align it to the bottom of the container
                left: 0,
                borderRadius: "0 0 10px 10px",
                width: "100%", // Ensure full width
                backgroundColor: "var(--chatWindow_Color)", // White background to match the design
                padding: "10px", // Add padding around the content
                zIndex: 10, // Stay above scrolling content
                // boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Shadow for slight separation
                borderTop: "1px solid var(--tableBorder)",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start", // Proper spacing between content
              }}
            >
              {/* Avatar and Beta tag */}
              <div
                className="IDA_Icons"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px", // Space between icon and input
                  marginLeft: "10px", // Space between icon and input
                  top: "5px",
                  position: "relative",
                }}
              >
                <img
                  src={IDA}
                  alt="VirtualAsst"
                  style={{ width: "38px", height: "38px", borderRadius: "50%" }} // Avatar style
                />
                <img
                  src={BETA}
                  alt="VirtualAsst"
                  className="beta"
                  style={{ height: "18px", width: "38px" }}
                />
              </div>

              {/* AutoComplete Input */}
              <div style={{ position: "relative", width: "93%" }}>
                <AutoComplete
                  value={input}
                  options={
                    input.trim() !== "" // Check if input is not empty
                      ? suggestions.map((suggestion) => ({
                          value: suggestion,
                        }))
                      : [] // Empty options if input is empty
                  }
                  onSearch={handleSearch}
                  onSelect={handleSelect}
                  style={{
                    width: "100%",
                    border: "none",
                    borderRadius: "10px",
                    paddingLeft: "15px",
                  }}
                  popupClassName="autocompleteSS"
                  dropdownStyle={{
                    backgroundColor: "var(--dropdownBackgroundColor)", // Change to your desired color
                    color: "var(--dropdownTextColor)", // Text color for suggestions
                  }}
                >
                  <Input
                    placeholder="Get the Accurate Answers and Create Reports"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && input.trim() !== "") {
                        onSent(input);
                        setSelectedPrompt(null);
                      }
                    }}
                    value={input}
                    className="chatInput-text"
                    onChange={handleInputChange}
                    style={{
                      paddingRight: "40px",
                      borderRadius: "10px",
                      border: "1px solid #8C8C8C",
                      color: "var(--tableText)",
                      backgroundColor: "var(--chatWindow_Color)",
                    }}
                  />
                </AutoComplete>

                {/* Send button inside the input */}
                <img
                  src={Send_Btn_Msg}
                  alt="Send Button"
                  style={{
                    width: "24px",
                    height: "24px",
                    position: "absolute",
                    right: "15px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={() => {
                    if (input.trim() !== "") {
                      onSent(input);
                      setSelectedPrompt(null);
                    }
                  }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Leave feedback for Ida"
        visible={isModalFeedbackVisible}
        onCancel={handleCancelFeedback}
        footer={[
          <Button key="cancel" onClick={handleCancelFeedback}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            style={{ backgroundColor: "#018e82" }}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Space direction="horizontal">
          <LikeOutlined style={{ fontSize: "24px" }} />
          <LikeOutlined
            style={{ fontSize: "24px", transform: "rotate(180deg)" }}
          />
        </Space>
        <Input.TextArea
          placeholder="Type comment here"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={4}
          style={{ marginTop: "16px" }}
        />
      </Modal>
    </div>
  );
};

export default ChatWindow;
