/* eslint-disable require-jsdoc */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import CloseIcon from '@material-ui/icons/Close';
import { customStyles, customStylesauto } from '../../../components/customscripts/customscript';
import Sidebar1 from "../../../components/sidebar/sidebar";
import ReactModal from 'react-modal';
import { withSnackbar } from 'notistack';
import ItemDetails from './Details';
import { getuserByOrgname,approveUser } from "../../../servies/services";
import Loader from '../../../components/loader';
import Tooltip from '@material-ui/core/Tooltip';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem('token'),
      peers: JSON.parse(sessionStorage.getItem('peers')),
      userid: sessionStorage.getItem("userid"),
      OrgName: sessionStorage.getItem("OrgName"),
      loading: true,
      getLoansLoader: false,
      open: false,
      message: '',
      table_data: [],
      ApproveStatus: '',
      OrgName: '',
      status: '',
      activeInsights1: true,
      activeInsights2: true,
    };
  }

  handleClickPending = () => {
    this.setState ({
      activeInsights1: true,
      activeInsights2: false,
    });
  this.props.history.push("/admin/users/" + this.state.OrgName + "/Pending");
  }

  handleClickApproved = () => {
    this.setState ({
      activeInsights1: false,
      activeInsights2: true,
    });
  this.props.history.push("/admin/users/wsfstrustee/Approved");
  }

  async selectedpoolid(selected) {
    const arr = []
    for (var i = 0; i < selected.length; i++) {
      var j = selected[i];
      let PoolID = this.state.table_data[j].UserID;
      arr.push(PoolID);
    }
    console.log("selected Loans", arr);
    sessionStorage.setItem("user_list", JSON.stringify(arr));
  }


  approveUserAction = async (UserID, UserName, ApproveStatus, OrgName) => {
    const data =
    {
      "UserName": UserName,
      "OrgName": OrgName,
      "token": this.state.token,
      "peers": this.state.peers,
      "UserID": UserID,
      "ApproveStatus": ApproveStatus,   
      "DealType":"admin"   
    }
    const APIResponse = await approveUser(OrgName,data)
    console.log("approveUser", APIResponse)

    if (APIResponse.data.success == "true") {
      const message = "Submitted Successfully";
      this.props.enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: 2000,
      });

    } else {
      const message = "Not saved";
      this.props.enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
    const orgname = OrgName
    const status = this.state.status
    this.filterMethod(orgname, status)
  }

  onOpenModal1(value) {
    console.log("MODAL " + value);
    this.setState({ open1: true, value: value });
  };

  onCloseModal1 = () => {
    this.setState({ open1: false, loadingmodal: false });
  };


  async componentDidMount() {
    const orgname = this.props.match.params.orgname;
    const status = this.props.match.params.status;
  
    this.setState({ 
      OrgName: orgname, 
      status: status,
      activeInsights1: status === "Pending",  // Set active state dynamically
      activeInsights2: status === "Approved", // Set active state dynamically
    });
  
    this.filterMethod(orgname, status);
  }
  
  componentWillReceiveProps(nextProps) {
    const orgname = nextProps.match.params.orgname;
    const status = nextProps.match.params.status;
  
    this.setState({ 
      OrgName: orgname, 
      status: status,
      activeInsights1: status === "Pending",  // Update active state dynamically
      activeInsights2: status === "Approved", // Update active state dynamically
    });
  
    this.filterMethod(orgname, status);
  }
  
  handleClickPending = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
    });
  
    // Navigate to Pending page
    this.props.history.push(`/admin/users/${this.state.OrgName}/Pending`);
  };
  
  handleClickApproved = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
    });
  
    // Navigate to Approved page
    this.props.history.push(`/admin/users/${this.state.OrgName}/Approved`);
  };
  

  async filterMethod(orgname, status) {
    console.log("orgname",orgname, status);

    this.setState({ orgname: orgname, ApproveStatus: status });

    if (orgname !== undefined) {
      if (status !== "Approved") {
        const APIResponse = await getuserByOrgname(orgname)
        console.log("APIResponse", APIResponse)
        const orgname_details = APIResponse.data.filter(item => item.OrgName == orgname)
        console.log("orgname_details", orgname_details)
        const users = APIResponse.data.filter(item => item.ApproveStatus !== "Approved")
        console.log("users", users)
        this.setState({ loading: false, table_data: users })
      } else {
        const APIResponse = await getuserByOrgname(orgname)
        console.log("getuserByOrgname", getuserByOrgname)
        const orgname_details = APIResponse.data.filter(item => item.OrgName == orgname)
        console.log("orgname_details", orgname_details)
        const users = APIResponse.data.filter(item => item.ApproveStatus == status)
        console.log("users", users)
        this.setState({ loading: false, table_data: users })
      }
    } else {
      // this.setState({ formAction: "add", pageTitle: "Create a Invoice" })
      const APIResponse = await getuserByOrgname(orgname)
      console.log("getuserByOrgname", getuserByOrgname)

      const orgname_details = APIResponse.data.filter(item => item.OrgName == orgname)
      console.log("orgname_details", orgname_details)
      const users = orgname_details.filter(item => item.ApproveStatus == status)
      console.log("users", users)
      this.setState({ loading: false, table_data: users })
    }
  }

  onCloseModal1 = () => {
    this.setState({ open1: false, loadingmodal: false });
  };

  render() {

    const options = {
      responsive: 'stacked',
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: '580px',
      filterType: 'dropdown',
      search: true,
      print: true,
      viewColumns: true,
      download: true,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      // onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      rowsSelected: this.state.rowsSelected,
      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map(row => row.dataIndex) });
        const selected = allRows.map(row => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },
      searchText: this.state.searchText,
      searchPlaceholder: 'Search Users',
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach(col => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },
      loading: false,
      textLabels: {
        body: {
          noMatch: this.state.loading === false ?
            'Sorry, there is no matching data to display' :
            <Loader msg={"Please wait, loading data"} />,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };

    const columns = [
      {
        name: 'UserID',
        label: 'User Id',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div>
              <React.Fragment>
                <Tooltip title={value+"123"} aria-label="add">
                  <React.Fragment>
                    {value.slice(0, 7) + "..."}
                  </React.Fragment>
                </Tooltip>
              </React.Fragment>
            </div>
          ),
        },
      },
      {
        name: 'UserName',
        label: 'User Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'FirstName',
        label: 'First Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'LastName',
        label: 'Last Name',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'EmailID',
        label: 'Email Id',
        options: {
          filter: false,
          sort: false,
        },
      },

      {
        name: 'OrgName',
        label: 'Org Name',
        options: {
          filter: false,
          sort: false
        },
      },      
      {
        name: 'MobileNumber',
        label: 'Mobile Number',
        options: {
          filter: false,
          sort: false
        },
      },
      {
        name: 'ApproveStatus',
        label: 'Status',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div style={{ position: 'relative' }}>
              <React.Fragment>
                {value}
              </React.Fragment> </div>
          ),
        }
      },
      {
        name: 'ApproveStatus',
        label: 'Action',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <div className="action_cell" style={{ display: "flex", gap: "10px" }}>
      
              <React.Fragment>
                {value === "Approved" && (
                  <Tooltip title={"Reject User"} aria-label="Reject User">
                    <Button
                      style={{
                        border:"none !important",
                        color: "#018E82", 
                        
                      }}
                      onClick={() => {
                        this.approveUserAction(
                          tableMeta.rowData[0],
                          tableMeta.rowData[1],
                          "Rejected",
                          tableMeta.rowData[5]
                        );
                      }}
                      disabled={tableMeta.rowData[1] === "admin"}
                    >
                      Reject
                    </Button>
                  </Tooltip>
                )}
      
                {value === "Pending" && (
                  <React.Fragment>
                    <Tooltip title={"Approve User"} aria-label="Approve User">
                      <Button
                        style={{
                          border:"none !important",
                          color: "#018E82", // Green for Approve
                          
                        }}
                        onClick={() => {
                          this.approveUserAction(
                            tableMeta.rowData[0],
                            tableMeta.rowData[1],
                            "Approved",
                            tableMeta.rowData[5]
                          );
                        }}
                      >
                        Approve
                      </Button>
                    </Tooltip>
      
                    <Tooltip title={"Reject User"} aria-label="Reject User">
                      <Button
                        style={{
                          border:"none !important",
                          color: "#018E82", // Red for Reject
                          
                        }}
                        onClick={() => {
                          this.approveUserAction(
                            tableMeta.rowData[0],
                            tableMeta.rowData[1],
                            "Rejected",
                            tableMeta.rowData[5]
                          );
                        }}
                        disabled={tableMeta.rowData[1] === "admin"}
                      >
                        Reject
                      </Button>
                    </Tooltip>
                  </React.Fragment>
                )}
      
                {value === "Rejected" && (
                  <Tooltip title={"Approve User"} aria-label="Approve User">
                    <Button
                      style={{
                        border:"none !important",
                        color: "#018E82", // Green for Approve
                        
                      }}
                      onClick={() => {
                        this.approveUserAction(
                          tableMeta.rowData[0],
                          tableMeta.rowData[1],
                          "Approved",
                          tableMeta.rowData[5]
                        );
                      }}
                    >
                      Approve
                    </Button>
                  </Tooltip>
                )}
              </React.Fragment>
            </div>
          ),
        },
      }
      
    ];

    return (
      <React.Fragment>
        <div className="page">
        <Sidebar1 activeComponent={"PendingDashBoard"} />
          <div className="content">
            <div className="page-content">
            <div className="row1 arrow-dealcontent">
                <div
                  className="col-12 col-sm-6 col-md-7 d-flex hellocard"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1.5rem",
                    width: "90rem",
                  }}
                >
                  <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                    <h3 className="headerdashboard">LIST OF USERS</h3>
                  </div>
                  <div className="row row1 movement-tabs">
                <div>
                  <div className="tablechangebuttonloans">
                    <button
                      type="button"
                      onClick={() => this.handleClickPending()}
                      className={
                        this.state.activeInsights1 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      Pending
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickApproved()}
                      className={
                        this.state.activeInsights2 == true
                          ? "issuerDashboard-table-top-button-workbench-active"
                          : "issuerDashboard-table-top-button-workbench"
                      }
                    >
                      Approved
                    </button>
                    </div>
                </div>
                </div>
                </div>
              </div>
              <MUIDataTable
                data={this.state.table_data}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        </div>

        <ReactModal
          isOpen={this.state.open1}
          contentLabel="Minimal Modal Example"
          style={customStyles}
          onRequestClose={this.onCloseModal1}
        >
          <React.Fragment>
            <div className="modalPopup">
              <h2>User Details</h2>
              <Button className="closePopup" style={{ minWidth: '30px' }} variant="text" color="primary" onClick={this.onCloseModal1}> <CloseIcon></CloseIcon> </Button>
              <div>
                <ItemDetails dataFromParent={this.state.value} />
              </div>
            </div>
          </React.Fragment>
        </ReactModal>

      </React.Fragment>
    );
  }
}

export default withSnackbar(Users);