import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import Plot from "react-plotly.js";
import NumberComp2 from "../../../../../components/NumberComp2";
import Accordion from "../../../../../components/Accordion/Accordion.js";
import { DownOutlined, FilterFilled } from "@ant-design/icons";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import fIlter from "../../../../../images/FilterMappedTape.svg";
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

const processSummaryTrancheData = (data) => {
  if (!data || typeof data !== "object" || Array.isArray(data)) {
    return [];
  }

  const keys = Object.keys(data);
  if (!keys.includes("Tranche") || !Array.isArray(data["Tranche"])) {
    return [];
  }

  const length = data["Tranche"].length;
  if (length === 0) {
    return [];
  }

  const tableData = [];

  for (let i = 0; i < length; i++) {
    const row = { key: i };

    keys.forEach((key) => {
      row[key] =
        data[key] && Array.isArray(data[key]) && data[key][i] !== undefined
          ? data[key][i]
          : "-";
    });

    tableData.push(row);
  }

  return tableData;
};

function SummaryDash() {
  const [plotData, setPlotData] = useState({
    DA_delinquent_percentage: { data: [], layout: {} },
    DA_summary_Collateral_Performance: { data: [], layout: {} },
    DA_summary_bond_Summary: { data: [], layout: {} },
    DA_summary_paydown: { data: [], layout: {} },
  });

  const [summaryTrancheData, setSummaryTrancheData] = useState([]);
  const [tableTitles, setTableTitles] = useState([]);
  const plotContainerRef = useRef(null); // Ref to track the container
  const [tableData, setTableData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filteredClrChangeColumns, setFilteredClrChangeColumns] = useState({});
  const [currentBatch, setCurrentBatch] = useState(1);
  const [sortOrder, setSortOrder] = useState({});
  const itemsPerBatch = 45;

  useEffect(() => {
    const finalData = JSON.parse(sessionStorage.getItem("finalDataSummary"));

    if (finalData && Object.keys(finalData).length > 0) {
      const getTableKeys = Object.keys(finalData);
      setTableTitles(getTableKeys);
    } else {
      console.log("finalData is empty or not found in session storage.");
    }

    if (finalData) {
      const parsePlotData = (data) => {
        let parsedData = { data: [], layout: {} };

        if (typeof data === "string") {
          parsedData = JSON.parse(data);
        } else if (typeof data === "object" && data !== null) {
          parsedData = data;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        parsedData.layout.width =
          plotContainerRef.current?.offsetWidth || window.innerWidth;
        parsedData.layout.autosize = true;

        return parsedData;
      };

      const DA_delinquent_percentage = parsePlotData(
        finalData["Delinquencies"]
      );
      const DA_summary_Collateral_Performance = parsePlotData(
        finalData["Collateral Performance"]
      );
      const DA_summary_bond_Summary = parsePlotData(finalData["Bond Summary"]);
      const DA_summary_paydown = parsePlotData(finalData["Paydown (Monthly)"]);

      setPlotData({
        DA_delinquent_percentage,
        DA_summary_Collateral_Performance,
        DA_summary_bond_Summary,
        DA_summary_paydown,
      });

      if (finalData["Tranche Summary"]) {
        const processedData = processSummaryTrancheData(
          finalData["Tranche Summary"]
        );
        setSummaryTrancheData(processedData);
        setTableData(processedData);
        setDisplayData(processedData);
      }
    }

    const handleResize = () => {
      setPlotData((prevPlotData) => ({
        ...prevPlotData,
        DA_delinquent_percentage: {
          ...prevPlotData.DA_delinquent_percentage,
          layout: {
            ...prevPlotData.DA_delinquent_percentage.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
        DA_summary_Collateral_Performance: {
          ...prevPlotData.DA_summary_Collateral_Performance,
          layout: {
            ...prevPlotData.DA_summary_Collateral_Performance.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
        DA_summary_bond_Summary: {
          ...prevPlotData.DA_summary_bond_Summary,
          layout: {
            ...prevPlotData.DA_summary_bond_Summary.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
        DA_summary_paydown: {
          ...prevPlotData.DA_summary_paydown,
          layout: {
            ...prevPlotData.DA_summary_paydown.layout,
            width: plotContainerRef.current?.offsetWidth || window.innerWidth,
          },
        },
      }));
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
      setPlotData({}); // Optional: Clear plot data on unmount if necessary
    };
  }, []);

  // Generate columns dynamically based on the data keys
  const generateColumns = (specialColumnData) => {
    if (!summaryTrancheData.length) return [];

    const keys = Object.keys(summaryTrancheData[0]).filter(
      (key) => key !== "key"
    );

    const getAlignmentStyle = (key, text) => {
      const lowerKey = key.toLowerCase();
      const isDollar = lowerKey.includes("($)");
      const isPercent = lowerKey.includes("%");
      const isCount = lowerKey.includes("count");
      const isNumeric = /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text);

      if (isDollar) return { textAlign: "right" };
      if (isPercent || isCount || isNumeric) return { textAlign: "center" };
      return { textAlign: "left" };
    };

    return keys.map((key, index) => ({
      title: key.replace(/_/g, " "), // Format column header
      dataIndex: key,
      key,
      width: 150,
      onHeaderCell: (column) => {
        return {
          style: {
            textAlign: "center", // Adjust as needed
          },
        };
      },
      sortOrder: sortOrder[key],
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
        <CustomFilterComponent
          columnKey={key}
          onSort={handleSort}
          onFilter={handleFilter}
          columnValues={getUniqueValues(displayData, key)}
          closeDropdown={close}
        />
      ),
      filterIcon: () => (
        <img
          src={fIlter}
          alt="Custom Filter Icon"
          style={{
            width: "15px", // Adjust size as needed
            height: "10px",
            color: filteredClrChangeColumns[key] ? "#018e82" : undefined, // Optional: Apply conditional styling
          }}
        />
      ),
      render: (text) => {
        const alignmentStyle = getAlignmentStyle(key, text);
        return <div style={alignmentStyle}>{text}</div>;
      },
      ...(specialColumnData && index === 0
        ? { width: "150px", fixed: "left" } // Special handling for first column
        : {}),
    }));
  };

  const handleFilter = (tableName, data, columnKey, criteria) => {
    console.log({ criteria });

    const updatedCriteria = { ...filterCriteria };

    if (criteria) {
      // Add or update filter criteria
      updatedCriteria[columnKey] = criteria;
      setFilteredClrChangeColumns((prev) => ({ ...prev, [columnKey]: true }));
    } else {
      // Remove filter criteria if no criteria provided
      delete updatedCriteria[columnKey];
      setFilteredClrChangeColumns((prev) => ({ ...prev, [columnKey]: false }));
    }

    setFilterCriteria(updatedCriteria);

    // Apply all active filters
    const filteredData = displayData.filter((item) =>
      Object.entries(updatedCriteria).every(([key, c]) => {
        if (!c) return true;

        const { condition1, value1, operator, condition2, value2 } = c;

        const applyCondition = (val, cond, target) => {
          switch (cond) {
            case "equals":
              return val === target;
            case "less_than":
              return val < target;
            case "greater_than":
              return val > target;
            case "not_equals":
              return val !== target;
            default:
              return false;
          }
        };

        const c1 = applyCondition(item[key], condition1, value1);
        const c2 = applyCondition(item[key], condition2, value2);

        return operator === "AND" ? c1 && c2 : c1 || c2;
      })
    );

    // Combine sorting logic if any active sort order is present
    let finalData = filteredData;
    if (sortOrder.columnKey) {
      finalData = [...filteredData].sort((a, b) => {
        const vA = a[sortOrder.columnKey];
        const vB = b[sortOrder.columnKey];

        if (!isNaN(vA) && !isNaN(vB))
          return sortOrder.order === "ascend" ? vA - vB : vB - vA;
        if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB)))
          return sortOrder.order === "ascend"
            ? new Date(vA) - new Date(vB)
            : new Date(vB) - new Date(vA);

        return vA?.localeCompare(vB) || 0;
      });
    }

    // Update table data with filtered and sorted results
    setTableData(finalData.slice(0, currentBatch * itemsPerBatch));
  };

  const handleSort = (tableName, columnKey, order) => {
    const sortedData = [...tableData].sort((a, b) => {
      const vA = a[columnKey];
      const vB = b[columnKey];

      // Check if both values are valid numbers
      if (!isNaN(parseFloat(vA)) && !isNaN(parseFloat(vB))) {
        return order === "ascend"
          ? parseFloat(vA) - parseFloat(vB)
          : parseFloat(vB) - parseFloat(vA);
      }

      // Check if both values are valid dates
      if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB))) {
        return order === "ascend"
          ? new Date(vA) - new Date(vB)
          : new Date(vB) - new Date(vA);
      }

      // Handle string comparison for non-numeric, non-date values
      return order === "ascend"
        ? vA?.localeCompare(vB) || 0
        : vB?.localeCompare(vA) || 0;
    });

    // Update the state with sorted data
    setTableData(sortedData.slice(0, itemsPerBatch));
  };

  return (
    <div className="portfolioDash">
      <div></div>
      <Accordion>
        <div className="workbench-table-container">
          <label className="tableHeaders">{tableTitles[1]}</label>
          <div className="dash-table-alignments">
            <Table
              columns={generateColumns(tableData)}
              className="performance-table"
              pagination={false}
              dataSource={tableData}
              // bordered={false}
              scroll={{
                x: "max-content",
                y:
                  summaryTrancheData.length > 5
                    ? "calc(100vh - 500px)"
                    : undefined,
              }}
            />
          </div>
        </div>
      </Accordion>
      <div className="chart-container-portfoilo">
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.DA_delinquent_percentage.data}
            layout={plotData.DA_delinquent_percentage.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "zoom",
                "resetScale2d",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.DA_summary_bond_Summary.data}
            layout={plotData.DA_summary_bond_Summary.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.DA_summary_Collateral_Performance.data}
            layout={plotData.DA_summary_Collateral_Performance.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                "zoom3d",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.DA_summary_paydown.data}
            layout={plotData.DA_summary_paydown.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                // "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
              ],
              scrollZoom: true,
            }}
            className="plot-inline"
          />
        </div>
      </div>
    </div>
  );
}

export default SummaryDash;
