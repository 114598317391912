/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Header from "../../../../../components/header";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import { withSnackbar } from "notistack";
import * as moment from "moment";
import NumberComp2 from "../../../../../components/NumberComp2";
import { DownloadExcel } from "../../../../../servies/services";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Sidebar from "../../../../../components/sidebar";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  getAllVersionsByPaymentDate,
  getAllPaymentDatesByDealName,
  ViewEsmaReport,
} from "../../../../../servies/services";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import AccordIcon from "../../../../../images/AccordIcon.png";
import * as ExcelJS from "exceljs";
import json2xml from "json2xml";
import { debounce } from "lodash";
import FilterMappedTape from "../../../../../images/FilterMappedTape.svg";
import borderLeft from "../../../../../images/border-left.png";
import borderBottom from "../../../../../images/border-bottom.png";
import rightArrow from "../../../../../images/RightPointer.png";
import ArrowDownward from "../../../../../images/arrow_downward.png";
import ArrowUpward from "../../../../../images/arrow_upward.png";

class Annex12 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAccordian: false,
      isAccordian1: false,
      isAccordian2: false,
      isFullScreen: false,
      downArrow: false,
      tableData: [],
      tableData1: [],
      tableData2: [],
      annex12Records: {},
      DealName: !sessionStorage.getItem("dealname")
        ? sessionStorage.getItem("getDashboardDeals")
          ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
              (item) => item[0]
            )[0]
          : null // Handle missing getDashboardDeals
        : sessionStorage.getItem("dealname"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      Version: sessionStorage.getItem("version"),
      VersionsChange: [],
      Assetclass: sessionStorage.getItem("Assetclass"),
      isESMA_Flag: sessionStorage.getItem("isESMA_Flag"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),

      getlastPaymentDates: [],
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      formLoader1: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "",
      sortAscending: true,
      showTable: true,
      showTable1: true,
      showTable2: true,
      filterConditions: {},
      filteredColumn: [],
      filteredData: [],
      // showTable3: true,
    };
    this.handleButtonClick = debounce(this.handleButtonClick.bind(this), 100);
  }

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  showPrev = () => {
    this.props.history.push("/admin/annex");
  };
  showNext = () => {
    this.props.history.push("/admin/annex14");
  };

  popoverBottomDownload = () => {
    return (
      <Popover className="servicer-popover-container">
        <button onClick={this.handleDownloadExcel}>Excel</button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleDownloadXML}>XML</button>
        <hr className="servicer-popover-hr" />
        <button onClick={this.handleDownloadCSV}>CSV</button>
      </Popover>
    );
  };

  sortA = (sortColumn) => {
    console.log("", this.state.MapDataKeys);
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return -1;
      if (a[sortColumn] > b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  sortZ = (sortColumn) => {
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] > b[sortColumn]) return -1;
      if (a[sortColumn] < b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  toggleFilter = () => {
    this.setState((prevState) => ({
      isFilterActive: !prevState.isFilterActive,
    }));
  };

  handleClickCancel = (column) => {
    let temp = this.state.filteredColumn.filter((col) => col !== column);
    console.log("columnname", column, this.state.filteredColumn);
    this.setState((prevState) => {
      const updatedFilterConditions = {
        ...prevState.filterConditions,
        [column]: {
          condition1: "Equals",
          value1: "",
          condition2: "Equals",
          value2: "",
          logic: "OR", // Default logic
        },
      };

      const filteredData = prevState.tableData.filter((row) => {
        return Object.keys(updatedFilterConditions).every((col) => {
          const { condition1, value1, condition2, value2, logic } =
            updatedFilterConditions[col];

          if (value1 || value2) {
            const isFirstConditionMet = this.checkCondition(
              row[col],
              condition1,
              value1,
              col
            );
            const isSecondConditionMet = this.checkCondition(
              row[col],
              condition2,
              value2,
              col
            );

            // Use logic to determine whether to apply AND or OR
            if (logic === "AND") {
              return isFirstConditionMet && isSecondConditionMet;
            }
            return isFirstConditionMet || isSecondConditionMet;
          }
          return true;
        });
      });

      return {
        filterConditions: updatedFilterConditions,
        filteredData: filteredData.length > 0 ? filteredData : [{}], // Restore full data if no matches
      };
    });
    document.body.click();
  };

  handleFilterSubmit = (column, filterConditions) => {
    const { condition1, value1, condition2, value2, logic } = filterConditions;
    console.log("Filter conditions:", filterConditions);

    const currentData =
      this.state.filteredData.length > 0
        ? this.state.filteredData
        : this.state.tableData;

    const filteredData = currentData.filter((row) => {
      const isFirstConditionMet = this.checkCondition(
        row[column],
        condition1,
        value1,
        column
      );
      const isSecondConditionMet = this.checkCondition(
        row[column],
        condition2,
        value2,
        column
      );

      // Apply AND or OR based on logic
      return logic === "AND"
        ? isFirstConditionMet && isSecondConditionMet
        : isFirstConditionMet || isSecondConditionMet;
    });

    // Check if filteredData is empty
    if (filteredData.length === 0) {
      this.setState({ filteredData: [{ message: "Data not found" }] });
    } else {
      this.setState({ filteredData });
    }

    console.log("Filtered Data:", filteredData);

    let temp = [...this.state.filteredColumn];
    if (!temp.includes(column)) {
      temp.push(column);
    }
    this.setState({ filteredColumn: temp });

    document.body.click();
  };

  checkCondition = (cellValue, condition, value, column) => {
    if (cellValue === null || cellValue === undefined || cellValue === "") {
      return false; // Skip rows with empty values
    }

    const parsedCellValue = isNaN(Number(cellValue))
      ? cellValue
      : Number(cellValue);
    const parsedValue = isNaN(Number(value)) ? value : Number(value);

    console.log("parsed", parsedCellValue, parsedValue);
    switch (condition) {
      case "Equals":
        return parsedCellValue === parsedValue;
      case "Not Equals":
        return parsedCellValue !== parsedValue;
      case "Greater than":
        return parsedCellValue > parsedValue;
      case "Lesser than":
        return parsedCellValue < parsedValue;
      default:
        return true;
    }
  };
  updateFilterCondition = (column, field, value) => {
    this.setState(
      (prevState) => ({
        filterConditions: {
          ...prevState.filterConditions,
          [column]: {
            ...prevState.filterConditions[column],
            [field]: value,
          },
        },
      }),
      () => {
        console.log("Updated filterConditions:", this.state.filterConditions); // Debug log
      }
    );
  };

  popoverBottom = (column) => {
    const { filterConditions } = this.state;
    const columnFilterConditions = filterConditions[column] || {
      condition1: "",
      value1: "",
      logic: "OR",
      condition2: "",
      value2: "",
    };

    const isFilterActive = this.state.isFilterActive;
    const triangleImage = isFilterActive ? rightArrow : rightArrow;

    // Removed conditions and displaying all filter options
    const filterOptions = [
      "Equals",
      "Greater than",
      "Lesser than",
      "Not Equals",
    ];

    let uniqueValues = [];
    if (this.state.tableData.length > 0) {
      uniqueValues = [
        ...new Set(this.state.tableData.map((row) => row[column])),
      ];
    }

    const renderInputField = (condition, valueField) => {
      return (
        <select
          className="popover-input-wide dropdownicon"
          value={columnFilterConditions[valueField]}
          onChange={(e) =>
            this.updateFilterCondition(column, valueField, e.target.value)
          }
        >
          <option value="">Select value</option>
          {uniqueValues.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    };

    const popoverClass =
      this.state.popoverPlacement === "left"
        ? "popover-container-sort-left"
        : "popover-container-sort-right";

    return (
      <Popover className={popoverClass}>
        <div className="popover-content">
          <div className="popover-row">
            <img src={ArrowDownward} alt="arrow-downward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortA(column)}
            >
              Sort A to Z
            </button>
          </div>
          <div className="popover-row">
            <img src={ArrowUpward} alt="arrow-upward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortB(column)}
            >
              Sort Z to A
            </button>
          </div>
          <div>
            <p>Custom Filter</p>
            <div className="popover-row">
              <select
                className="popover-input"
                value={columnFilterConditions.condition1}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition1",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition1, "value1")}
            </div>
            <div className="popover-radio-group">
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="AND"
                  checked={columnFilterConditions.logic === "AND"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label>AND</label>
              </div>
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="OR"
                  checked={columnFilterConditions.logic === "OR"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label>OR</label>
              </div>
            </div>
            <div className="popover-row">
              <select
                className="popover-input"
                value={columnFilterConditions.condition2}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition2",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition2, "value2")}
            </div>
            <div className="popover-bottom-buttons">
              <button
                type="button"
                className="popupbutton22"
                onClick={() => this.handleClickCancel(column)}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  this.handleFilterSubmit(column, columnFilterConditions);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    );
  };

  handleButtonClick() {
    this.setState((prevState) => ({
      downArrow: !prevState.downArrow,
    }));
  }

  handleOverlayHide = () => {
    this.setState({ downArrow: false });
  };

  handleClickAnnex = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
    });
    this.props.history.push("/admin/annex");
  };

  handleClickAnnex12 = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
    });
    this.props.history.push("/admin/annex12");
  };

  handleClickAnnex14 = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
    });
    this.props.history.push("/admin/annex14");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard",
    });
  };

  handleDealNameChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("dealname", newValue);
    this.setState({ DealName: newValue, isDisable: true }, async () => {
      console.log("pppppp", this.state.DealName);
      await this.getAllPaymentDatesByDealName();
      await this.getAllVersionsByPaymentDate();

      if (this.state.isLoansProcessed === "No") {
        this.props.history.push("/admin/general_recurring");
      }

      const { Version, getDashboardVersions } = this.state;
     
      if (
        (Version === "WIP" || Version === "V1") &&
        getDashboardVersions.length === 1
      ) {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      } else {
        function getMaxVersion(versions) {
          let highestNumber = -1;
          let prefix = "";

          for (const version of versions) {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }

          if (highestNumber >= 0) {
            return prefix + highestNumber;
          } else {
            // Handle the case where no valid version numbers were found
            return null;
          }
        }

        const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

        if (Version !== latestVersion) {
          // If the selected version is not the latest, call DealRecurring
          this.setState({ isDisable: this.state.isDisable });
          await this.ViewEsmaReport();
        } else {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        }
      }
    });
  };

  getAllPaymentDatesByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    const { NextPaymentDate } = this.state;

    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllPaymentDatesByDealName(data);

    console.log("getAllPaymentDatesByDealName", APIResponse.data);
    if (APIResponse.status === 200) {
      const paymentDates = APIResponse.data.PaymentDates;
      console.log(paymentDates, "payment");

      const [nextMonth, nextDay, nextYear] = NextPaymentDate.split("/");

      // Find the first payment date in the same month and year
      const sameMonthYearDate = paymentDates.find((date) => {
        const [month, day, year] = date.split("/");
        return month === nextMonth && year === nextYear;
      });

      const nextPaymentDate = sameMonthYearDate || paymentDates[0]; // Default to the first available date if none matches

      console.log("nextPaymentDate", nextPaymentDate);
      // // Determine the value for nextpaymentdate
      // const nextPaymentDate = paymentDates.includes(NextPaymentDate)
      //   ? NextPaymentDate
      //   : paymentDates[0];

      // Update sessionStorage with the determined nextpaymentdate
      sessionStorage.setItem("nextpaymentdate", nextPaymentDate);

      // Update sessionStorage with the dashboard dates
      sessionStorage.setItem("getDashboardDates", JSON.stringify(paymentDates));

      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleAccordian = () => {
    this.setState((prevState) => {
      return { isAccordian: !prevState.isAccordian };
    });
  };
  handleAccordian1 = () => {
    this.setState((prevState) => {
      return { isAccordian1: !prevState.isAccordian1 };
    });
  };
  handleAccordian2 = () => {
    this.setState((prevState) => {
      return { isAccordian2: !prevState.isAccordian2 };
    });
  };

  // handleDownloadExcel = () => {
  //   const tables = document.querySelectorAll(".downloadDiffFormats");

  //   // Create a new Excel workbook
  //   const wb = new ExcelJS.Workbook();

  //   // Iterate over each table
  //   tables.forEach((table) => {
  //     // Extract the table's header and rows
  //     const headerCells = Array.from(
  //       table.querySelectorAll("h1.headerdashboard1")
  //     );
  //     const rows = Array.from(
  //       table.querySelectorAll(".workbench-table-container table tbody tr")
  //     );

  //     // Create a new Excel worksheet for each table
  //     const ws = wb.addWorksheet(headerCells[0].textContent);

  //     // Add the header row to the worksheet
  //     const headerRow = ws.addRow(headerCells.map((cell) => cell.textContent));
  //     headerRow.eachCell((cell) => {
  //       cell.fill = {
  //         type: "pattern",
  //         pattern: "solid",
  //         fgColor: { argb: "003aff" },
  //       };
  //     });

  //     // Add the data rows to the worksheet
  //     rows.forEach((row) => {
  //       const rowData = Array.from(row.querySelectorAll("td")).map(
  //         (cell) => cell.textContent
  //       );
  //       ws.addRow(rowData);
  //     });
  //   });

  //   // Write the workbook to a buffer
  //   wb.xlsx
  //     .writeBuffer()
  //     .then((buffer) => {
  //       // Create a Blob from the buffer
  //       const blob = new Blob([buffer], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });

  //       // Create a URL for the Blob
  //       const url = URL.createObjectURL(blob);

  //       // Create a link element and trigger download
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "Annex12.xlsx";
  //       a.click();

  //       // Revoke the URL object
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error("Error generating Excel file:", error);
  //     });
  // };

  handleDownloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("ESMA - Annex12");

    const { annex12Records } = this.state;

    let startRow = 1;

    for (const tableName in annex12Records) {
      const tableData = annex12Records[tableName];

      // Add table name as header with orange font color
      const tableHeaderRow = worksheet.addRow([tableName]);
      tableHeaderRow.font = { color: { argb: "003aff" }, bold: true };

      // Get the headers from the first row of data
      const headers = Object.keys(tableData[0]);
      const headerRow = worksheet.addRow(headers); // Add header row
      headerRow.font = { color: { argb: "e56811" }, bold: true }; // Set font color to orange

      // Add table data
      tableData.forEach((row) => {
        const values = Object.values(row);
        worksheet.addRow(values);
      });

      worksheet.addRow([]);

      startRow += tableData.length + 2; // Add 2 for spacing between tables
    }

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const filename = "ESMA - Annex12.xlsx";

        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = window.URL.createObjectURL(blob);
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        }
      })
      .catch((err) => {
        console.error("Error writing Excel:", err);
      });
  };

  handleDownloadXML = () => {
    const { annex12Records } = this.state;

    console.log(">>>>", annex12Records);

    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?><root>'; // Start with XML declaration and root element

    for (const tableName in annex12Records) {
      const tableData = annex12Records[tableName];

      xmlContent += `<${tableName}>`;
      tableData.forEach((row) => {
        xmlContent += json2xml({ [`${tableName}Item`]: row }, { header: true });
      });

      xmlContent += `</${tableName}>`;
    }

    xmlContent += "</root>"; // Close the root element

    const blob = new Blob([xmlContent], {
      type: "text/xml",
    });

    const filename = "ESMA - Annex12.xml";

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    }
  };
  handleDownloadCSV = () => {
    const { annex12Records } = this.state;

    let csvContent = "data:text/csv;charset=utf-8,";

    for (const tableName in annex12Records) {
      const tableData = annex12Records[tableName];

      // Add table name as header
      csvContent += `${tableName}\n`;

      // Get the headers from the first row of data
      const headers = Object.keys(tableData[0]);
      csvContent += `${headers.join(",")}\n`;

      // Add table data
      tableData.forEach((row) => {
        const values = Object.values(row).map((value) => {
          // Check if the value contains a comma, if yes, enclose it within double quotes
          if (value.includes(",")) {
            return `"${value}"`;
          }
          return value;
        });
        csvContent += `${values.join(",")}\n`;
      });

      csvContent += "\n"; // Add empty line for spacing between tables
    }

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "ESMA - Annex12.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };

  ViewEsmaReport = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      tableData1: [],
      tableData2: [],
      loading: true,
      screenloader: true,
    });
    let x = this.state.NextPaymentDate;
    console.log();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;

    data.peer = this.state.peer;
    data.Version = this.state.Version;

    console.log("datatatata", data);
    const APIResponse = await ViewEsmaReport(data);
    console.log("ViewEsmaReport", APIResponse);
    if (APIResponse.status === 200 && APIResponse.data.isSuccess === true) {
      const annex12Data = APIResponse.data.data["Annex 12"];
      const securitisation = annex12Data["Securitisation Information"];
      const testTriggerEvents = annex12Data["Test/Trigger/Events Information"];
      const cashflow = annex12Data["Cash-Flow Information"];

      const Asset_Class = sessionStorage.getItem("Assetclass");
      let annexNumber;

      // Set annex number based on asset class
      if (this.state.isESMA_Flag === "Yes") {
        switch (Asset_Class) {
          case "Residential Real Estate":
            annexNumber = 2;
            break;
          case "Commercial Mortgage":
            annexNumber = 3;
            break;
          case "Corporate Loans":
            annexNumber = 4;
            break;
          case "Auto Loans":
            annexNumber = 5;
            break;
          case "Consumer Loans":
            annexNumber = 6;
            break;
          case "Credit Cards":
            annexNumber = 7;
            break;
          case "Leasing":
            annexNumber = 8;
            break;
          case "Esoteric":
            annexNumber = 9;
            break;
          case "Non-Performing Loans":
            annexNumber = 10;
            break;
          case "Asset Backed Commercial Papers":
            annexNumber = 11;
            break;
          default:
            annexNumber = "";
            break;
        }
      } else {
        annexNumber = "";
      }
      const buttonName = `Annex ${annexNumber}`;

      this.setState(
        {
          getLoansLoader: false,
          tableData: securitisation,
          tableData1: testTriggerEvents,
          tableData2: cashflow,
          annex12Records: annex12Data,
          loading: false,
          screenloader: false,
          openModalPayment: false,
          buttonName: buttonName,
        },
        () => {
          const filterConditions = this.state.MapDataKeys.reduce(
            (acc, column) => {
              acc[column] = {
                condition1: "Equals",
                value1: "",
                condition2: "Equals",
                value2: "",
                logic: "OR",
              };
              return acc;
            },
            {}
          );
          this.setState({ filterConditions });
        }
      );
    } else if (
      APIResponse.status === 200 &&
      APIResponse.data.isSuccess === false
    ) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        screenloader: false,
        openModalPayment: false,
        buttonName: "",
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    sessionStorage.setItem("nextpaymentdate", newValue);
    this.setState({ NextPaymentDate: newValue, isDisable: true }, async () => {
      console.log("pppppp", this.state.NextPaymentDate);
      await this.getAllVersionsByPaymentDate();

      if (this.state.isLoansProcessed === "No") {
        this.props.history.push("/admin/general_recurring");
      }
      const { Version, getDashboardVersions } = this.state;

      if (
        (Version === "WIP" || Version === "V1") &&
        getDashboardVersions.length === 1
      ) {
        this.setState({ isDisable: false }, async () => {
          sessionStorage.setItem("version", "WIP");
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      } else {
        function getMaxVersion(versions) {
          let highestNumber = -1;
          let prefix = "";

          for (const version of versions) {
            const numberMatch = version.match(/\d+/); // Extract the number using regex
            if (numberMatch) {
              const number = parseInt(numberMatch[0]);
              if (number > highestNumber) {
                highestNumber = number;
                prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
              }
            }
          }

          if (highestNumber >= 0) {
            return prefix + highestNumber;
          } else {
            // Handle the case where no valid version numbers were found
            return null;
          }
        }

        const latestVersion = getMaxVersion(getDashboardVersions); // Assuming the latest version is at the beginning of the array

        if (Version !== latestVersion) {
          // If the selected version is not the latest, call DealRecurring
          this.setState({ isDisable: this.state.isDisable });
          await this.ViewEsmaReport();
        } else {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
            this.setState({ checkLoadStatus: false });
          });
        }
      }
    });
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true, screenloader: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );
      sessionStorage.setItem(
        "isLoansProcessed",
        APIResponse.data.LoanProcessed
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
          isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the Version state with the selected value
    sessionStorage.setItem("version", newValue);
    this.setState(
      {
        Version: newValue,
        isDisable: true,
      },
      async () => {
        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState({ isDisable: false, Version: "WIP" }, async () => {
            await this.ViewEsmaReport();
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            // If the selected version is not the latest, call ViewEsmaReport
            this.setState({ isDisable: this.state.isDisable });
            this.ViewEsmaReport();
          } else {
            this.setState({ isDisable: false, Version: "WIP" }, async () => {
              await this.ViewEsmaReport();
            });
          }
        }
      }
    );
  };

  componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("isTabChange", false);
    sessionStorage.setItem("AddedContent", []);
    sessionStorage.setItem("TranchItems", []);
    if (
      this.state.isLoansProcessed === "No" ||
      sessionStorage.getItem("isESMA_Flag") === "No"
    ) {
      this.props.history.push("/admin/general_recurring");
    }
    const { Version, getDashboardVersions } = this.state;

    if (
      (Version === "WIP" || Version === "V1") &&
      getDashboardVersions.length === 1
    ) {
      this.setState({ isDisable: false }, async () => {
        sessionStorage.setItem("version", "WIP");
        await this.ViewEsmaReport();
        this.setState({ checkLoadStatus: false });
      });
    } else {
      function getMaxVersion(versions) {
        let highestNumber = -1;
        let prefix = "";

        for (const version of versions) {
          const numberMatch = version.match(/\d+/); // Extract the number using regex
          if (numberMatch) {
            const number = parseInt(numberMatch[0]);
            if (number > highestNumber) {
              highestNumber = number;
              prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
            }
          }
        }

        if (highestNumber >= 0) {
          return prefix + highestNumber;
        } else {
          // Handle the case where no valid version numbers were found
          return null;
        }
      }
      const latestVersion = getMaxVersion(getDashboardVersions);
      if (Version !== latestVersion) {
        // If the selected version is not the latest, call DealRecurring
        this.setState({ isDisable: this.state.isDisable });
      } else {
        this.setState({ isDisable: false, Version: "WIP" }, async () => {
          await this.ViewEsmaReport();
          this.setState({ checkLoadStatus: false });
        });
      }
    }

    // if (this.state.Version === "V1" || this.state.Version === "V2") {
    //   this.setState({ isDisable: this.state.isDisable });
    //   this.ViewEsmaReport();
    // } else {
    //   this.setState({ isDisable: false });
    //   this.ViewEsmaReport();
    // }
  }
  //   async componentDidMount() {
  //     var component = window.location.pathname;
  //     sessionStorage.setItem("component", component);
  //     this.ViewAccountTable();
  //   }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: " 1px solid var(--tableBorder) !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even) > td:first-child": {
              backgroundColor: "var(--white) !important",
              position: "relative",
              "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: "var(--tdEven) !important",
              },
              "&:first-child": {
                position: "sticky",
                left: 0,
                top: 0,
                zIndex: 1,
                backgroundColor: "var(--white)",
                color: "var(--tableText) !important",
              },
              "& > *": {
                color: "var(--tableText) !important",
              },
            },
            "&:nth-child(even)": {
              backgroundColor: "var(--tdEven) !important",
              color: "var(--tableText) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "var(--white) !important",
              color: "var(--tableText) !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
            color: "var(--tableText) !important",
          },
        },

        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            color: "var(--tableText) !important",
            borderBottom: "var(--tableBorder) !important",
            "&::after": {
              content: "",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "var(--white)",
              zIndex: -99,
            },
            "@media (min-width: 800px)": {
              "&:first-child": {
                position: "sticky",
                left: 0,
                top: 0,
                zIndex: 1,
                backgroundColor: "var(--white)",
              },
            },
          },
        },

        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "var(--table-header-bg) !important",
            color: "var(--headerText) !important",
            borderBottom: "var(--tableBorder) !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
            position: "relative",
            zIndex: 1,
            "&:first-child": {
              position: "sticky",
              left: 0,
              zIndex: 500,
              top: 0,
            },
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "var(--tableBorder) !important",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
            backgroundColor: "var(--white)",
            position: "relative",
            color: "var(--tableText) !important",
            border: " 1px solid var(--tableBorder) !important",
          },
        },
      },
    });

  render() {
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },

      {
        name: "Data Cut-Off Date",
        label: "Data Cut-Off Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },

      {
        name: "Securitisation Name",
        label: "Securitisation Name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Reporting Entity Name",
        label: "Reporting Entity Name",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Reporting Entity Contact Person",
        label: "Reporting Entity Contact Person",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Reporting Entity Contact Telephone",
        label: "Reporting Entity Contact Telephone",
        options: {
          filter: true,
          sort: true,
          // customBodyRender: (value, tableMeta, updateValue) => {
          //   return (
          //     <div
          //       style={{
          //         paddingLeft: "2rem",
          //       }}
          //     >
          //       <React.Fragment>
          //         <NumberComp2 value={value}></NumberComp2>
          //       </React.Fragment>
          //     </div>
          //   );
          // },
        },
      },
      {
        name: "Reporting Entity Contact Emails",
        label: "Reporting Entity Contact Emails",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Risk Retention Method",
        label: "Risk Retention Method",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Risk Retention Holder",
        label: "Risk Retention Holder",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Underlying Exposure Type",
        label: "Underlying Exposure Type",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Risk Transfer Method",
        label: "Risk Transfer Method",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Trigger Measurements/Ratios",
        label: "Trigger Measurements/Ratios",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "4rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Revolving/ Ramp-Up Period End-Date",
        label: "Revolving/ Ramp-Up Period End-Date",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Principal Recoveries In The Period",
        label: "Principal Recoveries In The Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Recoveries In The Period",
        label: "Interest Recoveries In The Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Principal Collections In The Period",
        label: "Principal Collections In The Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Interest Collections In The Period",
        label: "Interest Collections In The Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Drawings Under Liquidity Facility",
        label: "Drawings Under Liquidity Facility",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Securitisation Excess Spread",
        label: "Securitisation Excess Spread",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Excess Spread Trapping Mechanism",
        label: "Excess Spread Trapping Mechanism",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Current Overcollateralisation",
        label: "Current Overcollateralisation",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "4rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Annualised Constant Prepayment Rate",
        label: "Annualised Constant Prepayment Rate",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Dilutions",
        label: "Dilutions",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Gross Charge Offs In The Period",
        label: "Gross Charge Offs In The Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Repurchased Exposures",
        label: "Repurchased Exposures",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Restructured Exposures",
        label: "Restructured Exposures",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Annualised Constant Default Rate",
        label: "Annualised Constant Default Rate",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Defaulted Exposures",
        label: "Defaulted Exposures",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Defaulted Exposures CRR",
        label: "Defaulted Exposures CRR",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Risk Weight Approach",
        label: "Risk Weight Approach",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Obligor Probability Of Default in Range [0.00%,0.10%]",
        label: "Obligor Probability Of Default in Range [0.00%,0.10%]",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Obligor Probability Of Default in Range [0.10%,0.25%]",
        label: "Obligor Probability Of Default in Range [0.10%,0.25%]",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Obligor Probability Of Default in Range [0.25%,1.00%]",
        label: "Obligor Probability Of Default in Range [0.25%,1.00%]",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Obligor Probability Of Default in Range [1.00%,7.50%]",
        label: "Obligor Probability Of Default in Range [1.00%,7.50%]",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Obligor Probability Of Default in Range [7.50%,20.00%]",
        label: "Obligor Probability Of Default in Range [7.50%,20.00%]",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Obligor Probability Of Default in Range [20.00%,100.00%]",
        label: "Obligor Probability Of Default in Range [20.00%,100.00%]",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Internal Loss Given Default Estimate",
        label: "Internal Loss Given Default Estimate",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 1-29 Days",
        label: "Arrears 1-29 Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 30-59 Days",
        label: "Arrears 30-59 Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 60-89 Days",
        label: "Arrears 60-89 Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 90-119 Days",
        label: "Arrears 90-119 Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 120-149 Days",
        label: "Arrears 120-149 Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 150-179 Days",
        label: "Arrears 150-179 Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Arrears 180+ Days",
        label: "Arrears 180+ Days",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "0.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    const columns2 = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Cashflow Item Identifier",
        label: "Original Cashflow Item Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "New Cashflow Item Identifier",
        label: "New Cashflow Item Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Cashflow Item",
        label: "Cashflow Item",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Amount Paid During Period",
        label: "Amount Paid During Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "3rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Available Funds Post",
        label: "Available Funds Post",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    const columns1 = [
      {
        name: "Unique Identifier",
        label: "Unique Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Original Test/Event/Trigger Identifier",
        label: "Original Test/Event/Trigger Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "New Test/Event/Trigger Identifier",
        label: "New Test/Event/Trigger Identifier",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Description",
        label: "Description",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Threshold Level",
        label: "Threshold Level",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Actual Value",
        label: "Actual Value",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingRight: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Cure Period",
        label: "Cure Period",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Calculation Frequency",
        label: "Calculation Frequency",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "1.5rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
      {
        name: "Consequence for Breach",
        label: "Consequence for Breach",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div
                style={{
                  paddingLeft: "2rem",
                }}
              >
                <React.Fragment>
                  <NumberComp2 value={value}></NumberComp2>
                </React.Fragment>
              </div>
            );
          },
        },
      },
    ];

    const { showTable, showTable1, showTable2 } = this.state;
    const sortedDeals = [...this.state.getDashboardDeals].sort();
    return (
      <React.Fragment>
        <div className="page">
          <Sidebar activeComponent={"ESMA Reporting"} />
          <div className="content">
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        paddingBottom: "1.6rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      <h3 className="headerdashboard">ESMA REPORTING</h3>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        alignItems: "right",
                        gap: "5rem",
                      }}
                    ></div>
                  </div>
                </div>
                <div className="row row14 move-dropdowns-annexs">
                  <div className="general-InvContianer">
                    <div>
                      <label className="label">Deal Name</label>
                      <div>
                        <select
                          className="input-select-general-new1-deal"
                          value={this.state?.DealName}
                          onChange={this.handleDealNameChange}
                        >
                          {sortedDeals.map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="general-InvContianer">
                    <div>
                      <label className="label">Payment Date</label>
                      {/* <h6 className="dealInfo1">
                          {this.state?.NextPaymentDate}
                        </h6> */}
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={this.state?.NextPaymentDate}
                          onChange={this.handlePaymentDateChange}
                        >
                          {this.state.getDashboardDates?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="general-InvContianer">
                    <div>
                      <label className="label">Version</label>
                      {/* <h6 className="dealInfo1">{this.state?.Version}</h6> */}
                      <div>
                        <select
                          className="input-select-general-new1"
                          value={
                            this.state.Version === "WIP"
                              ? sessionStorage.getItem("version")
                              : this.state.Version
                          }
                          onChange={this.handleVersionChange}
                        >
                          {this.state.getDashboardVersions?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "-35px" }}>
                  <div className="row row1 movement-tabs-annexs">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px",
                      }}
                    >
                      <div>
                        {this.state.buttonName ? (
                          <button
                            type="button"
                            onClick={() => this.handleClickAnnex()}
                            className={
                              this.state.activeInsights1 == true
                                ? "issuerDashboard-table-top-button-rejected-active"
                                : "issuerDashboard-table-top-button-rejected"
                            }
                          >
                            {this.state.buttonName}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => this.handleClickAnnex()}
                            className={
                              this.state.activeInsights1 == true
                                ? "issuerDashboard-table-top-button-rejected-active"
                                : "issuerDashboard-table-top-button-rejected"
                            }
                          >
                            Annex
                          </button>
                        )}
                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex12()}
                          className={
                            this.state.activeInsights2 == true
                              ? "issuerDashboard-table-top-button-insights-active"
                              : "issuerDashboard-table-top-button-insights"
                          }
                        >
                          Annex 12
                        </button>

                        <button
                          type="button"
                          onClick={() => this.handleClickAnnex14()}
                          className={
                            this.state.activeInsights3 == true
                              ? "issuerDashboard-table-top-button-workbench-active"
                              : "issuerDashboard-table-top-button-workbench"
                          }
                        >
                          Annex 14
                        </button>
                      </div>
                    </div>
                    <div>
                      {" "}
                      {this.state.createStatus === "Update" ? null : (
                        <OverlayTrigger
                          rootClose
                          trigger="click"
                          placement="bottom"
                          overlay={this.popoverBottomDownload()}
                          onExited={this.handleOverlayHide}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleButtonClick}
                          >
                            Download
                            {this.state.downArrow ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </Button>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>

                  <div className="downloadDiffFormats">
                    <div className="row row1 movement-header-annexs">
                      <div className="investor-heading-container">
                        <h1 className="headerdashboard1">
                          Securitization Information
                        </h1>
                      </div>
                      <div>
                        <img
                          src={AccordIcon}
                          alt="Accordian"
                          className={
                            this.state.isAccordian
                              ? "AccordImgAnnexReverse"
                              : "AccordImgAnnex"
                          }
                          onClick={this.handleAccordian}
                        />
                      </div>
                    </div>
                    {!this.state.isAccordian ? (
                      <React.Fragment>
                        <div className="workbench-table-container movement-table-annexs">
                          <MuiThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable
                              // title={'Dashboard'}
                              data={this.state.tableData}
                              columns={columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <div className="row row1 movement-header-annexs">
                      <div className="investor-heading-container">
                        <h1 className="headerdashboard1">
                          Test/Triggers/Events Information
                        </h1>
                      </div>
                      <div>
                        <img
                          src={AccordIcon}
                          alt="Accordian"
                          className={
                            this.state.isAccordian1
                              ? "AccordImgAnnexReverse"
                              : "AccordImgAnnex"
                          }
                          onClick={this.handleAccordian1}
                        />
                      </div>
                    </div>
                    {!this.state.isAccordian1 ? (
                      <React.Fragment>
                        <div className="workbench-table-container movement-table-annexs">
                          <MuiThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable
                              // title={'Dashboard'}
                              data={this.state.tableData1}
                              columns={columns1}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <div className="row row1 movement-header-annexs">
                      <div className="investor-heading-container">
                        <h1 className="headerdashboard1">
                          Cash-Flow Information
                        </h1>
                      </div>
                      <div>
                        <img
                          src={AccordIcon}
                          alt="Accordian"
                          className={
                            this.state.isAccordian2
                              ? "AccordImgAnnexReverse"
                              : "AccordImgAnnex"
                          }
                          onClick={this.handleAccordian2}
                        />
                      </div>
                    </div>
                    {!this.state.isAccordian2 ? (
                      <React.Fragment>
                        <div className="workbench-table-container movement-table-annexs">
                          <MuiThemeProvider theme={this.getMuiTheme()}>
                            <MUIDataTable
                              // title={'Dashboard'}
                              data={this.state.tableData2}
                              columns={columns2}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>

                <div>
                  <React.Fragment>
                    <>
                      <div className="btn_move_Needed">
                        <div
                          className="btn_prev"
                          onClick={(e) => this.showPrev(e)}
                        >
                          Previous
                        </div>
                        <button
                          className="btn_next"
                          onClick={(e) => this.showNext(e)}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  </React.Fragment>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Annex12);
