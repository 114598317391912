/* eslint-disable require-jsdoc */
import React, { Component } from "react";
// import { connect } from 'react-redux';
import Header from "../../../../../components/header";
import Sidebar from "../../../../../components/sidebar";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import "react-input-range/lib/css/index.css";
import Popover from "react-bootstrap/Popover";

// import Spreadsheet from "react-spreadsheet";
// import { Category } from "@material-ui/icons";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import * as moment from "moment";
import {
  dealPreviewMapFieldsRecurring,
  CalculatePrePayments,
  CalculatePayments,
  CalculateConsolidatedPayments,
  DownloadExcel,
  getDealDetailsByDealName,
  getAllVersionsByPaymentDate,
} from "../../../../../servies/services";
import SearchIcon from "@material-ui/icons/Search";
import { TableVirtuoso } from "react-virtuoso";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ArrowDownward from "../../../../../images/arrow_downward.png";
import ArrowUpward from "../../../../../images/arrow_upward.png";
import FilterMappedTape from "../../../../../images/FilterMappedTape.svg";
// import * as moment from "moment";
import rightArrow from "../../../../../images/RightPointer.png";

// import {
// MuiPickersUtilsProvider,
// KeyboardTimePicker,
// KeyboardDatePicker,
// } from "@material-ui/pickers";
// import MomentUtils from "@date-io/moment";

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};
class LoanTapes_InvRecurring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFullScreen: false,
      tableData: [],
      DealName:
        sessionStorage.getItem("dealname") === null
          ? sessionStorage.getItem("servicerdealname")
          : sessionStorage.getItem("dealname"),
      ServicerName:
        sessionStorage.getItem("Servicer") === null
          ? sessionStorage.getItem("servicerdashboardname")
          : sessionStorage.getItem("Servicer"),
      detailsDate:
        sessionStorage.getItem("selectdate") === null
          ? sessionStorage.getItem("selectservicerdate")
          : sessionStorage.getItem("selectdate"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      formLoader1: false,
      screenloader: false,
      MapDataValues: [],
      MapDataKeys: [],
      data: [],
      searchTerm: "",
      isDataFound: true,
      isSearchOpen: false,
      sortColumn: "",
      sortAscending: true,
      filterConditions: {},
      filteredColumn: [],
      filteredData: [],
      overlayVisible: false,
    };
  }

  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  showPrev = () => {
    this.props.history.push("/investor/collateral_InvRecurring");
  };
  showNext = () => {
    this.props.history.push("/investor/file_InvRecurring");
  };

  sortA = (sortColumn) => {
    console.log("", this.state.MapDataKeys);
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return -1;
      if (a[sortColumn] > b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  sortZ = (sortColumn) => {
    const { tableData } = this.state;
    const sortedData = [...tableData].sort((a, b) => {
      if (a[sortColumn] > b[sortColumn]) return -1;
      if (a[sortColumn] < b[sortColumn]) return 1;
      return 0;
    });
    this.setState({ tableData: sortedData });
    document.body.click();
  };

  handleClickCancel = (column) => {
    let temp = this.state.filteredColumn.filter((col) => col !== column);
    console.log("columnname", column, this.state.filteredColumn);
    this.setState((prevState) => {
      const updatedFilterConditions = {
        ...prevState.filterConditions,
        [column]: {
          condition1: "Equals",
          value1: "",
          condition2: "Equals",
          value2: "",
          logic: "OR", // Default logic
        },
      };

      const filteredData = prevState.tableData.filter((row) => {
        return Object.keys(updatedFilterConditions).every((col) => {
          const { condition1, value1, condition2, value2, logic } =
            updatedFilterConditions[col];

          if (value1 || value2) {
            const isFirstConditionMet = this.checkCondition(
              row[col],
              condition1,
              value1,
              col
            );
            const isSecondConditionMet = this.checkCondition(
              row[col],
              condition2,
              value2,
              col
            );

            // Use logic to determine whether to apply AND or OR
            if (logic === "AND") {
              return isFirstConditionMet && isSecondConditionMet;
            }
            return isFirstConditionMet || isSecondConditionMet;
          }
          return true;
        });
      });

      return {
        filterConditions: updatedFilterConditions,
        filteredData: filteredData.length > 0 ? filteredData : [{}], // Restore full data if no matches
      };
    });
    document.body.click();
  };

  handleFilterSubmit = (column, filterConditions) => {
    const { condition1, value1, condition2, value2, logic } = filterConditions;
    console.log("Filter conditions:", filterConditions);
  
    const currentData =
      this.state.filteredData.length > 0
        ? this.state.filteredData
        : this.state.tableData;
  
    const filteredData = currentData.filter((row) => {
      const isFirstConditionMet = this.checkCondition(
        row[column],
        condition1,
        value1,
        column
      );
      const isSecondConditionMet = this.checkCondition(
        row[column],
        condition2,
        value2,
        column
      );
  
      // Apply AND or OR based on logic
      return logic === "AND"
        ? isFirstConditionMet && isSecondConditionMet
        : isFirstConditionMet || isSecondConditionMet;
    });
  
    // Check if filteredData is empty
    if (filteredData.length === 0) {
      this.setState({ filteredData: [{ message: "Data not found" }] });
    } else {
      this.setState({ filteredData });
    }
  
    console.log("Filtered Data:", filteredData);
  
    let temp = [...this.state.filteredColumn];
    if (!temp.includes(column)) {
      temp.push(column);
    }
    this.setState({ filteredColumn: temp });
  
    document.body.click();
  };
  
  checkCondition = (cellValue, condition, value, column) => {
    if (cellValue === null || cellValue === undefined || cellValue === "") {
      return false; // Skip rows with empty values
    }
  
    const parsedCellValue = isNaN(Number(cellValue))
      ? cellValue
      : Number(cellValue);
    const parsedValue = isNaN(Number(value)) ? value : Number(value);
  
    console.log("parsed", parsedCellValue, parsedValue);
    switch (condition) {
      case "Equals":
        return parsedCellValue === parsedValue;
      case "Not Equals":
        return parsedCellValue !== parsedValue;
      case "Greater than":
        return parsedCellValue > parsedValue;
      case "Lesser than":
        return parsedCellValue < parsedValue;
      default:
        return true;
    }
  };

  updateFilterCondition = (column, field, value) => {
    this.setState(
      (prevState) => ({
        filterConditions: {
          ...prevState.filterConditions,
          [column]: {
            ...prevState.filterConditions[column],
            [field]: value,
          },
        },
      }),
      () => {
        console.log("Updated filterConditions:", this.state.filterConditions); // Debug log
      }
    );
  };

  popoverBottom = (column) => {
    const { filterConditions } = this.state;
    const columnFilterConditions = filterConditions[column] || {
      condition1: "",
      value1: "",
      logic: "OR",
      condition2: "",
      value2: "",
    };

    const isFilterActive = this.state.isFilterActive;
    const triangleImage = isFilterActive ? rightArrow : rightArrow;

    // Removed conditions and displaying all filter options
    const filterOptions = [
      "Equals",
      "Greater than",
      "Lesser than",
      "Not Equals",
    ];

    let uniqueValues = [];
    if (this.state.tableData.length > 0) {
      uniqueValues = [
        ...new Set(this.state.tableData.map((row) => row[column])),
      ];
    }

    const renderInputField = (condition, valueField) => {
      return (
        <select
          className="popover-input-wide dropdownicon"
          value={columnFilterConditions[valueField]}
          onChange={(e) =>
            this.updateFilterCondition(column, valueField, e.target.value)
          }
        >
          <option value="">Select value</option>
          {uniqueValues.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      );
    };

    const popoverClass =
      this.state.popoverPlacement === "left"
        ? "popover-container-sort-left"
        : "popover-container-sort-right";

    return (
      <Popover className={popoverClass}>
        <div className="popover-content">
          <div className="popover-row">
            <img src={ArrowDownward} alt="arrow-downward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortA(column)}
            >
              Sort A to Z
            </button>
          </div>
          <div className="popover-row">
          <img src={ArrowUpward} alt="arrow-upward" />
            <button
              className="popover-sort-btn"
              onClick={() => this.sortZ(column)}
            >
              Sort Z to A
            </button>
          </div>
          <div>
            <p>Custom Filter</p>
            <div className="popover-row">
              <select
              
                className="popover-input"
                value={columnFilterConditions.condition1}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition1",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition1, "value1")}
            </div>
            <div className="popover-radio-group">
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="AND"
                  checked={columnFilterConditions.logic === "AND"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label>AND</label>
              </div>
              <div className="popover-radio-item">
                <input
                  type="radio"
                  name="filterLogic"
                  value="OR"
                  checked={columnFilterConditions.logic === "OR"}
                  onChange={(e) =>
                    this.updateFilterCondition(column, "logic", e.target.value)
                  }
                />
                <label>OR</label>
              </div>
            </div>
            <div className="popover-row">
              <select
                className="popover-input"
                value={columnFilterConditions.condition2}
                onChange={(e) =>
                  this.updateFilterCondition(
                    column,
                    "condition2",
                    e.target.value
                  )
                }
              >
                {filterOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {renderInputField(columnFilterConditions.condition2, "value2")}
            </div>
            <div className="popover-bottom-buttons">
              <button
                type="button"
                className="popupbutton22"
                onClick={() => this.handleClickCancel(column)}
              >
                Cancel
              </button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  this.handleFilterSubmit(column, columnFilterConditions);
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    );
  };

  handleSearchIconClick = () => {
    this.setState({
      isSearchOpen: true,
    });
  };

  toggleFullScreen = () => {
    this.setState((prevState) => ({
      isFullScreen: !prevState.isFullScreen,
    }));

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  handleClickReport = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
    });
    this.props.history.push("/investor/report_InvRecurring");
  };

  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
    });
    this.props.history.push("/investor/collateral_InvRecurring");
  };
  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
    });
    // this.props.history.push("/investor/loantape_InvRecurring");
  };
  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
    });
    this.props.history.push("/investor/file_InvRecurring");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/investor/dashboard",
    });
  };

  dealPreviewMapFieldsRecurring = async () => {
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;

    console.log("datata", data);
    this.setState({ screenloader: true });
    const APIResponse = await dealPreviewMapFieldsRecurring(data);
    console.log("APIResponse", APIResponse);

    if (APIResponse.status === 200) {
      const tableData = APIResponse.data?.result || [];
      console.log("tableData", tableData);
      this.setState({
        MapDataKeys: tableData.length > 0 ? Object.keys(tableData[0]) : [],
        MapDataValues: APIResponse.data?.result?.map((item) =>
          Object.keys(item).map((key) => ({ value: item[key] }))
        ),
        screenloader: false,
        tableData: APIResponse.data?.result || ["no data"],
      },
      () => {
        const filterConditions = this.state.MapDataKeys.reduce(
          (acc, column) => {
            acc[column] = {
              condition1: "Equals",
              value1: "",
              condition2: "Equals",
              value2: "",
              logic: "OR",
            };
            return acc;
          },
          {}
        );
        this.setState({ filterConditions });
      }
    );
  } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: true,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  Loans = (value, date) => {
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("selectdate", date);
    let x = moment(date).subtract(1, "months").format("MM/DD/YYYY").toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    sessionStorage.setItem("month", month);
    sessionStorage.setItem("year", year);
    this.props.history.push({
      pathname: "/admin/viewdetails",
      state: {
        checkRecurring: true,
        checkRecurringGeneral: true,
        checkRecurringTranches: false,
        checkRecurringFees: false,
        checkRecurringExpenses: false,
        checkRecurringAccounts: false,
        checkRecurringTests: false,
        checkRecurringVariables: false,
        checkRecurringPaymentRules: false,
        Month: month,
        Year: year,
        ViewDate: date,
      },
    });
  };

  CalculatePrePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.NextPaymentDate = this.state?.NextPaymentDate;
    data.LastPaymentDate = this.state?.LastPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePrePayments(data);

    console.log("AccountsCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculatePayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculatePayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculatePayments(data);

    console.log("GeneralCalculatePayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.CalculateConsolidatedPayments();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    }
    // else if (APIResponse.status === 201) {
    //   this.setState({ getLoansLoader: false, loading: false });
    //   const message = APIResponse.data.message;
    //   this.props.enqueueSnackbar(message, {
    //     variant: "error",
    //     autoHideDuration: 3000,
    //   });
    // }
    else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  CalculateConsolidatedPayments = async () => {
    this.setState({ getLoansLoader: true, formLoader: true, loading: true });
    let x = this.state?.NextPaymentDate;
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.DealName = this.state.DealName;
    data.Month = month;
    data.Year = year;
    data.peers = this.state.peers;
    data.userid = sessionStorage.getItem("user_id");
    data.CurrentPaymentDate = this.state?.NextPaymentDate;

    console.log("datata", data);
    const APIResponse = await CalculateConsolidatedPayments(data);

    console.log("PaymentCalculateConsolidatedPayments", APIResponse.data);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        this.setState({
          getLoansLoader: false,
          loading: false,
          formLoader: false,
        });
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  handleDealChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        DealName: newValue,
        screenloader: true,
      },
      async () => {
        sessionStorage.setItem("dealname", newValue);
        await this.getDealDetailsByDealName();
        // await this.dealPreviewMapFieldsRecurring();

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.dealPreviewMapFieldsRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            await this.dealPreviewMapFieldsRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              {
                isVersionChange: false,
                publishVersion: Version,
              },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.dealPreviewMapFieldsRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getDealDetailsByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getDealDetailsByDealName(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem("version", APIResponse.data.Versions[0]);
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Versions)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        NextPaymentDate: newValue,
        screenloader: true,
        // openModalPayment: true
      },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        sessionStorage.setItem("nextpaymentdate", newValue);
        await this.getAllVersionsByPaymentDate();

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.dealPreviewMapFieldsRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            this.dealPreviewMapFieldsRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isVersionChange: false, publishVersion: Version },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.dealPreviewMapFieldsRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        Version: newValue,
        screenloader: true,
      },
      async () => {
        console.log("pppppp", this.state.Version);
        sessionStorage.setItem("version", newValue);

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isDisable: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.dealPreviewMapFieldsRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isDisable: this.state.isDisable,
              publishVersion: sessionStorage.getItem("version"),
            });
            this.dealPreviewMapFieldsRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isDisable: false, publishVersion: Version },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.dealPreviewMapFieldsRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  DownloadExcel = async () => {
    this.setState({ getLoansLoader: true, formLoader1: true, loading: true });
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(6, 10);
    var data = {};
    data.file = `${this.state.DealName}-${month}-${year}.xlsx`;
    data.file2 = `${this.state.DealName}-${month}-${year}.xlsx`;

    console.log("data", data);
    const APIResponse = await DownloadExcel(data);

    console.log("DownloadExcel", APIResponse.data);
    if (APIResponse.status === 200) {
      const blob = new Blob([APIResponse.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", data.file);
      document.body.appendChild(link);
      link.click();

      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
    } else {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
    }
  };

  componentDidMount() {
    if (this.state.Version === "V1" || this.state.Version === "V2") {
      this.setState({ isDisable: this.state.isDisable });
      this.dealPreviewMapFieldsRecurring();
    } else {
      this.setState({ isDisable: false });
      this.dealPreviewMapFieldsRecurring();
    }
  }
  handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) => {
      for (let key in row) {
        if (
          row[key] &&
          row[key].toString().toLowerCase().includes(searchTerm)
        ) {
          return true;
        }
      }
      return false;
    });

    this.setState({
      searchTerm: event.target.value,
      data: filteredData,
      isDataFound: filteredData.length > 0,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTerm: "",
      data: this.state.tableData, // Reset the data to the original dataset
    });
  };

  filterData = (searchTerm) => {
    const { tableData } = this.state;

    const filteredData = tableData.filter((row) =>
      Object.values(row).some((value) => {
        if (typeof value === "string") {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

    return filteredData;
  };

  render() {
    const { overlayVisible } = this.state;
    const {
      searchTerm,
      isDataFound,
      tableData,
      MapDataKeys,
      data,
      isSearchOpen,
      sortColumn,
      sortAscending,
    } = this.state;

    const filteredData = searchTerm ? this.filterData(searchTerm) : tableData;
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    return (
      <React.Fragment>
        <div className={`page ${overlayVisible ? "blur-background" : ""}`}>
          <Sidebar activeComponent={"Consolidated Report"} />
          <div className="content">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <div className="page-contentofpool1">
                <div className="row1">
                  <div
                    className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                    style={{
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1.5rem",
                      width: "90rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <KeyboardBackspaceIcon
                        onClick={this.goBackToDashboard}
                        className="left-arrow-muis1 left-arrow-servicer"
                      ></KeyboardBackspaceIcon>
                      <h3 className="headerdashboard">CONSOLIDATED REPORT</h3>
                    </div>
                  </div>
                </div>
                <div className="row row13">
                  <div className="general-InvContainer">
                    <div>
                      <label className="label">Deal Name</label>
                      <select
                        className="input-select-general-new1-deal"
                        value={this.state.DealName}
                        onChange={this.handleDealChange}
                      >
                        {sortedDeals.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="general-InvContainer">
                    <div>
                      <label className="label">Payment Date</label>
                      <select
                        className="input-select-general-new1"
                        value={this.state?.NextPaymentDate}
                        onChange={this.handlePaymentDateChange}
                      >
                        {this.state.getDashboardDates.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="general-InvContainer">
                    <div>
                      <label className="label">Version</label>
                      <select
                        className="input-select-general-new1"
                        value={this.state.Version}
                        onChange={this.handleVersionChange}
                      >
                        {this.state.getDashboardVersions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "-35px" }}>
                  <div className="row row1">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-15px",
                      }}
                    >
                      <div>
                        <button
                          type="button"
                          onClick={() => this.handleClickReport()}
                          className={
                            this.state.activeInsights1 == true
                              ? "issuerDashboard-table-top-button-insights-active"
                              : "issuerDashboard-table-top-button-insights"
                          }
                        >
                          Report
                        </button>

                        <button
                          type="button"
                          onClick={() => this.handleClickCollateral()}
                          className={
                            this.state.activeInsights2 == true
                              ? "issuerDashboard-table-top-button-workbench-active"
                              : "issuerDashboard-table-top-button-workbench"
                          }
                        >
                          Collateral
                        </button>

                        <button
                          type="button"
                          onClick={() => this.handleClickLoanTape()}
                          className={
                            this.state.activeInsights3 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Loan Tape
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickFiles()}
                          className={
                            this.state.activeInsights4 == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Files
                        </button>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <div>
                        <div className="search-container active">
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={this.handleSearch}
                            placeholder="Search..."
                            className="search-input"
                          />
                          <span
                            className="search-icon"
                            onClick={this.handleSearchIconClick}
                          >
                            <SearchIcon />
                          </span>
                        </div>

                        {!isDataFound && <p>No data found.</p>}
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.DownloadExcel}
                      >
                        Download Excel
                        {this.state.formLoader1 === true ? (
                          <CircularProgress size="22px" color="primary" />
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </div>

                  {this.state.screenloader == true ? (
                    <LinearLoader></LinearLoader>
                  ) : (
                    <React.Fragment>
                      <div
                        style={{
                          overflow: "auto",
                          borderRadius: "10px",
                          border: "0.5px solid var(--tableBorder)",
                        }}
                      >
                        {/* <Spreadsheet
                        data={this.state?.MapDataValues}
                        title={"Servicer Details"}
                        DataEditor="false"
                        columnLabels={MapFieldKeys}
                        readOnly={true}
                      /> */}
                        <TableVirtuoso
                          styles={{ overflowAnchor: "none" }}
                          style={{ height: 800 }}
                          data={filteredData}
                          components={TableComponents}
                          fixedHeaderContent={() => (
                            <TableRow>
                              <>
                                <TableCell
                                  style={{
                                    color:"var(--headerText)",
                                    background: "var(--white)",
                                    width: "100px",
                                  }}
                                ></TableCell>
                                {MapDataKeys.map((column, index) => (
                                  <TableCell
                                    key={index}
                                    style={{
                                      color:"var(--headerText)",
                              background: "var(--white)",
                                      cursor: "pointer",
                                      width: `${column.length * 10}px`,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                        <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <span>{column}</span>
                                {/* <OverlayTrigger
                                  trigger="click"
                                  placement="right"
                                  overlay={this.popoverBottom(column)}
                                  onExited={this.handleOverlayHide}
                                  rootClose={true}
                                >
                                  <img
                                    src={FilterMappedTape}
                                    alt="Filter"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "8px",
                                    }}
                                    onClick={this.handleButtonClick}
                                  />
                                </OverlayTrigger> */}
                              </div>
                                  </TableCell>
                                ))}
                              </>
                            </TableRow>
                          )}
                          itemContent={(index, row) => (
                            <>
                              <TableCell
                                style={{ background: "var(--white)", width: "100px" , color:"var(--tableText)" }}
                              >
                                {index + 1}
                              </TableCell>
                              {MapDataKeys.map((column, columnIndex) => (
                                <TableCell
                                  key={columnIndex}
                                  style={{
                                    color:"var(--tableText)",
                              background: "var(--white)",
                                    width: `${column.length * 10}px`,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {row[column]}
                                </TableCell>
                              ))}
                            </>
                          )}
                        />
                      </div>
                      <div></div>
                    </React.Fragment>
                  )}
                  <>
                    <div className="btn_move_Needed">
                      <div className="btn_prevs" onClick={this.showPrev}>
                        Previous
                      </div>
                      <button className="btn_next" onClick={this.showNext}>
                        Next
                      </button>
                    </div>
                  </>
                </div>
              </div>
            )}
          </div>
        </div>
        {overlayVisible && (
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0)",
              zIndex: 999,
            }}
            onClick={this.handleOverlayHide}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withSnackbar(LoanTapes_InvRecurring);
