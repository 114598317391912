import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Sidebar1 from "../../../../components/sidebar/sidebar";
import {
  servicerGetalldeals,
  getDealDetailsByPaymentDateservicer,
} from "../../../../servies/services";
import BeanEater from "../../../../images/BeanEater.gif";
import ReactModal from "react-modal";
import borderLeft from "../../../../images/border-left.png"
import { Table, Select, Button, Input, Spin, Pagination } from "antd";
import CustomFilterComponent from "../../../../components/CustomFilterComponent/CustomFilterComponent";
import fIlter from "../../../../images/FilterMappedTape.svg";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Search } = Input; // Import AntD Search component


const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

class ServicerMainDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      tableData: [],
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      loading: false,
      getLoansLoader: false,
      screenloader: false,
      openModalPayment: false,
      NextPaymentDate: "",
      LastPaymentDate: "",
      getPaymentDateIndex: null,
      getlastPaymentDates: [],
      paymentDates: [],
      PaymentDate: "",
      anchorEl: null,
      servicerValues: [],
      isDateSelected: null,
      showSearchBox: false,
      searchText: "",
      currentPage: 1,
      rowsPerPage: 6,
      selectedDate: null,
      dealName: "",
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {}, 
      searchText: "",
    };
  }
 
  Recurring = (value, rowData) => {
    console.log(rowData);
    sessionStorage.setItem("Servicer", rowData["Servicer"]);
    sessionStorage.setItem("servicerdealname",rowData["Deal Name"]);
    sessionStorage.setItem(
      "selectservicerdate",
      this.state.NextPaymentDate === ""
        ? this.state.selectedDate
        : this.state.NextPaymentDate
    );

    const dates = rowData["Payment Date"];

    let firstDate;
    if (typeof dates === "string") {
      // If `dates` is a string, split it by commas and get the first date
      firstDate = dates.split(",")[0].trim();
    } else if (Array.isArray(dates)) {
      // If `dates` is an array, take the first element
      firstDate = dates[0];
    } else {
      // If `dates` is another type (e.g., an object), handle it accordingly
      firstDate = ""; // Fallback value or custom logic
    }

    sessionStorage.setItem("getDashboardDates", firstDate);

    // sessionStorage.setItem(
    //   "selectservicerdate",
    //   this.state.NextPaymentDate === ""
    //     ? tableMeta.rowData[4]
    //     : this.state.NextPaymentDate
    // );
    sessionStorage.setItem("servicerdashboardname", rowData["Servicer"]);
    this.props.history.push({
      pathname: "/admin/summarize",
      state: { ViewServicer: "Yes" },
    });
  };
 
  UploadLoanTape = (value, rowData) => {
    sessionStorage.setItem("selectservicerdate", this.state.selectedDate);
    sessionStorage.setItem("servicerdashboardname", rowData["Deal Name"]);
    sessionStorage.setItem("Servicer", rowData["Servicer"]);
    sessionStorage.getItem("selectdate",this.state.detailsDateViewDetails)
    const dates = rowData["Payment Date"];
    console.log("tablemetaser", rowData);

    let firstDate;
    if (typeof dates === "string") {
      // If `dates` is a string, split it by commas and get the first date
      firstDate = dates.split(",")[0].trim();
    } else if (Array.isArray(dates)) {
      // If `dates` is an array, take the first element
      firstDate = dates[0];
    } else {
      // If `dates` is another type (e.g., an object), handle it accordingly
      firstDate = ""; // Fallback value or custom logic
    }

    sessionStorage.setItem("getDashboardDates", firstDate);
    
console.log("firstdate",firstDate,this.state.selectedDate);
    this.props.history.push({
      pathname: "/admin/uploadloantape",
      state: {
        ServicerPaymentDate:
          this.state.selectedDate === null ? firstDate : this.state.selectedDate,
        ServicerDealName: this.state.dealName,
        ServicerDashboardName: rowData["Deal Name"],
      },
    });
  };

  General = (value) => {
    sessionStorage.setItem("dealname", value);
    this.props.history.push({
      pathname: "/admin/general",
      state: { checkDeal: false },
    });
  };

  servicerGetalldeals = async () => {
    var data = {};
    data.ServicerName = sessionStorage.getItem("user_name");
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await servicerGetalldeals(data);

    if (APIResponse.status === 200) {
      this.setState(
        {
          getLoansLoader: false,
          tableData: APIResponse.data,
          displayData: APIResponse.data,
          loading: false,
        },
        () => {
          const dealData = APIResponse.data;
          if (dealData.length > 0) {
            const getDealNames = dealData.map((item) => item["Deal Name"]);
            const getPaymentDates = dealData.map(
              (item) => item["Payment Date"]
            )[0];
            console.log("getpaymentdates", getPaymentDates);
            const getServicers = dealData.map((item) => item["Servicer"])[0];
            // const getVersions = dealData.map((item) => item["Version"])[0];
            sessionStorage.setItem("dealname", getDealNames[0]);
            sessionStorage.setItem("isLoansProcessed", "Yes");
            console.log(">>>>>>>>>>>>>>>>>>", getPaymentDates[0]);
            console.log("check", getDealNames[0]);
            sessionStorage.setItem(
              "nextpaymentdate",
              getPaymentDates[getPaymentDates.length - 1]
            );
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("Servicer", getServicers[0]);
            // sessionStorage.setItem("version", getVersions[0]);
            sessionStorage.setItem(
              "getDashboardDeals",
              JSON.stringify(getDealNames)
            );
            sessionStorage.setItem(
              "getDashboardDates",
              JSON.stringify(getPaymentDates)
            );
            // sessionStorage.setItem(
            //   "getDashboardVersions",
            //   JSON.stringify(getVersions)
            // );
            sessionStorage.setItem("dashChanges", true);
          } else {
            console.log("Deal Name or Payment Date is missing.");
            sessionStorage.setItem("dealname", "");
            sessionStorage.setItem("isLoansProcessed", "No");
            sessionStorage.setItem("nextpaymentdate", "");
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("Servicer", "");
            // sessionStorage.setItem("version", "");
            sessionStorage.setItem("getDashboardDeals", "");
            sessionStorage.setItem("getDashboardDates", "");
            // sessionStorage.setItem("getDashboardVersions", "");
            sessionStorage.setItem("dashChanges", false);
          }
        }
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event, value, rowIndex, tableData) => {
    const newValue = event.target.value;
    const { selectedDate } = this.state;
    console.log("newValue", newValue);
    const updatedPaymentDates = [...this.state.paymentDates];
    updatedPaymentDates[rowIndex] = newValue;

    // console.log("PaymentDates", value);

    console.log("updatedPaymentDates", updatedPaymentDates);

    this.setState(
      {
        getlastPaymentDates: value,
        isDateChanged: true,
        getPaymentDateIndex: rowIndex,
        PaymentDate: newValue,
        selectedDate: selectedDate,
        openModalPayment: true,
        paymentDates: updatedPaymentDates,
        singleRowPaymentDate: tableData,
      },
      () => sessionStorage.setItem("viewsummarydate", this.state.selectedDate)
    );
  };

  updatePaymentDateValue = async (newValue, rowData) => {
    console.log("newValue", newValue, rowData);
    var data = {};
    var input = {};

    input["Deal Name"] = rowData["Deal Name"];
    input["Payment Date"] = rowData["Payment Date"]; // Generate dynamically
    input["Loans Processed"] = rowData["Loans Processed"];
    input["Last Modified"] = rowData["Last Modified"];
    input["Asset Class"] = rowData["Asset Class"];
    input["Servicer"] = rowData["Servicer"];
    input["Error Status"] = rowData["Error Status"];

    console.log("input", input);
    data.input = input;

    data.selectedDate = newValue; // Assuming newValue is the selected date
    data.peers = this.state.peers; // Assuming peers are already set in the state
    console.log("data", data);

    this.setState({
      singleRowPaymentDate: rowData,
      selectedDate: newValue,
      dealName: rowData["Deal Name"],
    });

    const APIResponse = await getDealDetailsByPaymentDateservicer(data);
    console.log("APIResponse", APIResponse);

    if (APIResponse.status === 200) {
      const res = APIResponse.data;
      const modifiedData = this.state.displayData.map((item) => {
        console.log("rowdatadeal",rowData["Deal Name"])
        if (item["Deal Name"] === rowData["Deal Name"]) {
          return res;
        } else {
          return item;
        }
      });
      console.log("modifiedData", modifiedData);
      this.setState(
        {
          getLoansLoader: false,
          // tableData: modifiedData,
          displayData:modifiedData,
          loading: false,
          openModalPayment: false,
        },
        () => {
          console.log("singleRowPaymentDate", this.state.tableData);
        }
      );
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });
      console.log("", this.state.NextPaymentDate);

      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
  
    // Reset the page to 1 whenever search is changed
    this.setState({ searchText: searchText, currentPage: 1 });
  };

  handleSearch = (value) => {
    const filteredData = this.state.tableData.filter((item) =>
      Object.values(item).some((field) =>
        String(field).toLowerCase().includes(value.toLowerCase())
      )
    );
    this.setState({
      searchText: value,
      displayData: filteredData,
      currentPage: 1,
    });
  };

  clearSearch = () => {
    this.setState({
      searchText: "",
      displayData: this.state.tableData, // Reset to original data
    });
    this.inputRef.focus();
  };

  handleFilter = (tableName, data, columnKey, criteria) => {
    const { filterCriteria, tableData, pageSize } = this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    let filtered = tableData;

    if (!isCriteriaEmpty) {
      // Apply filters
      filtered = tableData.filter((item) => {
        return Object.keys(newCriteria).every((key) => {
          const criteria = newCriteria[key];
          if (!criteria) return true;

          const { condition1, value1, operator, condition2, value2 } = criteria;

          const applyCondition = (value, condition, target) => {
            switch (condition) {
              case "equals":
                return value === target;
              case "less_than":
                return value < target;
              case "greater_than":
                return value > target;
              case "not_equals":
                return value !== target;
              default:
                return false;
            }
          };

          const condition1Met = applyCondition(item[key], condition1, value1);
          const condition2Met = applyCondition(item[key], condition2, value2);

          return operator === "AND"
            ? condition1Met && condition2Met
            : condition1Met || condition2Met;
        });
      });
    }

    const startIndex = 0;
    const endIndex = pageSize;

    this.setState({
      displayData: filtered,
      paginatedData: filtered.slice(startIndex, endIndex),
      currentPage: 1,
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handleSort = (tableName, columnKey, order) => {
    const { tableData, itemsPerBatch } = this.state;
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        if (!isNaN(valueA) && !isNaN(valueB)) {
          return order === "ascend" ? valueA - valueB : valueB - valueA;
        }

        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        return 0;
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        this.setState({
          tableData: sortedData,
          displayData: sortedData.slice(0, itemsPerBatch),
        });
      }
    };

    sortChunk(0);
  };

  applyCurrentFilters = (tableData) => {
    const { filterCriteria } = this.state;

    if (!filterCriteria || Object.keys(filterCriteria).length === 0) {
      return tableData;
    }

    return tableData.filter((item) => {
      return Object.keys(filterCriteria).every((key) => {
        const criteria = filterCriteria[key];
        if (!criteria || !criteria.value1) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return true;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });
  };

  handlePaginationChange = (page) => {
    const { pageSize, displayData } = this.state;

    const startIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    const updatedPaginatedData = displayData.slice(startIndex, endIndex);

    this.setState({
      currentPage: page,
      paginatedData: updatedPaginatedData,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if the PaymentDate state has changed
    if (
      prevState.PaymentDate !== this.state.PaymentDate ||
      prevState.getPaymentDateIndex !== this.state.getPaymentDateIndex
    ) {
      //|| prevState.singleRowPaymentDate !== this.state.singleRowPaymentDate
      // Perform the API call or other logic here
      const { PaymentDate, singleRowPaymentDate, selectedDate, dealName } =
        this.state;
      console.log("rowData", singleRowPaymentDate);

      this.updatePaymentDateValue(
        PaymentDate,
        singleRowPaymentDate,
        selectedDate,
        dealName
      );
    }
  }

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    sessionStorage.setItem("selectdate", this.state.detailsDateViewDetails);
    sessionStorage.removeItem("selectservicerdate");
    sessionStorage.removeItem("servicerdealname");
    sessionStorage.removeItem("servicerdashboardname");
    sessionStorage.removeItem("dealname");
    this.servicerGetalldeals();
  }
 
  render() {
    const { displayData, currentPage, pageSize , searchText } = this.state;

    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const truncateStringdealname = (value, maxLength = 20) => {
      if (typeof value === "string" && value.length > maxLength) {
        return `${value.substring(0, maxLength)}...`;
      }
      return value;
    };

    const fontSizeStyle = {
      fontSize: "15px", // Adjust the font size as needed
    };

    const columns = [
      {
        title: "Deal Name",
        dataIndex: "Deal Name",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deal Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Deal Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width:"15px",
              height:"10px",
              color: this.state.filteredClrChangeColumns["Deal Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value, record) => {
          const isError = record["Error Status"] === "Yes";
    
          return (
            <div
              style={{
                ...fontSizeStyle,
                position: "relative",
                borderLeft: isError ? "4px solid transparent" : "none",
                paddingLeft: isError ? "8px" : "4px",
              }}
            >
              {isError && (
                <img
                  src={borderLeft}
                  alt="Error"
                  style={{
                    position: "absolute",
                    left: "-12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: "16px",
                    width: "80% 8px",
                  }}
                />
              )}
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                title={value}
              >
                {truncateStringdealname(value)}
              </div>
            </div>
          );
        },
      },
      {
        title: "Asset Class",
        dataIndex: "Asset Class",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Asset Class"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Asset Class")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width:"15px",
              height:"10px",
              color: this.state.filteredClrChangeColumns["Asset Class"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={fontSizeStyle}>{truncateStringdealname(value)}</div>,
      },
      {
        title: "Servicer",
        dataIndex: "Servicer",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Servicer"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Servicer")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width:"15px",
              height:"10px",
              color: this.state.filteredClrChangeColumns["Servicer"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={fontSizeStyle}>{truncateStringdealname(value)}</div>,
      },
      {
        title: "Payment Date",
        dataIndex: "Payment Date",
        align:"left",
        render: (value, record, rowIndex) => {
          return (
            <div
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
                ...fontSizeStyle,
              }}
            >
              <select
                className="input-select-general-new2"
                value={this.state.paymentDates[rowIndex]}
                onChange={(e) =>
                  this.handlePaymentDateChange(e, value, rowIndex, record)
                }
              >
                {value.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          );
        },
      },
      {
        title: "Processed",
        dataIndex: "Loans Processed",
        align:"left",
        render: (value) => (
          <div
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "left",
              justifyContent: "left",
              paddingRight: "4rem",
              ...fontSizeStyle,
            }}
          >
            <tr>{value}</tr>
          </div>
        ),
      },
      {
        title: "Last Modified",
        dataIndex: "Last Modified",
        align:"left",
        render: (value) => {
          
          return (
            <div
              style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: "4rem",
                ...fontSizeStyle,
              }}
            >
              <tr>{value}</tr>
            </div>
          );
        },
      },
      {
        title: "Loan Data",
        dataIndex: "Error Status",
        align:"left",
        render: (value, record, rowIndex) => {
          const isError = record.showError;
    
          return (
            <div className="align-actions-servicer">
              {record["Loans Processed"] === "Yes" ? (
                <button
                  className={`popupdashboard-servicer ${
                    isError ? "border-bottom-class" : ""
                  }`}
                  onClick={() => this.Recurring(value, record)}
                >
                  View
                </button>
              ) : (
                <button
                  className="login-sign_up-links popupdashboard-servicer-button-disabled"
                  onClick={() => this.Recurring(value, record)}
                  disabled
                >
                  View
                </button>
              )}
    
              <button
                className="popupdashboard-servicer-process"
                onClick={() => this.UploadLoanTape(value, record)}
              >
                Process
              </button>
            </div>
          );
        },
      },
    ];
    
    
    return (
      <div className="page">
        <Sidebar1 activeComponent={"Servicer Dashboard"} />
        <div className="content">
          
          <div className="page-content">
            <div className="row row1">
              <div className="investor-heading-container">
                <h1 className="headerdashboard-trustee">DASHBOARD</h1>
              </div>
              <div className="dashboard-top-right-container1">
              <Search
              ref={(input) => (this.inputRef = input)}
          placeholder="Search..."
          enterButton={false}
          className="custom-search"
          value={searchText}
          onChange={(e) => this.handleSearch(e.target.value)}
          onSearch={this.handleSearch}
          style={{ width: 300 }}
          autoFocus
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={this.clearSearch}
                style={{ cursor: "pointer" }}
              />
            )
          }
        /> 
        </div>
              <div>
              </div>
            </div>
            <React.Fragment>
             <Table
              dataSource={paginatedData.map((item, index) => ({
                ...item,
                key: index,
              }))}
              // dataSource={this.state.tableData}
              columns={columns}
              className="custom-antds-dash-table"
              pagination={false}
              loading={this.state.loading}
              scroll={{ x:"max-content" , y:600 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                overflow: "hidden",
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={displayData.length}
                onChange={this.handlePaginationChange}
                itemRender={(page, type, originalElement) => {
                  if (type === "page") {
                    return (
                      <div
                        className={`custom-pagination-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        {page}
                      </div>
                    );
                  }
                  return originalElement;
                }}
              />
            </div>
              <ReactModal
                isOpen={this.state.openModalPayment}
                contentLabel="Minimal Modal Example"
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed", // Use fixed positioning for the overlay
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 9999, // Set a high z-index to ensure the modal appears on top
                  },
                  content: {
                    position: "absolute",
                    background: "transparent", // Make the modal content transparent
                    border: "none", // Optional: Remove any borders
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    overflow: "hidden",
                  },
                }}
              >
                <React.Fragment>
                  <img
                    src={BeanEater}
                    style={{
                      width: "8vw",
                      height: "9vh",
                      backgroundColor: "transparent",
                    }}
                  />
                  <h3 className="dot-loader">Loading.Please wait</h3>
                </React.Fragment>
              </ReactModal>
            </React.Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(ServicerMainDashboard);
