import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Table } from "antd";
import Plot from "react-plotly.js";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import fIlter from "../../../../../images/FilterMappedTape.svg";
import WaveLoad from "../../../../../images/Wave_Load.svg";
import debounce from "lodash/debounce";

function FundingFacility() {
  const [plotData, setPlotData] = useState({
    DA_Collateral_Breakdown: { data: [], layout: {} },
    DA_summary_Origination: { data: [], layout: {} },
    DA_FacilityActivity: { data: [], layout: {} },
    DA_Delinquencies: { data: [], layout: {} },
  });
  const [tableData, setTableData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const plotContainerRef = useRef(null);

  const updatePlotLayouts = () => {
    if (plotContainerRef.current) {
      setPlotData((prevPlotData) =>
        Object.keys(prevPlotData).reduce((acc, key) => {
          const plot = prevPlotData[key];
          acc[key] = {
            ...plot,
            layout: {
              ...plot.layout,
              width: plotContainerRef.current.offsetWidth || window.innerWidth,
              autosize: true,
            },
          };
          return acc;
        }, {})
      );
    }
  };

  useLayoutEffect(() => {
    if (plotContainerRef.current) {
      updatePlotLayouts();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && plotContainerRef.current) {
      updatePlotLayouts();
    }
  }, [isLoading]);

  // useEffect(() => {
  //   let isComponentMounted = true; // Track if the component is mounted

  //   const fetchData = () => {
  //     if (!isComponentMounted) return;

  //     try {
  //       const finalData = JSON.parse(
  //         sessionStorage.getItem("finalDataSummary")
  //       );

  //       if (finalData) {
  //         const parsePlotData = (data) => {
  //           let parsedData = { data: [], layout: {} };
  //           if (typeof data === "string") {
  //             parsedData = JSON.parse(data);
  //           } else if (typeof data === "object" && data !== null) {
  //             parsedData = data;
  //           }
  //           parsedData.layout = {
  //             ...parsedData.layout,
  //             width: plotContainerRef.current?.offsetWidth || window.innerWidth,
  //             autosize: true,
  //           };
  //           return parsedData;
  //         };

  //         setPlotData({
  //           DA_Collateral_Breakdown: parsePlotData(
  //             finalData["Collateral Breakdown and BBC"]
  //           ),
  //           DA_summary_Origination: parsePlotData(finalData["Origination"]),
  //           DA_FacilityActivity: parsePlotData(
  //             finalData["Facility Actitivity and Utilization Rate"]
  //           ),
  //           DA_Delinquencies: parsePlotData(finalData["Delinquencies"]),
  //         });

  //         // Process and update table data
  //         const dynamicTableData = {};
  //         [
  //           "Liabilities",
  //           "Recent Activity",
  //           "Collateral",
  //           "Accounts",
  //           "Borrowing Base",
  //           "Tests",
  //         ].forEach((key) => {
  //           if (finalData[key]) {
  //             dynamicTableData[key] = finalData[key].map((row, index) => ({
  //               key: index,
  //               ...row,
  //             }));
  //           }
  //         });
  //         setTableData(dynamicTableData);
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error parsing JSON from sessionStorage:", error);
  //     }
  //   };

  //   fetchData(); // Call once on component mount

  //   const handleResize = debounce(() => {
  //     if (isComponentMounted) {
  //       updatePlotLayouts();
  //     }
  //   }, 300);

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     isComponentMounted = false;
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  useEffect(() => {
    let previousData = null; // To track the previous state of data and prevent unnecessary updates
    let isComponentMounted = true; // Track if the component is mounted

    const fetchData = () => {
      if (!isComponentMounted) return; // Prevent state updates if the component is unmounted
      try {
        const finalData = JSON.parse(
          sessionStorage.getItem("finalDataSummary")
        );

        if (
          finalData &&
          JSON.stringify(finalData) !== JSON.stringify(previousData)
        ) {
          previousData = finalData;

          const parsePlotData = (data) => {
            let parsedData = { data: [], layout: {} };
            if (typeof data === "string") {
              parsedData = JSON.parse(data);
            } else if (typeof data === "object" && data !== null) {
              parsedData = data;
            }
            if (!parsedData.layout) parsedData.layout = {};
            parsedData.layout.width =
              plotContainerRef.current?.offsetWidth || window.innerWidth;
            parsedData.layout.autosize = true;
            return parsedData;
          };

          // Parse and update plot data
          setPlotData({
            DA_Collateral_Breakdown: parsePlotData(
              finalData["Collateral Breakdown and BBC"]
            ),
            DA_summary_Origination: parsePlotData(finalData["Origination"]),
              DA_FacilityActivity: parsePlotData(
              finalData["Facility Actitivity and Utilization Rate"]
            ),
            DA_Delinquencies: parsePlotData(finalData["Delinquencies"]),
          });

          // Process and update table data dynamically
          const dynamicTableData = {};
          [
            "Liabilities",
            "Recent Activity",
            "Collateral",
            "Accounts",
            "Borrowing Base",
            "Tests",
          ].forEach((key) => {
            if (finalData[key]) {
              dynamicTableData[key] = finalData[key].map((row, index) => ({
                key: index,
                ...row,
              }));
            }
          });
          setTableData(dynamicTableData);
          setIsLoading(false); // Mark as loaded
        }
      } catch (error) {
        console.error("Error parsing JSON from sessionStorage:", error);
        setPlotData({});
        setTableData({});
        setIsLoading(false); // Stop loading on error
      }
    };

    fetchData(); // Initial fetch

    const interval = setInterval(() => {
      fetchData();
    }, 2000); // Poll every 2 seconds

    // Debounced resize event listener
    const handleResize = debounce(() => {
      if (isComponentMounted) {
        updatePlotLayouts();
      }
    }, 300);

    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      isComponentMounted = false; // Mark the component as unmounted
      clearInterval(interval); // Clear the polling interval
      window.removeEventListener("resize", handleResize); // Remove the resize listener
    };
  }, []);

  const generateColumns = (data) => {
    if (!data || !data.length) return [];
    const keys = Object.keys(data[0]).filter((key) => key !== "key");

    return keys.map((key) => ({
      title: key.replace(/_/g, " "), // Replace underscores with spaces for better readability
      dataIndex: key,
      key,
      // width: 150,
      onHeaderCell: () => ({
        style: {
          textAlign: "center", // Custom header alignment
        },
      }),
      // filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
      //   <CustomFilterComponent
      //     columnKey={key}
      //     onSort={handleSort}
      //     onFilter={handleFilter}
      //     columnValues={getUniqueValues(data, key)}
      //     closeDropdown={close}
      //   />
      // ),
      // filterIcon: () => (
      //   <FilterFilled
      //     style={{
      //       color: filteredClrChangeColumns[key] ? "green" : undefined,
      //     }}
      //   />
      // ),
      render: (text, record) => {
        const isDollarColumn = key.toLowerCase().includes("($)");
        const isWholeOrIntegerWithZeroDecimal =
          /^[+-]?\d{1,3}(,\d{3})*(\.\d{2,})?$/.test(text); // Adjusted regex to handle "0.00" and similar cases
        const isCountColumn = key.toLowerCase().includes("count");
        const isPercentColumn = key.toLowerCase().includes("%");
        const isNA = text === "NA";

        if (record.isTotal) {
          if (isNA) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }
          if (isDollarColumn) {
            return (
              <strong style={{ textAlign: "right", display: "block" }}>
                {text}
              </strong>
            );
          }
          if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
            return (
              <strong style={{ textAlign: "center", display: "block" }}>
                {text}
              </strong>
            );
          }
          if (isPercentColumn) {
            return (
              <strong style={{ textAlign: "center", display: "block" }}>
                {text}
              </strong>
            );
          }
        }

        if (isNA) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
        if (isDollarColumn || /^[+-]?\d{1,3}(,\d{3})*(\.\d{2,})?$/.test(text)) {
          // Align numeric values with trailing decimals to the right
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }
        if (isWholeOrIntegerWithZeroDecimal || isCountColumn) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
        if (isPercentColumn) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }
        return <div style={{ textAlign: "left" }}>{text}</div>;
      },
    }));
  };

  return (
    <div className="portfolioDash">
      {isLoading ? (
        <div>
          <img
            src={WaveLoad}
            style={{
              width: "100%",
              height: "10vh",
              backgroundColor: "var(--white)",
            }}
            alt="Loading Animation"
          />
        </div>
      ) : (
        <>
          <div className="workbench-table-container">
            <div className="table-section">
              <label className="tableHeaders">Liabilities</label>
              <Table
                columns={generateColumns(tableData["Liabilities"])}
                dataSource={tableData["Liabilities"]}
                pagination={false}
                className="performance-table"
                scroll={{ x: "max-content", y: 500 }}
              />
            </div>

            <div className="table-row">
              <div className="table-column">
                <label className="tableHeaders">Recent Activity</label>
                <Table
                  columns={generateColumns(tableData["Recent Activity"])}
                  dataSource={tableData["Recent Activity"]}
                  pagination={false}
                  className="performance-table"
                  scroll={{ x: "max-content", y: 500 }}
                />
              </div>
              <div className="table-column">
                <label className="tableHeaders">Collateral</label>
                <Table
                  columns={generateColumns(tableData["Collateral"])}
                  dataSource={tableData["Collateral"]}
                  pagination={false}
                  className="performance-table"
                  scroll={{ x: "max-content", y: 500 }}
                />
              </div>
            </div>
            <div className="table-row">
              <div className="table-column">
                <label className="tableHeaders">Accounts</label>
                <Table
                  columns={generateColumns(tableData["Accounts"])}
                  dataSource={tableData["Accounts"]}
                  pagination={false}
                  className="performance-table"
                  style={{ overflowX: "scroll" }}
                  scroll={{ x: "max-content", y: 500 }}
                />
              </div>
              <div className="table-column">
                <label className="tableHeaders">Tests</label>
                <Table
                  columns={generateColumns(tableData["Tests"])}
                  dataSource={tableData["Tests"]}
                  pagination={false}
                  className="performance-table"
                  scroll={{ x: "max-content", y: 500 }}
                />
              </div>
            </div>
            <div className="table-row">
              <div className="table-column alignment-for-Charts">
                <label className="tableHeaders">Borrowing Base</label>
                <Table
                  columns={generateColumns(tableData["Borrowing Base"])}
                  dataSource={tableData["Borrowing Base"]}
                  pagination={false}
                  className="performance-table"
                  scroll={{ x: "max-content", y: 500 }}
                />
              </div>
              <div
                className="table-column"
                // style={{ position: "relative", top: "2.5rem" }}
              >
                <div
                  className="plot-borders align-border-plot"
                  ref={plotContainerRef}
                  // style={{ position: "relative", height: "93%" }}
                >
                  {Object.entries(plotData)
                    .slice(2, 3) // Take the third graph
                    .map(([key, plot]) => (
                      <Plot
                        key={key}
                        data={plot.data}
                        layout={plot.layout}
                        config={{
                          displaylogo: false,
                          modeBarButtonsToRemove: [
                            "select2d",
                            "lasso2d",
                            "resetScale2d",
                            "zoom",
                          ],
                          scrollZoom: true,
                        }}
                        className="plot-inline"
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="chart-container-portfoilo">
            {Object.entries(plotData)
              .slice(0, 2)
              .map(([key, plot]) => (
                <div className="plot-borders" ref={plotContainerRef} key={key}>
                  <Plot
                    data={plot.data}
                    layout={plot.layout}
                    config={{
                      displaylogo: false,
                      modeBarButtonsToRemove: [
                        "select2d",
                        "lasso2d",
                        "resetScale2d",
                        "zoom",
                      ],
                      scrollZoom: true,
                    }}
                    className="plot-inline"
                  />
                </div>
              ))}
          </div>

          <div className="chart-container-portfoilo">
            {Object.entries(plotData)
              .slice(3)
              .map(([key, plot]) => (
                <div className="plot-borders" ref={plotContainerRef} key={key}>
                  <Plot
                    data={plot.data}
                    layout={plot.layout}
                    config={{
                      displaylogo: false,
                      modeBarButtonsToRemove: [
                        "select2d",
                        "lasso2d",
                        "resetScale2d",
                        "zoom",
                      ],
                      scrollZoom: true,
                    }}
                    className="plot-inline"
                  />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default FundingFacility;
