/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import * as XLSX from "xlsx";
import {
  dealCreationAddSave,
  dealCreationTable,
  dealCreationCreateUpdate,
  dealCreationDelete,
  dealCreationSaveForNow,
  dealCreation_BC_Status,
  dealCreationAutoSave,
  dealCreationEdit,
  savedealservicerdate,
} from "../../../../../servies/services";
import { connect } from "react-redux";
import Header from "../../../../../components/header";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import Loader from "../../../../../components/loader";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearLoader from "../../../../../components/loader/LinearLoader";
import ReactModal from "react-modal";
import {
  customStylesServicer,
  customStylesauto,
  customStylesautoTranches,
} from "../../../../../components/customscripts/customscript";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import NumberComp4 from "../../../../../components/NumberComp4";
import Switch from "@material-ui/core/Switch";
import { customStylesautosmallmodal1 } from "../../../../../components/customscripts/customscript";
import FormLoader from "../../../../../components/loader/formLoader";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as moment from "moment";
import AccordIcon from "../../../../../images/AccordIcon.png";
import Upload_Logo from "../../../../../images/Upload_Logo.svg";
import DownloadLogo from "../../../../../images/DownloadLogo.svg";
import checkedImg from "../../../../../images/checked.png";
import {Table} from "antd";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
// import { Pagination } from "antd"; 
import fIlter from "../../../../../images/FilterMappedTape.svg"

// Function to get unique values for a column
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};


class Tranches_DealCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      isUpdate: false,
      getLoansLoader: false,
      openPopup: false,
      searchText: "",
      rowsSelected: null,
      isAccordian: false,
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      fileUploaded: false,
      screenloader: false,
      isAdded: false,
      isTranches: false,
      TableName: "Tranches",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      dealid: sessionStorage.getItem("dealid"),
      DealName: !sessionStorage.getItem("dealname")
        ? JSON.parse(sessionStorage.getItem("getDashboardDeals")).map(
            (item) => item[0]
          )[0]
        : sessionStorage.getItem("dealname"),
      isRecurring: sessionStorage.getItem("isAddDeal"),
      isSecuritisation: sessionStorage.getItem("isSecuritisation"),
      isESMA: sessionStorage.getItem("isESMA_Flag"),
      formData: {
        Name: "",
        Cusip: "",
        "Original Principal Balance": "",
        "Interest Type": "",
        "Interest Rate": "",
        "Index Code": "",
        Margin: "",
        "Day Count Method": "",
        "Loss Allocation Method": "",
        "Maturity Date": sessionStorage.getItem("LegalMaturityDate"),
        "Coupon Cap1": "",
        "Coupon Cap2": "",
        "Interest On Shortfall Flag": "",
        "Interest On WAC Shortfall Flag": "",
        "Deferral Allocation Method": "",
        "Stepup Margin": "",
        "Class Type": "",
        "Index Determination Date Logic": "",
        "Stepup Date": "",
        "Loss Allocation Sequence": "",
        "Interest Accrual Start Date Logic": "",
        "Interest Accrual End Date Logic": "",
        "Facility Type": "",
        "Original Commitment Balance": "",
        "Commitment Fee Rate": "",
        "Commitment Fee Basis": "",
        "Notional Flag": "",
        "Original Notional Balance": "",
        "Beginning Notional Balance Logic": "",
        "Ending Notional Balance Logic": "",
        "International Securities Identification Number": "",
        "Tranche/Bond Type": "",
        Currency: "",
        "Coupon Floor": "",
        "Coupon Cap": "",
        "Business Day Convention": "",
        "Current Interest Rate Index": "",
        "Current Interest Rate Index Tenor": "",
        "Extension Clause": "",
        "Next Call Date": "",
        "Clean-Up Call Threshold": "",
        "Next Put date": "",
        "Settlement Convention": "",
        "Current Attachment Point": "",
        "Original Attachment Point": "",
        "Credit Enhancement Formula": "",
        "Pari-Passu Tranches": "",
        "Senior Tranches": "",
        "Outstanding Principal Deficiency Ledger Balance": "",
        "Guarantor Legal Entity Identifier": "",
        "Guarantor Name": "",
        "Guarantor ESA Subsector": "",
        "Protection Type": "",
        "Record Date Logic": "",
        "Beginning Cumulative Loss": "",
        "Beginning Shortfall": "",
        "Beginning WAC Shortfall": "",
        "Beginning Principal Balance": "",
        "Ending Shortfall Logic": "",
      },
      formData1: {
        Name: "",
        Cusip: "",
        "Original Principal Balance": "",
        "Interest Type": "",
        "Interest Rate": "",
        "Index Code": "",
        Margin: "",
        "Day Count Method": "",
        "Loss Allocation Method": "",
        "Maturity Date": sessionStorage.getItem("LegalMaturityDate"),
        "Coupon Cap1": "",
        "Coupon Cap2": "",
        "Interest On Shortfall Flag": "",
        "Interest On WAC Shortfall Flag": "",
        "Deferral Allocation Method": "",
        "Stepup Margin": "",
        "Class Type": "",
        "Index Determination Date Logic": "",
        "Stepup Date": "",
        "Loss Allocation Sequence": "",
        "Interest Accrual Start Date Logic": "",
        "Interest Accrual End Date Logic": "",
        "Facility Type": "",
        "Original Commitment Balance": "",
        "Commitment Fee Rate": "",
        "Commitment Fee Basis": "",
        "Notional Flag": "",
        "Original Notional Balance": "",
        "Beginning Notional Balance Logic": "",
        "Ending Notional Balance Logic": "",
        "International Securities Identification Number": "",
        "Tranche/Bond Type": "",
        Currency: "",
        "Coupon Floor": "",
        "Coupon Cap": "",
        "Business Day Convention": "",
        "Current Interest Rate Index": "",
        "Current Interest Rate Index Tenor": "",
        "Extension Clause": "",
        "Next Call Date": "",
        "Clean-Up Call Threshold": "",
        "Next Put date": "",
        "Settlement Convention": "",
        "Current Attachment Point": "",
        "Original Attachment Point": "",
        "Credit Enhancement Formula": "",
        "Pari-Passu Tranches": "",
        "Senior Tranches": "",
        "Outstanding Principal Deficiency Ledger Balance": "",
        "Guarantor Legal Entity Identifier": "",
        "Guarantor Name": "",
        "Guarantor ESA Subsector": "",
        "Protection Type": "",
        "Record Date Logic": "",
        "Beginning Cumulative Loss": "",
        "Beginning Shortfall": "",
        "Beginning WAC Shortfall": "",
        "Beginning Principal Balance": "",
        "Ending Shortfall Logic": "",
      },
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      addswitchtoggle: false,
      editswitchtoggle: false,
      activeInsightsMI: false,
      deleteRowVal: {
        Name: "",
      },
      ViewStatus: false,
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {}, 
    };
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleChange1 = (e) => {
    // togglevat
    if (this.state.addswitchtoggle === false) {
      this.setState({ addswitchtoggle: true });
    } else {
      this.setState({ addswitchtoggle: false });
    }
  };
  handleChange2 = (e) => {
    // togglevat
    if (this.state.editswitchtoggle === false) {
      this.setState({ editswitchtoggle: true });
    } else {
      this.setState({ editswitchtoggle: false });
    }
  };

  handleClickGeneral = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/general");
  };

  handleClickTranches = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
  };
  handleClickFees = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/fees");
  };
  handleClickExpenses = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/expenses");
  };
  handleClickAccounts = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: true,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/account");
  };

  handleClickTests = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: true,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/tests");
  };
  handleClickBorrowingBase = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: true,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/tests");
  };
  handleClickVariables = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: true,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/variables");
  };
  handleClickPaymentRules = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: true,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/paymentrules");
  };
  handleClickManualInputs = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: true,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/paymentrules");
  };

  onOpenModal = () => {
    console.log("add tranche modal");
    this.setState({
      open1: true,
      isAccordian: false,
      formData: {
        Name: "",
        Cusip: "",
        "Original Principal Balance": "",
        "Interest Type": "",
        "Interest Rate": "",
        "Index Code": "",
        Margin: "",
        "Day Count Method": "",
        "Loss Allocation Method": "",
        "Maturity Date": sessionStorage.getItem("LegalMaturityDate"),
        "Coupon Cap1": "",
        "Coupon Cap2": "",
        "Interest On Shortfall Flag": "",
        "Interest On WAC Shortfall Flag": "",
        "Deferral Allocation Method": "",
        "Stepup Margin": "",
        "Class Type": "",
        "Index Determination Date Logic": "",
        "Stepup Date": "",
        "Loss Allocation Sequence": "",
        "Interest Accrual Start Date Logic": "",
        "Interest Accrual End Date Logic": "",
        "Facility Type": "",
        "Original Commitment Balance": "",
        "Commitment Fee Rate": "",
        "Commitment Fee Basis": "",
        "Notional Flag": "NA",
        "Original Notional Balance": "",
        "Beginning Notional Balance Logic": "",
        "Ending Notional Balance Logic": "",
        "International Securities Identification Number": "",
        "Tranche/Bond Type": "",
        Currency: "",
        "Coupon Floor": "",
        "Coupon Cap": "",
        "Business Day Convention": "",
        "Current Interest Rate Index": "",
        "Current Interest Rate Index Tenor": "",
        "Extension Clause": "",
        "Next Call Date": "",
        "Clean-Up Call Threshold": "",
        "Next Put date": "",
        "Settlement Convention": "",
        "Current Attachment Point": "",
        "Original Attachment Point": "",
        "Credit Enhancement Formula": "",
        "Pari-Passu Tranches": "",
        "Senior Tranches": "",
        "Outstanding Principal Deficiency Ledger Balance": "",
        "Guarantor Legal Entity Identifier": "",
        "Guarantor Name": "",
        "Guarantor ESA Subsector": "",
        "Protection Type": "",
        "Record Date Logic": "",
        "Beginning Cumulative Loss": "",
        "Beginning Shortfall": "",
        "Beginning WAC Shortfall": "",
        "Beginning Principal Balance": "",
        "Ending Shortfall Logic": "",
      },
      addswitchtoggle: false,
    });
  };

  onOpenModal1 = (value, editValue) => {
    console.log("value: ", value, editValue);
    console.log("view tranche modal");
    this.setState({
      open2: true,
      ViewStatus: true,
      formData1: {
        Name: value,
        Cusip: editValue[0],
        "Original Principal Balance": editValue[1],
        "Class Type": editValue[2],
        "Interest Type": editValue[3],
        "Interest Rate": editValue[4],
        "Index Code": editValue[5],
        Margin: editValue[6],
        "Day Count Method": editValue[7],
        "Maturity Date": editValue[8],
        "Loss Allocation Method": editValue[9],
        "Coupon Cap1": editValue[10],
        "Coupon Cap2": editValue[11],
        "Interest On Shortfall Flag": editValue[12],
        "Interest On WAC Shortfall Flag": editValue[13],
        "Deferral Allocation Method": editValue[14],
        "Stepup Margin": editValue[15],
        "Index Determination Date Logic": editValue[16],
        "Stepup Date": editValue[17],
        "Loss Allocation Sequence": editValue[18],
        "Interest Accrual Start Date Logic": editValue[19],
        "Interest Accrual End Date Logic": editValue[20],
        "Facility Type": editValue[21],
        "Original Commitment Balance": editValue[22],
        "Commitment Fee Rate": editValue[23],
        "Commitment Fee Basis": editValue[24],
        "Notional Flag": editValue[25],
        "Original Notional Balance": editValue[26],
        "Beginning Notional Balance Logic": editValue[27],
        "Ending Notional Balance Logic": editValue[28],
        "International Securities Identification Number": editValue[29],
        "Tranche/Bond Type": editValue[30],
        Currency: editValue[31],
        "Coupon Floor": editValue[32],
        "Coupon Cap": editValue[33],
        "Business Day Convention": editValue[34],
        "Current Interest Rate Index": editValue[35],
        "Current Interest Rate Index Tenor": editValue[36],
        "Extension Clause": editValue[37],
        "Next Call Date": editValue[38],
        "Clean-Up Call Threshold": editValue[39],
        "Next Put date": editValue[40],
        "Settlement Convention": editValue[41],
        "Current Attachment Point": editValue[42],
        "Original Attachment Point": editValue[43],
        "Credit Enhancement Formula": editValue[44],
        "Pari-Passu Tranches": editValue[45],
        "Senior Tranches": editValue[46],
        "Outstanding Principal Deficiency Ledger Balance": editValue[47],
        "Guarantor Legal Entity Identifier": editValue[48],
        "Guarantor Name": editValue[49],
        "Guarantor ESA Subsector": editValue[50],
        "Protection Type": editValue[51],
        "Record Date Logic": editValue[52],
        "Beginning Cumulative Loss": editValue[53],
        "Beginning Shortfall": editValue[54],
        "Beginning WAC Shortfall": editValue[55],
        "Beginning Principal Balance": editValue[56],
        "Ending Shortfall Logic": editValue[57],
      },
      EditPreviousName: value,
    });
  };

  onOpenModal2 = (value, editValue, getKeys) => {
    // Find the corresponding record based on the Name
    const getKeyData = getKeys.find((obj) => obj.Name === value);
    console.log("value: ", value, editValue, getKeyData);

    // Utility function to format percentages
    const formatPercentage = (field) =>
        getKeyData?.[field]?.replace(/%?/g, "") || "";

    // Utility function to format numbers with commas
    const formatNumber = (field) =>
        getKeyData?.[field] !== undefined && getKeyData?.[field] !== null
            ? getKeyData[field].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "";

    // If not in ViewStatus mode, process data and open the edit modal
    if (!this.state.ViewStatus) {
        this.setState({
            EditPreviousName: value,
            open3: true, // Open the edit modal
            open2: false, // Close other modal if open
            isAccordian: false, // Disable accordion view
            formData1: {
                Name: value,
                Cusip: getKeyData?.Cusip ,
                "Original Principal Balance": formatNumber("Original Principal Balance"),
                "Class Type": getKeyData?.["Class Type"] ,
                "Interest Type": getKeyData?.["Interest Type"] ,
                "Interest Rate": getKeyData?.["Interest Rate"] ,
                "Index Code": getKeyData?.["Index Code"] ,
                Margin: formatPercentage("Margin"),
                "Day Count Method": getKeyData?.["Day Count Method"] ,
                "Maturity Date": getKeyData?.["Maturity Date"] ,
                "Loss Allocation Method": getKeyData?.["Loss Allocation Method"] ,
                "Coupon Cap1": formatPercentage("Coupon Cap1"),
                "Coupon Cap2": formatPercentage("Coupon Cap2"),
                "Interest On Shortfall Flag": getKeyData?.["Interest On Shortfall Flag"] ,
                "Interest On WAC Shortfall Flag": getKeyData?.["Interest On WAC Shortfall Flag"] ,
                "Deferral Allocation Method": getKeyData?.["Deferral Allocation Method"] ,
                "Stepup Margin": formatPercentage("Stepup Margin"),
                "Index Determination Date Logic": getKeyData?.["Index Determination Date Logic"] ,
                "Stepup Date": getKeyData?.["Stepup Date"] ,
                "Loss Allocation Sequence": getKeyData?.["Loss Allocation Sequence"] ,
                "Interest Accrual Start Date Logic": getKeyData?.["Interest Accrual Start Date Logic"] ,
                "Interest Accrual End Date Logic": getKeyData?.["Interest Accrual End Date Logic"] ,
                "Facility Type": getKeyData?.["Facility Type"] ,
                "Original Commitment Balance": formatNumber("Original Commitment Balance"),
                "Commitment Fee Rate": getKeyData?.["Commitment Fee Rate"] ,
                "Commitment Fee Basis": getKeyData?.["Commitment Fee Basis"] ,
                "Notional Flag": getKeyData?.["Notional Flag"] || "NA",
                "Original Notional Balance": formatNumber("Original Notional Balance"),
                "Beginning Notional Balance Logic": getKeyData?.["Beginning Notional Balance Logic"] ,
                "Ending Notional Balance Logic": getKeyData?.["Ending Notional Balance Logic"] ,
                "International Securities Identification Number":
                    getKeyData?.["International Securities Identification Number"] ,
                "Tranche/Bond Type": getKeyData?.["Tranche/Bond Type"] ,
                Currency: getKeyData?.Currency ,
                "Coupon Floor": getKeyData?.["Coupon Floor"] ,
                "Coupon Cap": getKeyData?.["Coupon Cap"] ,
                "Business Day Convention": getKeyData?.["Business Day Convention"] ,
                "Current Interest Rate Index": getKeyData?.["Current Interest Rate Index"] ,
                "Current Interest Rate Index Tenor": getKeyData?.["Current Interest Rate Index Tenor"] ,
                "Extension Clause": getKeyData?.["Extension Clause"] ,
                "Next Call Date": getKeyData?.["Next Call Date"] ,
                "Clean-Up Call Threshold": getKeyData?.["Clean-Up Call Threshold"] ,
                "Next Put date": getKeyData?.["Next Put date"] ,
                "Settlement Convention": getKeyData?.["Settlement Convention"] ,
                "Current Attachment Point": getKeyData?.["Current Attachment Point"] ,
                "Original Attachment Point": getKeyData?.["Original Attachment Point"] ,
                "Credit Enhancement Formula": getKeyData?.["Credit Enhancement Formula"] ,
                "Pari-Passu Tranches": getKeyData?.["Pari-Passu Tranches"] ,
                "Senior Tranches": getKeyData?.["Senior Tranches"] ,
                "Outstanding Principal Deficiency Ledger Balance":
                    getKeyData?.["Outstanding Principal Deficiency Ledger Balance"] ,
                "Guarantor Legal Entity Identifier": getKeyData?.["Guarantor Legal Entity Identifier"] ,
                "Guarantor Name": getKeyData?.["Guarantor Name"] ,
                "Guarantor ESA Subsector": getKeyData?.["Guarantor ESA Subsector"] ,
                "Protection Type": getKeyData?.["Protection Type"] ,
                "Record Date Logic": getKeyData?.["Record Date Logic"] ,
                "Beginning Cumulative Loss": formatNumber("Beginning Cumulative Loss"),
                "Beginning Shortfall": formatNumber("Beginning Shortfall"),
                "Beginning WAC Shortfall": formatNumber("Beginning WAC Shortfall"),
                "Beginning Principal Balance": formatNumber("Beginning Principal Balance"),
                "Ending Shortfall Logic": getKeyData?.["Ending Shortfall Logic"] ,
            },
        });
    } else {
        // If ViewStatus is true, reset relevant fields and toggle modals
        this.setState({
            open3: true, // Open the edit modal
            open2: false, // Close other modal if open
            ViewStatus: false, // Switch from view-only mode to edit mode
            formData1: {
                ...this.state.formData1,
                "Stepup Margin": this.state.formData1["Stepup Margin"].replace(/%?/g, ""),
                Margin: this.state.formData1.Margin.replace(/%?/g, ""),
                "Original Principal Balance": this.state.formData1["Original Principal Balance"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                "Original Commitment Balance": this.state.formData1["Original Commitment Balance"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                "Original Notional Balance": this.state.formData1["Original Notional Balance"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                "Beginning Cumulative Loss": this.state.formData1["Beginning Cumulative Loss"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                "Beginning Shortfall": this.state.formData1["Beginning Shortfall"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                "Beginning WAC Shortfall": this.state.formData1["Beginning WAC Shortfall"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                "Beginning Principal Balance": this.state.formData1["Beginning Principal Balance"]
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            },
        });
    }
};

  
  
  shiftUp = (record, index) => {
    const { displayData , tableData } = this.state;
    const temp = [...displayData];
    [temp[index - 1], temp[index]] = [temp[index], temp[index - 1]];
    this.setState({ displayData: temp , tableData: temp });
  };

  shiftDown = (record, index) => {
    const { displayData , tableData } = this.state;
    const temp = [...displayData];
    [temp[index], temp[index + 1]] = [temp[index + 1], temp[index]];
    this.setState({ displayData: temp , tableData: temp});
  };
  
  
  
  onOpenModal3 = (record) => {
    console.log("delete tranche modal");
    this.setState({
      open4: true,
      isAccordian: false,
      deleteRowVal: { ...record },
    });
  };
  
  onCloseModal = () => {
    this.setState({ open1: false });
  };

  onCloseModal1 = () => {
    this.setState({ open2: false });
  };

  onCloseModal2 = () => {
    this.setState({ open3: false });
  };
  onCloseModal3 = () => {
    this.setState({ open4: false });
  };

  AddSubmit = (e) => {
    e.preventDefault();
    const renameStepUpMargin =
      this.state.formData["Stepup Margin"] !== ""
        ? `${this.state.formData["Stepup Margin"]}%`
        : this.state.formData["Stepup Margin"];
    delete this.state.formData["Stepup Margin"];
    this.state.formData["Stepup Margin"] = renameStepUpMargin;

    const renameMargin =
      this.state.formData.Margin !== ""
        ? `${this.state.formData.Margin}%`
        : this.state.formData.Margin;
    delete this.state.formData.Margin;
    this.state.formData.Margin = renameMargin;

    const renameCouponCap1 =
      this.state.formData["Coupon Cap1"] !== "" &&
      typeof this.state.formData["Coupon Cap1"] !== "string"
        ? `${this.state.formData["Coupon Cap1"]}%`
        : this.state.formData["Coupon Cap1"];
    delete this.state.formData["Coupon Cap1"];
    this.state.formData["Coupon Cap1"] = renameCouponCap1;

    const renameCouponCap2 =
      this.state.formData["Coupon Cap2"] !== "" &&
      typeof this.state.formData["Coupon Cap2"] !== "string"
        ? `${this.state.formData["Coupon Cap2"]}%`
        : this.state.formData["Coupon Cap2"];
    delete this.state.formData["Coupon Cap2"];
    this.state.formData["Coupon Cap2"] = renameCouponCap2;

    let renameOPB;

    if (this.state.formData["Original Principal Balance"].trim() === "") {
      // If nothing is entered, set Original Principal Balance as empty string
      renameOPB = "";
    } else {
      // If a value is entered, parse and rename Original Principal Balance
      renameOPB = parseFloat(
        this.state.formData["Original Principal Balance"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Original Principal Balance"];
    this.state.formData["Original Principal Balance"] = renameOPB;

    // For Original Commitment Balance
    let renameOCB;

    if (this.state.formData["Original Commitment Balance"].trim() === "") {
      // If nothing is entered, set Original Commitment Balance as empty string
      renameOCB = "";
    } else {
      // If a value is entered, parse and rename Original Commitment Balance
      renameOCB = parseFloat(
        this.state.formData["Original Commitment Balance"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Original Commitment Balance"];
    this.state.formData["Original Commitment Balance"] = renameOCB;

    // For Original Notional Balance
    let renameONB;

    if (this.state.formData["Original Notional Balance"].trim() === "") {
      // If nothing is entered, set Original Notional Balance as empty string
      renameONB = "";
    } else {
      // If a value is entered, parse and rename Original Notional Balance
      renameONB = parseFloat(
        this.state.formData["Original Notional Balance"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Original Notional Balance"];
    this.state.formData["Original Notional Balance"] = renameONB;

    // For Beginning Cumulative Loss
    let renameBCS;

    if (this.state.formData["Beginning Cumulative Loss"].trim() === "") {
      // If nothing is entered, set Beginning Cumulative Loss as empty string
      renameBCS = "";
    } else {
      // If a value is entered, parse and rename Beginning Cumulative Loss
      renameBCS = parseFloat(
        this.state.formData["Beginning Cumulative Loss"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Beginning Cumulative Loss"];
    this.state.formData["Beginning Cumulative Loss"] = renameBCS;

    // For Beginning Shortfall
    let renameBS;

    if (this.state.formData["Beginning Shortfall"].trim() === "") {
      // If nothing is entered, set Beginning Shortfall as empty string
      renameBS = "";
    } else {
      // If a value is entered, parse and rename Beginning Shortfall
      renameBS = parseFloat(
        this.state.formData["Beginning Shortfall"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Beginning Shortfall"];
    this.state.formData["Beginning Shortfall"] = renameBS;

    // For Beginning WAC Shortfall
    let renameBWS;

    if (this.state.formData["Beginning WAC Shortfall"].trim() === "") {
      // If nothing is entered, set Beginning WAC Shortfall as empty string
      renameBWS = "";
    } else {
      // If a value is entered, parse and rename Beginning WAC Shortfall
      renameBWS = parseFloat(
        this.state.formData["Beginning WAC Shortfall"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Beginning WAC Shortfall"];
    this.state.formData["Beginning WAC Shortfall"] = renameBWS;

    // For Beginning Principal Balance
    let renameBPB;

    if (this.state.formData["Beginning Principal Balance"].trim() === "") {
      // If nothing is entered, set Beginning Principal Balance as empty string
      renameBPB = "";
    } else {
      // If a value is entered, parse and rename Beginning Principal Balance
      renameBPB = parseFloat(
        this.state.formData["Beginning Principal Balance"].replace(/,/g, "")
      );
    }

    delete this.state.formData["Beginning Principal Balance"];
    this.state.formData["Beginning Principal Balance"] = renameBPB;

    // For Maturity Date

    let renameMaturityDate;

    if (this.state.formData["Maturity Date"] === null) {
      // If nothing is entered, set Maturity Date as empty string
      renameMaturityDate = "";
    }

    delete this.state.formData["Maturity Date"];
    this.state.formData["Maturity Date"] = renameMaturityDate;

    console.log("check", this.state.formData);
    this.dealCreationAddSave();
    console.log("hello we have clicked the button");
  };
  EditSubmit = (e) => {
    e.preventDefault();
    const renameStepUpMargin =
      this.state.formData1["Stepup Margin"] !== ""
        ? `${this.state.formData1["Stepup Margin"]}%`
        : this.state.formData1["Stepup Margin"];
    delete this.state.formData1["Stepup Margin"];
    this.state.formData1["Stepup Margin"] = renameStepUpMargin;

    const renameMargin =
      this.state.formData1.Margin !== ""
        ? `${this.state.formData1.Margin}%`
        : this.state.formData1.Margin;
    delete this.state.formData1.Margin;
    this.state.formData1.Margin = renameMargin;

    const renameCouponCap1 =
      this.state.formData1["Coupon Cap1"] !== "" &&
      typeof this.state.formData1["Coupon Cap1"] !== "string"
        ? `${this.state.formData1["Coupon Cap1"]}%`
        : this.state.formData1["Coupon Cap1"];
    delete this.state.formData1["Coupon Cap1"];
    this.state.formData1["Coupon Cap1"] = renameCouponCap1;

    const renameCouponCap2 =
      this.state.formData1["Coupon Cap2"] !== "" &&
      typeof this.state.formData1["Coupon Cap2"] !== "string"
        ? `${this.state.formData1["Coupon Cap2"]}%`
        : this.state.formData1["Coupon Cap2"];
    delete this.state.formData1["Coupon Cap2"];
    this.state.formData1["Coupon Cap2"] = renameCouponCap2;

    const renameOPB = this.state.formData1["Original Principal Balance"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Original Principal Balance"].replace(/,/g, "")
        )
      : this.state.formData1["Original Principal Balance"];
    delete this.state.formData1["Original Principal Balance"];
    this.state.formData1["Original Principal Balance"] = renameOPB;

    const renameOCB = this.state.formData1["Original Commitment Balance"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Original Commitment Balance"].replace(/,/g, "")
        )
      : this.state.formData1["Original Commitment Balance"];
    delete this.state.formData1["Original Commitment Balance"];
    this.state.formData1["Original Commitment Balance"] = renameOCB;

    const renameONB = this.state.formData1["Original Notional Balance"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Original Notional Balance"].replace(/,/g, "")
        )
      : this.state.formData1["Original Notional Balance"];
    delete this.state.formData1["Original Notional Balance"];
    this.state.formData1["Original Notional Balance"] = renameONB;

    const renameBCL = this.state.formData1["Beginning Cumulative Loss"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Beginning Cumulative Loss"].replace(/,/g, "")
        )
      : this.state.formData1["Beginning Cumulative Loss"];
    delete this.state.formData1["Beginning Cumulative Loss"];
    this.state.formData1["Beginning Cumulative Loss"] = renameBCL;

    const renameBS = this.state.formData1["Beginning Shortfall"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Beginning Shortfall"].replace(/,/g, "")
        )
      : this.state.formData1["Beginning Shortfall"];
    delete this.state.formData1["Beginning Shortfall"];
    this.state.formData1["Beginning Shortfall"] = renameBS;

    const renameBWS = this.state.formData1["Beginning WAC Shortfall"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Beginning WAC Shortfall"].replace(/,/g, "")
        )
      : this.state.formData1["Beginning WAC Shortfall"];
    delete this.state.formData1["Beginning WAC Shortfall"];
    this.state.formData1["Beginning WAC Shortfall"] = renameBWS;

    const renameBPB = this.state.formData1["Beginning Principal Balance"]
      .toString()
      .includes(",")
      ? parseFloat(
          this.state.formData1["Beginning Principal Balance"].replace(/,/g, "")
        )
      : this.state.formData1["Beginning Principal Balance"];
    delete this.state.formData1["Beginning Principal Balance"];
    this.state.formData1["Beginning Principal Balance"] = renameBPB;

    console.log(this.state.formData1);
    this.dealCreationEdit();
    console.log("hello we have clicked the Edit button");
  };

  showNext = (e) => {
    e.preventDefault();
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/fees");
  };
  showPrev = (e) => {
    e.preventDefault();
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
      activeInsights5: false,
      activeInsights6: false,
      activeInsights7: false,
      activeInsights8: false,
      activeInsightsBB: false,
      activeInsightsMI: false,
    });
    this.dealCreationAutoSave();
    // this.props.history.push("/admin/general");
  };

  dealCreationDelete = async () => {
    this.setState({ formLoader: true });
    let data = {};
    data.peers = this.state.peers;
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    const NameAdd1 = this.state.deleteRowVal.Name;
    const Obj = {
      [`${NameAdd1}`]: {
        ...this.state.deleteRowVal,
      },
    };
    data.Deleterow = Obj;
    data.peers = this.state.peers;
    console.log("dataDelete", data);
    const APIResponse = await dealCreationDelete(data);

    const deleteRowVal = this.state.deleteRowVal; // Assuming deleteRowVal contains the key-value pair you want to delete based on

    // Retrieve existing added content from sessionStorage if any
    let existingAddedContent = [];

    // Check if sessionStorage contains an item with key "AddedContent"
    const sessionStorageItem = sessionStorage.getItem("AddedContent");
    if (sessionStorageItem) {
      // Parse the sessionStorage item if it exists
      existingAddedContent = JSON.parse(sessionStorageItem);
    }

    // Filter out the object based on the key-value pair from deleteRowVal
    existingAddedContent = existingAddedContent.filter((item) => {
      for (let key in deleteRowVal) {
        if (item[key] !== deleteRowVal[key]) {
          return true; // Keep the item if any key-value pair doesn't match
        }
      }
      return false; // Exclude the item if all key-value pairs match
    });

    // Store the updated added content in sessionStorage
    sessionStorage.setItem(
      "AddedContent",
      JSON.stringify(existingAddedContent)
    );

    if (APIResponse.status === 200) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal3();
      this.savedealservicerdate();
      this.dealCreationTable();
    } else {
      this.setState({ formLoader: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  dealCreationAddSave = async () => {
    this.setState({ formLoader: true });
    let data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    const NameAdd = this.state.formData.Name;
    const ObjAdd = {
      [`${NameAdd}`]: {
        ...this.state.formData,
      },
    };
    // Make a copy of formData
    const formDataCopy = { ...this.state.formData };

    // Retrieve existing added content from sessionStorage if any
    const existingAddedContentString = sessionStorage.getItem("AddedContent");
    const existingAddedContent = existingAddedContentString
      ? JSON.parse(existingAddedContentString)
      : [];

    // Combine existing added content with new content and store it in sessionStorage
    const updatedAddedContent = [...existingAddedContent, formDataCopy];
    sessionStorage.setItem("AddedContent", JSON.stringify(updatedAddedContent));

    data.TableData = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await dealCreationAddSave(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false, isAdded: true });
        this.savedealservicerdate();
        this.dealCreationTable();
        this.onCloseModal();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal();
    }
  };
  dealCreationEdit = async () => {
    this.setState({ formLoader: true });
    let data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.EditKey = this.state.EditPreviousName;
    const NameAdd = this.state.formData1.Name;
    const ObjAdd = {
      [`${NameAdd}`]: {
        ...this.state.formData1,
      },
    };
    data.TableData = ObjAdd;
    data.peers = this.state.peers;
    console.log("dataAddddd", data);

    const APIResponse = await dealCreationEdit(data);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.savedealservicerdate();
        this.dealCreationTable();
        this.onCloseModal2();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.onCloseModal2();
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
      this.onCloseModal2();
    }
  };
  dealCreationAutoSave = async () => {
    var data = {};
    data.DealName = this.state.DealName;
    data.peers = this.state.peers;
    data.TableName = this.state.TableName;
    let Obj = {};
    this.state.tableData.forEach((item) => {
      // Create a new object for each item
      let modifiedItem = { ...item };

      // Apply the condition to each key in formData1
      Object.keys(this.state.formData1).forEach((key) => {
        if (typeof modifiedItem[key] === "undefined") {
          modifiedItem[key] =
            this.state.formData1[key] === undefined
              ? ""
              : this.state.formData1[key];
        }
      });

      Obj = {
        ...Obj,
        [`${item.Name}`]: modifiedItem,
      };
    });
    data.TableData = Obj;
    console.log(data);
    const APIResponse = await dealCreationAutoSave(data);
    if (!this.state.fileUploaded) {
      this.savedealservicerdate();
    }

    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    if (this.state.fileUploaded) {
      await delay(3000); // Wait for 3 seconds
      window.location.reload();
    }

    this.setState({ fileUploaded: false }, () => {
      if (!this.state.fileUploaded) {
        sessionStorage.setItem("AddedContent", []);
        sessionStorage.setItem("TranchItems", []);
      }
    });
    console.log("ress", APIResponse);
  };

  dealCreationCreateUpdate = async () => {
    this.setState({ formLoader2: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.peers = this.state.peers;
    let Obj = {};
    this.state.tableData.forEach((item) => {
      // Create a new object for each item
      let modifiedItem = { ...item };

      // Apply the condition to each key in formData1
      Object.keys(this.state.formData1).forEach((key) => {
        if (typeof modifiedItem[key] === "undefined") {
          modifiedItem[key] =
            this.state.formData1[key] === undefined
              ? ""
              : this.state.formData1[key];
        }
      });

      Obj = {
        ...Obj,
        [`${item.Name}`]: modifiedItem,
      };
    });
    data.TableData = Obj;
    console.log(data);
    const APIResponse = await dealCreationCreateUpdate(data);
    console.log("ress", APIResponse);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader2: false, isUpdate: true });
        this.dealCreation_BC_Status();
        this.savedealservicerdate();
        // this.dealCreationTable()
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader2: false });
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader2: false });
    }
  };

  dealCreationSaveForNow = async () => {
    this.setState({ formLoader1: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.peers = this.state.peers;
    let Obj = {};
    this.state.tableData.forEach((item) => {
      // Create a new object for each item
      let modifiedItem = { ...item };

      // Apply the condition to each key in formData1
      Object.keys(this.state.formData1).forEach((key) => {
        if (typeof modifiedItem[key] === "undefined") {
          modifiedItem[key] =
            this.state.formData1[key] === undefined
              ? ""
              : this.state.formData1[key];
        }
      });

      Obj = {
        ...Obj,
        [`${item.Name}`]: modifiedItem,
      };
    });
    data.TableData = Obj;
    console.log(data);
    const APIResponse = await dealCreationSaveForNow(data);
    console.log("ress", APIResponse);
    if (APIResponse.status === 200) {
      if (APIResponse.data.isSuccess === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader1: false, isUpdate: true });
        this.savedealservicerdate();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader1: false });
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader1: false });
    }
  };

  dealCreationTable = async () => {
    this.setState({
      getLoansLoader: true,
      tableData: [],
      loading: true,
      screenloader: true,
    });
    var data = {};
    data.DealName = this.state.DealName;
    data.TableName = this.state.TableName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await dealCreationTable(data);

    if (APIResponse.status === 200) {
      this.setState(
        {
          getLoansLoader: false,
          tableData: APIResponse.data,
          displayData:APIResponse.data,
          loading: false,
          isTranches: true,
        },
        () =>
          sessionStorage.setItem(
            "TranchItems",
            JSON.stringify(this.state.tableData)
          )
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      this.dealCreation_BC_Status();
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      // Handle the case where no file is selected
      this.setState({ fileUploaded: false });
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(new Uint8Array(data), {
        type: "array",
        cellDates: true,
      });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const range = XLSX.utils.decode_range(sheet["!ref"]);
      const parsedData = [];

      // Extract headers
      const headers = [];
      for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
        const headerCellAddress = XLSX.utils.encode_cell({
          r: range.s.r,
          c: colIndex,
        });
        const headerCellValue = sheet[headerCellAddress]
          ? sheet[headerCellAddress].v
          : "";
        headers.push(headerCellValue);
      }

      // console.log(">>>>>>>>>>>", headers);

      // Parse each row
      for (let rowIndex = range.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
        const rowData = {};
        for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
          const cellAddress = XLSX.utils.encode_cell({
            r: rowIndex,
            c: colIndex,
          });
          const cell = sheet[cellAddress];
          const header = headers[colIndex - range.s.c];
          rowData[header] = cell ? cell.v : ""; // If cell doesn't exist, set empty string
        }
        parsedData.push(rowData);
      }

      // Handle missing keys by filling them with empty strings
      parsedData.forEach((row) => {
        headers.forEach((header) => {
          if (!row.hasOwnProperty(header)) {
            row[header] = "";
          }
        });
      });
      // console.log(">>>>>>>>>>>", parsedData);

      const sessionStorageItem = sessionStorage.getItem("AddedContent");
      const sessionStorageItemTranches = sessionStorage.getItem("TranchItems");
      let AddedData = sessionStorageItem ? JSON.parse(sessionStorageItem) : [];
      let sessionTranches = sessionStorageItemTranches
        ? JSON.parse(sessionStorageItemTranches)
        : [];

      // console.log("???????",sessionTranches);

      let filteredParsedData = [];
      let newParsedAddedData = [];

      if (this.state.isAdded) {
        // Compare every key-value pair for filteredParsedData
        filteredParsedData = sessionTranches.filter((tranche) => {
          return !AddedData.some((added) => {
            return Object.keys(tranche).every((key) => {
              // Check if the key exists in the added data, otherwise set its value to an empty string
              const addedValue = added[key] !== undefined ? added[key] : "";
              return addedValue === tranche[key];
            });
          });
        });
        newParsedAddedData = [...filteredParsedData, ...AddedData];
      } else if (this.state.isTranches) {
        // Compare every key-value pair for filteredParsedData
        // filteredParsedData = parsedData.filter((data) => {
        //   console.log("ParsedData", data);
        //   return !sessionTranches.some((tranche) => {
        //     console.log("sessionTranches", tranche);
        //     return Object.keys(data).every((key) => {
        //       // Check if the key exists in the tranche data, otherwise set its value to an empty string
        //       const trancheValue =
        //         tranche[key] !== undefined ? tranche[key] : "";
        //       return trancheValue === data[key];
        //     });
        //   });
        // });
        // newParsedAddedData = [...sessionTranches, ...filteredParsedData];
        newParsedAddedData = parsedData;
      }
      // console.log(">>>>>>>>>>>", newParsedAddedData);

      this.setState(
        {
          fileUploaded: true,
          tableData: newParsedAddedData,
          displayData:newParsedAddedData,
          isTranches: false,
          isAdded: false,
        },
        async () => await this.dealCreationAutoSave()
      );
    };
  };

  handleDownloadClick = (columns) => {
    const ColumnsForNoData = columns.slice(0, 59).map((item) => item.name);

    let ws;

    // Check if tableData is empty
    if (this.state.tableData.length === 0) {
      // Create an empty object with ColumnsForNoData as keys to initialize headers
      const emptyRow = ColumnsForNoData.reduce((obj, key) => {
        obj[key] = ""; // Initialize each key with an empty string
        return obj;
      }, {});

      // Create a worksheet with the emptyRow to ensure headers are shown
      ws = XLSX.utils.json_to_sheet([emptyRow], { header: ColumnsForNoData });
    } else {
      // Create a worksheet with the actual tableData
      ws = XLSX.utils.json_to_sheet(this.state.tableData);
    }

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, "Tranches.xlsx");
  };

  dealCreation_BC_Status = async () => {
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;
    // console.log(data);
    const APIResponse = await dealCreation_BC_Status(data);
    console.log("ress", APIResponse);
    this.setState({
      createStatus: APIResponse.data.status,
      screenloader: false,
    });
  };

  savedealservicerdate = async () => {
    let data = {};
    data.DealName = this.state.DealName;
    data.peers = this.state.peers;
    console.log(data);
    const APIResponse = await savedealservicerdate(data);
    console.log("ress", APIResponse);
    if (this.state.activeInsights1 === true) {
      this.props.history.push({
        pathname: "/admin/general",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights2 === true) {
      this.props.history.push({
        pathname: "/admin/tranches",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights3 === true) {
      this.props.history.push({
        pathname: "/admin/fees",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights4 === true) {
      this.props.history.push({
        pathname: "/admin/expenses",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights5 === true) {
      this.props.history.push({
        pathname: "/admin/account",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights6 === true) {
      this.props.history.push({
        pathname: "/admin/tests",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsightsBB === true) {
      this.props.history.push({
        pathname: "/admin/borrowingBase",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsights7 === true) {
      this.props.history.push({
        pathname: "/admin/variables",
      });
    } else if (this.state.activeInsights8 === true) {
      this.props.history.push({
        pathname: "/admin/paymentrules",
        state: { checkDeal: false },
      });
    } else if (this.state.activeInsightsMI === true) {
      this.props.history.push({
        pathname: "/admin/manualinput",
        state: { checkDeal: false },
      });
    }
    if (this.state.isUpdate) {
      window.location.reload();
      this.setState({ isUpdate: false });
    }
  };

  handleAccordian = () => {
    this.setState((prevState) => {
      return { isAccordian: !prevState.isAccordian };
    });
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    await this.dealCreationTable();
  }

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even) > td:first-child": {
              backgroundColor: "white !important",
              position: "relative",
              "&::before": {
                content: "''",
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: "rgb(229, 229, 229, 0.3) !important",
              },
              "&:first-child": {
                position: "sticky",
                left: 0,
                top: 0,
                zIndex: 1,
                backgroundColor: "rgba(255, 255, 255, 1)",
              },
              "& > *": {
                color: "#000 !important",
              },
            },
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },

        MUIDataTableBodyCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            fontWeight: "400 !important",
            fontSize: "15px !important",
            borderBottom: "none !important",
            "&::after": {
              content: "",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "white",
              zIndex: -99,
            },
            "@media (min-width: 800px)": {
              "&:first-child": {
                position: "sticky",
                left: 0,
                top: 0,
                zIndex: 1,
                backgroundColor: "rgba(255, 255, 255, 1)", // Adjust the alpha value to your preference
              },
            },
          },
        },

        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "white !important",
            backgroundClip: "padding-box",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
            position: "relative",
            zIndex: 1,
            "&:first-child": {
              position: "sticky",
              left: 0,
              zIndex: 500,
              top: 0,
            },
            "&::after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(1, 142, 130, 0.1) !important",
              zIndex: -1,
            },
          },
          toolButton: {
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish, sans-serif !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            fontWeight: "600 !important",
            fontFamily: "Mulish, sans-serif !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },

        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            position: "relative",
            overflowX: "auto",
            maxHeight: "600px",
            overflowY:
              "auto" /* Use "auto" to enable scrollbar only when needed */,
            scrollbarWidth: "thin",
            "-ms-overflow-style": "none",
            borderRadius: "10px" /* Set the border radius to the scroll area */,
            "&::-webkit-scrollbar": {
              width: "1em" /* Adjust to hide the scrollbar */,
              display: "none" /* Hide the scrollbar */,
            },
          },
        },
      },
    });

  // getMuiTheme = () =>
  //   createMuiTheme({
  //     typography: {
  //       useNextVariants: true,
  //     },
  //     overrides: {
  //       MUIDataTable: {
  //         root: {
  //           border: "none !important",
  //         },
  //       },
  //       MUIDataTableBodyRow: {
  //         root: {
  //           "&:nth-child(even)": {
  //             backgroundColor: "rgb(229,229,229,0.3) !important",
  //           },
  //           "&.Mui-selected": {
  //             backgroundColor: "white !important",
  //           },
  //         },
  //         hoverCursor: {
  //           cursor: "auto !important",
  //         },
  //       },
  //       MuiTableCell: {
  //         root: {
  //           fontFamily: "Mulish, sans-serif !important",
  //           padding: "20px",
  //           fontSize: "0.980rem !important",
  //         },
  //       },

  //       MUIDataTableBodyCell: {
  //         root: {
  //           fontFamily: "Mulish, sans-serif !important",
  //           fontWeight: "400 !important",
  //           fontSize: "15px !important",
  //           borderBottom: "none !important",
  //         },
  //       },
  //       MuiCircularProgress: {
  //         colorSecondary: {
  //           color: "#048c88 !important",
  //         },
  //       },
  //       MUIDataTableHeadCell: {
  //         root: {
  //           fontFamily: "Mulish, sans-serif !important",
  //           backgroundColor: "rgba(1, 142, 130, 0.1) !important",
  //           borderBottom: "none !important",
  //           paddingBottom: "5px !important",
  //           paddingTop: "5px !important",
  //           paddingLeft: "15px !important",
  //         },
  //         toolButton: {
  //           fontWeight: "600 !important",
  //           fontSize: "15px !important",
  //           backgroundColor: "none !important",
  //           padding: "none !important",
  //           marginLeft: "none !important",
  //           textTransform: "none !important",
  //           border: "none !important",
  //           borderRadius: "none !important",
  //         },
  //       },
  //       MUIDataTableToolbar: {
  //         root: {
  //           fontFamily: "Mulish, sans-serif !important",
  //           paddingLeft: "5px !important",
  //           paddingRight: "5px !important",
  //         },
  //         titleText: {
  //           fontFamily: "Mulish, sans-serif !important",
  //           fontSize: "28px",
  //           color: "#212121",
  //           fontWeight: "600",
  //           fontFamily: "arial",
  //           marginBottom: "20px",
  //           marginTop: "20px",
  //         },
  //         icon: {
  //           color: "#018E82",
  //           paddingRight: "14px !important",
  //           "&:hover": {
  //             color: "#018E82 !important",
  //           },
  //         },
  //         iconActive: {
  //           color: "#018E82 !important",
  //         },
  //       },
  //       MuiButton: {
  //         contained: {
  //           backgroundColor: "#FFC000 !important",
  //           padding: "5px 30px !important",
  //           marginLeft: "10px !important",
  //           textTransform: "none !important",
  //           border: "1.2px solid #212121 !important",
  //           borderRadius: "20px !important",
  //           boxShadow: "none !important",
  //         },
  //         outlined: {
  //           fontFamily: "Mulish, sans-serif !important",
  //           backgroundColor: "#fff !important",
  //           padding: "5px 30px !important",
  //           marginLeft: "10px !important",
  //           textTransform: "none !important",
  //           border: "1.2px solid #212121 !important",
  //           borderRadius: "20px !important",
  //           boxShadow: "none !important",
  //         },
  //         label: {
  //           fontSize: "15px !important",
  //           fontWeight: "600 !important",
  //           fontFamily: "Mulish, sans-serif !important",
  //         },
  //         textPrimary: {
  //           color: "#018E82 !important",
  //         },
  //       },
  //       MUIDataTablePagination: {
  //         tableCellContainer: {
  //           borderBottom: "none !important",
  //         },
  //         navContainer: {
  //           justifyContent: "center",
  //         },
  //         toolbar: {
  //           paddingLeft: "50px !important",
  //         },
  //       },
  //       MuiTableSortLabel: {
  //         icon: {
  //           color: "#018E82 !important",
  //         },
  //         active: {
  //           color: "#018E82 !important",
  //         },
  //       },
  //       MuiTablePagination: {
  //         caption: {
  //           color: "#8C8C8C",
  //           marginRight: `${
  //             this.state.currentPage >= 1 && this.state.currentPage <= 9
  //               ? "-138"
  //               : this.state.currentPage >= 10
  //               ? "-142"
  //               : "-130"
  //           }px`,
  //           fontSize: "0.80rem",
  //         },
  //       },
  //       MuiIconButton: {
  //         colorInherit: {
  //           color: "#018E82 !important",
  //           zIndex: "1000",
  //           marginRight: "60px",
  //           paddingLeft: "-25px",
  //         },
  //       },

  //       MUIDataTable: {
  //         paper: {
  //           boxShadow: "none !important",
  //         },
  //         responsiveBase: {
  //           border: "1px solid #212121 !important",
  //           borderRadius: "10px !important",
  //         },
  //       },
  //     },
  //   });

  goBackToDashBoard = () => {
    this.state.isRecurring
      ? this.props.history.push({
          isRecurring: sessionStorage.removeItem("isAddDeal"),
          pathname: "/dashboard",
        })
      : this.props.history.push({
          pathname: "/admin/tranches_recurring",
        });
  };

   handleFilter = (tableName, data, columnKey, criteria) => {

    const { filterCriteria, tableData, currentBatch, itemsPerBatch } =
      this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    if (isCriteriaEmpty) {
      // Reset to the first batch of the full dataset if no criteria are selected
      this.setState({
        displayData: tableData.slice(0, currentBatch * itemsPerBatch),
        filteredClrChangeColumns: {
          ...this.state.filteredClrChangeColumns,
          [columnKey]: false,
        },
      });
      return;
    }

    // Apply filters
    const filtered = tableData.filter((item) => {
      return Object.keys(newCriteria).every((key) => {
        const criteria = newCriteria[key];
        if (!criteria) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return false;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });

    console.log({ filtered });

    // Apply sorting after filtering
    const { sortOrder } = this.state;
    const sortedFiltered = [...filtered].sort((a, b) => {
      const valueA = a[sortOrder.columnKey];
      const valueB = b[sortOrder.columnKey];

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return sortOrder.order === "ascend"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder.order === "ascend"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    });

    // Update display data to only show the current batch
    this.setState({
      displayData: sortedFiltered.slice(0, currentBatch * itemsPerBatch),
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handleSort = (tableName, columnKey, order) => {
    const { tableData, itemsPerBatch } = this.state;

    // Determine the chunk size based on the total dataset size
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        if (!isNaN(valueA) && !isNaN(valueB)) {
          return order === "ascend" ? valueA - valueB : valueB - valueA;
        }

        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        return 0;
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        this.setState({
          tableData: sortedData,
          displayData: sortedData.slice(0, itemsPerBatch),
        });
      }
    };

    sortChunk(0);
  };

  handlePaginationChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { displayData, currentPage, pageSize, loading } = this.state;

    // Paginate data manually
    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    const { classes } = this.props;
    const options = {
      filterType: "dropdown",
      filter: false,
      search: false,
      print: false,
      viewColumns: false,
      download: false,
      rowHover: false,
      selectableRowsOnClick: false,
      selectableRows: "none",
      onRowClick: this.onRowClick,
      onRowSelectionChange: this.onRowSelectionChange,
      onChangePage: this.onChangePage,
      rowsSelected: this.state.rowsSelected,
      rowsPerPage: [10],
      rowsPerPageOptions: false,
      jumpToPage: false,
      pagination: false,

      onRowSelectionChange: (rowsSelected, allRows) => {
        console.log("allRows", allRows);
        console.log("rowsSelected", rowsSelected);
        this.setState({ rowsSelected: allRows.map((row) => row.dataIndex) });
        const selected = allRows.map((row) => row.dataIndex);
        console.log("selected" + selected);
        this.selectedpoolid(selected);
      },

      onChangePage: (currentPage) => {
        console.log("currentPage", currentPage);
        this.setState({ currentPage: currentPage });
      },

      searchText: this.state.searchText,
      searchPlaceholder: "Search",
      customSearch: (searchQuery, currentRow, columns) => {
        let isFound = false;
        currentRow.forEach((col) => {
          if (col.toString().indexOf(searchQuery) >= 0) {
            isFound = true;
          }
        });
        return isFound;
      },

      //
      loading: false,
      textLabels: {
        body: {
          noMatch:
            this.state.loading === true ? (
              <Loader msg={"Please wait, Loading Loan Data"} />
            ) : (
              "Sorry, there is no matching data to display"
            ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: "All",
          title: "FILTERS",
          reset: "RESET",
        },

        selectedRows: {
          text: "row(s) selected",
          delete: "Delete",
          deleteAria: "Delete Selected Rows",
        },
        pagination: {
          next: "Next ",
          previous: "Previous",
          rowsPerPage: "",
          displayRows: "Of",
        },
      },
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        fixed:"left",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),      },
      {
        title: "Cusip",
        dataIndex: "Cusip",
        key: "Cusip",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Cusip"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Cusip")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Cusip"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Original Principal Balance",
        dataIndex: "Original Principal Balance",
        key: "Original Principal Balance",
        render: (value) => <NumberComp4 value={value} />,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Original Principal Balance"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Original Principal Balance")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Original Principal Balance"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Class Type",
        dataIndex: "Class Type",
        key: "Class Type",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Class Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Class Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Class Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Interest Type",
        dataIndex: "Interest Type",
        key: "Interest Type",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Interest Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Interest Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Interest Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Interest Rate",
        dataIndex: "Interest Rate",
        key: "Interest Rate",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Interest Rate"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Interest Rate")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Interest Rate"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Index Code",
        dataIndex: "Index Code",
        key: "Index Code",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Index Code"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Index Code")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Index Code"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Margin",
        dataIndex: "Margin",
        key: "Margin",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Margin"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Margin")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Margin"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Day Count Method",
        dataIndex: "Day Count Method",
        key: "Day Count Method",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Day Count Method"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Day Count Method")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Day Count Method"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Maturity Date",
        dataIndex: "Maturity Date",
        key: "Maturity Date",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Maturity Date"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Maturity Date")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Maturity Date"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          value !== "Invalid date" && value !== null ? (
            <div style={{ paddingRight: "2rem" }}>{value}</div>
          ) : (
            ""
          ),
      },
      {
        title: "Loss Allocation Method",
        dataIndex: "Loss Allocation Method",
        key: "Loss Allocation Method",
        hidden:
            this.state.isSecuritisation !== "Securitisation" ? true : false,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Loss Allocation Method"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Loss Allocation Method")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Loss Allocation Method"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "2rem" }}>{value}</div>,
      },
      {
        title: "Coupon Cap1",
        dataIndex: "Coupon Cap1",
        key: "Coupon Cap1",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Coupon Cap1"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Coupon Cap1")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Coupon Cap1"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          value === "" ? "NA" : <NumberComp4 value={value} />,
      },
      {
        title: "Coupon Cap2",
        dataIndex: "Coupon Cap2",
        key: "Coupon Cap2",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Coupon Cap2"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Coupon Cap2")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Coupon Cap2"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          value === "" ? "NA" : <NumberComp4 value={value} />,
      },
      {
        title: "Interest On Shortfall Flag",
        dataIndex: "Interest On Shortfall Flag",
        key: "Interest On Shortfall Flag",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Interest On Shortfall Flag"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Interest On Shortfall Flag")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Interest On Shortfall Flag"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Interest On WAC Shortfall Flag",
        dataIndex: "Interest On WAC Shortfall Flag",
        key: "Interest On WAC Shortfall Flag",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Interest On WAC Shortfall Flag"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Interest On WAC Shortfall Flag")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Interest On WAC Shortfall Flag"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Deferral Allocation Method",
        dataIndex: "Deferral Allocation Method",
        key: "Deferral Allocation Method",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deferral Allocation Method"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Deferral Allocation Method")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Deferral Allocation Method"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1.5rem" }}>{value}</div>,
      },
      {
        title: "Stepup Margin",
        dataIndex: "Stepup Margin",
        key: "Stepup Margin",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Stepup Margin"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Stepup Margin")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Stepup Margin"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Index Determination Date Logic",
        dataIndex: "Index Determination Date Logic",
        key: "Index Determination Date Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Index Determination Date Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Index Determination Date Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Index Determination Date Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "2rem" }}>{value}</div>,
      },
      {
        title: "Stepup Date",
        dataIndex: "Stepup Date",
        key: "Stepup Date",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Stepup Date"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Stepup Date")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Stepup Date"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          value !== "Invalid date" ? (
            <div style={{ paddingRight: "2rem" }}>{value}</div>
          ) : (
            ""
          ),
      },
      {
        title: "Loss Allocation Sequence",
        dataIndex: "Loss Allocation Sequence",
        key: "Loss Allocation Sequence",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Loss Allocation Sequence"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Loss Allocation Sequence")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Loss Allocation Sequence"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingRight: "2rem" }}>{value}</div>,
      },
      {
        title: "Interest Accrual Start Date Logic",
        dataIndex: "Interest Accrual Start Date Logic",
        key: "Interest Accrual Start Date Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Interest Accrual Start Date Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Interest Accrual Start Date Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Interest Accrual Start Date Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Interest Accrual End Date Logic",
        dataIndex: "Interest Accrual End Date Logic",
        key: "Interest Accrual End Date Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Interest Accrual End Date Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Interest Accrual End Date Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Interest Accrual End Date Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Facility Type",
        dataIndex: "Facility Type",
        key: "Facility Type",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Facility Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Facility Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Facility Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Original Commitment Balance",
        dataIndex: "Original Commitment Balance",
        key: "Original Commitment Balance",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Original Commitment Balance"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Original Commitment Balance")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Original Commitment Balance"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Commitment Fee Rate",
        dataIndex: "Commitment Fee Rate",
        key: "Commitment Fee Rate",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Commitment Fee Rate"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Commitment Fee Rate")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Commitment Fee Rate"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "2rem" }}>{value}</div>,
      },
      {
        title: "Commitment Fee Basis",
        dataIndex: "Commitment Fee Basis",
        key: "Commitment Fee Basis",
        hidden:this.state.isSecuritisation !== "Securitisation" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Commitment Fee Basis"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Commitment Fee Basis")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Commitment Fee Basis"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "2rem" }}>{value}</div>,
      },
      {
        title: "Notional Flag",
        dataIndex: "Notional Flag",
        key: "Notional Flag",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Notional Flag"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Notional Flag")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Notional Flag"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Original Notional Balance",
        dataIndex: "Original Notional Balance",
        key: "Original Notional Balance",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Original Notional Balance"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Original Notional Balance")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Original Notional Balance"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Beginning Notional Balance Logic",
        dataIndex: "Beginning Notional Balance Logic",
        key: "Beginning Notional Balance Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Beginning Notional Balance Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Beginning Notional Balance Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Beginning Notional Balance Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Ending Notional Balance Logic",
        dataIndex: "Ending Notional Balance Logic",
        key: "Ending Notional Balance Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Ending Notional Balance Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Ending Notional Balance Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Ending Notional Balance Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "International Securities Identification Number",
        dataIndex: "International Securities Identification Number",
        key: "International Securities Identification Number",
        hidden: this.state.isESMA !== "Yes" ? true : false,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
           
                <CustomFilterComponent
                    columnKey="International Securities Identification Number"
                    onSort={this.handleSort} // Replace with your sorting logic
                    onFilter={this.handleFilter} // Replace with your filtering logic
                    columnValues={getUniqueValues(this.state.tableData, "International Securities Identification Number")}
                    closeDropdown={close}
                />
        ),
        filterIcon: () => (
           
                <img
                    src={fIlter}
                    alt="Custom Filter Icon"
                    style={{
                        width: "15px", // Adjust size as needed
                        height: "10px",
                        color: this.state.filteredClrChangeColumns["International Securities Identification Number"]
                            ? "#018e82"
                            : undefined, // Optional: Apply conditional styling
                    }}
                />
          
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
    },
      {
        title: "Tranche/Bond Type",
        dataIndex: "Tranche/Bond Type",
        key: "Tranche/Bond Type",
        hidden: this.state.isESMA !== "Yes" ? true : false,
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Tranche/Bond Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Tranche/Bond Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Tranche/Bond Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Currency",
        dataIndex: "Currency",
        key: "Currency",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Currency"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Currency")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Currency"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Coupon Floor",
        dataIndex: "Coupon Floor",
        key: "Coupon Floor",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Coupon Floor"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Coupon Floor")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Coupon Floor"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Coupon Cap",
        dataIndex: "Coupon Cap",
        key: "Coupon Cap",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Coupon Cap"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Coupon Cap")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Coupon Cap"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Business Day Convention",
        dataIndex: "Business Day Convention",
        key: "Business Day Convention",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Business Day Convention"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Business Day Convention")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Business Day Convention"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Current Interest Rate Index",
        dataIndex: "Current Interest Rate Index",
        key: "Current Interest Rate Index",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Current Interest Rate Index"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Current Interest Rate Index")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Current Interest Rate Index"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Current Interest Rate Index Tenor",
        dataIndex: "Current Interest Rate Index Tenor",
        key: "Current Interest Rate Index Tenor",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Current Interest Rate Index Tenor"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Current Interest Rate Index Tenor")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Current Interest Rate Index Tenor"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Extension Clause",
        dataIndex: "Extension Clause",
        key: "Extension Clause",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Extension Clause"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Extension Clause")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Extension Clause"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Next Call Date",
        dataIndex: "Next Call Date",
        key: "Next Call Date",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Next Call Date"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Next Call Date")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Next Call Date"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          value !== "Invalid date" ? (
            <div style={{ paddingRight: "2rem" }}>{value}</div>
          ) : (
            ""
          ),
      },
      {
        title: "Clean-Up Call Threshold",
        dataIndex: "Clean-Up Call Threshold",
        key: "Clean-Up Call Threshold",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Clean-Up Call Threshold"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Clean-Up Call Threshold")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Clean-Up Call Threshold"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Next Put Date",
        dataIndex: "Next Put Date",
        key: "Next Put Date",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Next Put Date"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Next Put Date")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Next Put Date"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) =>
          value !== "Invalid date" ? (
            <div style={{ paddingRight: "2rem" }}>{value}</div>
          ) : (
            ""
          ),
      },
      {
        title: "Settlement Convention",
        dataIndex: "Settlement Convention",
        key: "Settlement Convention",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Settlement Convention"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Settlement Convention")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Settlement Convention"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Current Attachment Point",
        dataIndex: "Current Attachment Point",
        key: "Current Attachment Point",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Current Attachment Point"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Current Attachment Point")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Current Attachment Point"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Original Attachment Point",
        dataIndex: "Original Attachment Point",
        key: "Original Attachment Point",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Original Attachment Point"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Original Attachment Point")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Original Attachment Point"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Credit Enhancement Formula",
        dataIndex: "Credit Enhancement Formula",
        key: "Credit Enhancement Formula",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Credit Enhancement Formula"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Credit Enhancement Formula")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Credit Enhancement Formula"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Pari-Passu Tranches",
        dataIndex: "Pari-Passu Tranches",
        key: "Pari-Passu Tranches",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Pari-Passu Tranches"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Pari-Passu Tranches")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Pari-Passu Tranches"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Senior Tranches",
        dataIndex: "Senior Tranches",
        key: "Senior Tranches",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Senior Tranches"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Senior Tranches")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Senior Tranches"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Outstanding Principal Deficiency Ledger Balance",
        dataIndex: "Outstanding Principal Deficiency Ledger Balance",
        key: "Outstanding Principal Deficiency Ledger Balance",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Outstanding Principal Deficiency Ledger Balance"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Outstanding Principal Deficiency Ledger Balance")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Outstanding Principal Deficiency Ledger Balance"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Guarantor Legal Entity Identifier",
        dataIndex: "Guarantor Legal Entity Identifier",
        key: "Guarantor Legal Entity Identifier",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Guarantor Legal Entity Identifier"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Guarantor Legal Entity Identifier")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Guarantor Legal Entity Identifier"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Guarantor Name",
        dataIndex: "Guarantor Name",
        key: "Guarantor Name",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Guarantor Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Guarantor Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Guarantor Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Guarantor ESA Subsector",
        dataIndex: "Guarantor ESA Subsector",
        key: "Guarantor ESA Subsector",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Guarantor ESA Subsector"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Guarantor ESA Subsector")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Guarantor ESA Subsector"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Protection Type",
        dataIndex: "Protection Type",
        key: "Protection Type",
        hidden: this.state.isESMA !== "Yes" ? true : false,

        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Protection Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Protection Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Protection Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "0.5rem" }}>{value}</div>,
      },
      {
        title: "Record Date Logic",
        dataIndex: "Record Date Logic",
        key: "Record Date Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Record Date Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Record Date Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Record Date Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Beginning Cumulative Loss",
        dataIndex: "Beginning Cumulative Loss",
        key: "Beginning Cumulative Loss",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Beginning Cumulative Loss"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Beginning Cumulative Loss")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Beginning Cumulative Loss"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Beginning Shortfall",
        dataIndex: "Beginning Shortfall",
        key: "Beginning Shortfall",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Beginning Shortfall"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Beginning Shortfall")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Beginning Shortfall"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Beginning WAC Shortfall",
        dataIndex: "Beginning WAC Shortfall",
        key: "Beginning WAC Shortfall",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Beginning WAC Shortfall"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Beginning WAC Shortfall")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Beginning WAC Shortfall"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Beginning Principal Balance",
        dataIndex: "Beginning Principal Balance",
        key: "Beginning Principal Balance",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Beginning Principal Balance"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Beginning Principal Balance")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Beginning Principal Balance"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <NumberComp4 value={value} />,
      },
      {
        title: "Ending Shortfall Logic",
        dataIndex: "Ending Shortfall Logic",
        key: "Ending Shortfall Logic",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Ending Shortfall Logic"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Ending Shortfall Logic")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Ending Shortfall Logic"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div style={{ paddingLeft: "1rem" }}>{value}</div>,
      },
      {
        title: "Actions",
        dataIndex: "Actions",
        key: "actions",
        render: (_, record) => (
          <div style={{ textAlign: "center" }}>
            <button className="popupbutton1" onClick={() => this.onOpenModal2(record.Name, record, this.state.tableData)}>
              Edit
            </button>
            <button className="popupbuttons1" onClick={() => this.onOpenModal3(record)}>
              Delete
            </button>
          </div>
        ),
      },
      {
        title: "Priority",
        key: "priority",
        render: (_, record, index) => (
          <div className="login-sign_up-links">
            {index === 0 ? (
              <ArrowUpwardIcon style={{ cursor: "pointer", color: "grey" }} />
            ) : (
              <ArrowUpwardIcon
                style={{ cursor: "pointer" }}
                onClick={() => this.shiftUp(record, index)}
              />
            )}
            {index === this.state.tableData.length - 1 ? (
              <ArrowDownwardIcon style={{ cursor: "pointer", color: "grey" }} />
            ) : (
              <ArrowDownwardIcon
                style={{ cursor: "pointer" }}
                onClick={() => this.shiftDown(record, index)}
              />
            )}
          </div>
        ),
      },
      
      
      
      
    ];
    
    

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"Deal Creation"} />
          {this.state.screenloader === true ? (
            <LinearLoader msg={""} />
          ) : (
            <div className="content">
              

              <div className="page-contentofpool1">
                <div className="row1 arrow-dealcontent">
                  <div className="col-12 col-sm-6 col-md-8 d-flex justigy-content-center align-center hellocard">
                    <KeyboardBackspaceIcon
                      onClick={() => this.goBackToDashBoard()}
                      className="left-arrow-muis1 left-arrow-servicer"
                    ></KeyboardBackspaceIcon>
                    <h3 className="headerdashboard">DEAL CREATION</h3>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 hellocard">
                    <div className="buttonsverified uw-deal-details-button-container">
                      {this.state.createStatus === "Update" ? null : (
                        <Button
                          variant="outlined"
                          color="black"
                          onClick={this.dealCreationSaveForNow}
                        >
                          Save for Now
                          {this.state.formLoader1 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                      {this.state.createStatus === "Update" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.dealCreationCreateUpdate}
                          style={{
                            color: "#000", // Change this to your desired text color
                          }}
                        >
                          Update
                          {this.state.formLoader2 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.dealCreationCreateUpdate}
                          style={{
                            color: "#000", // Change this to your desired text color
                          }}
                        >
                          Create
                          {this.state.formLoader2 === true ? (
                            <CircularProgress size="22px" color="primary" />
                          ) : (
                            ""
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row row1 movement-tabs-creation">
                  <div>
                    {/* <div className="tablechangebutton"> */}
                    <div className="tablechangebuttonloans">
                      <button
                        type="button"
                        onClick={() => this.handleClickGeneral()}
                        className={
                          this.state.activeInsights1 == true
                            ? "issuerDashboard-table-top-button-insights-active"
                            : "issuerDashboard-table-top-button-insights"
                        }
                      >
                        General
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTranches()}
                        className={
                          this.state.activeInsights2 == true
                            ? "issuerDashboard-table-top-button-workbench-active"
                            : "issuerDashboard-table-top-button-workbench"
                        }
                      >
                        Tranches
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickFees()}
                        className={
                          this.state.activeInsights3 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Fees
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickExpenses()}
                        className={
                          this.state.activeInsights4 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Expenses
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickManualInputs()}
                        className={
                          this.state.activeInsightsMI == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Manual Inputs
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickAccounts()}
                        className={
                          this.state.activeInsights5 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Accounts
                      </button>

                      <button
                        type="button"
                        onClick={() => this.handleClickTests()}
                        className={
                          this.state.activeInsights6 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Tests
                      </button>
                      {this.state.isSecuritisation !== "Securitisation" ? (
                        <button
                          type="button"
                          onClick={() => this.handleClickBorrowingBase()}
                          className={
                            this.state.activeInsightsBB == true
                              ? "issuerDashboard-table-top-button-rejected-active"
                              : "issuerDashboard-table-top-button-rejected"
                          }
                        >
                          Borrowing Base
                        </button>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => this.handleClickVariables()}
                        className={
                          this.state.activeInsights7 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Variables
                      </button>
                      <button
                        type="button"
                        onClick={() => this.handleClickPaymentRules()}
                        className={
                          this.state.activeInsights8 == true
                            ? "issuerDashboard-table-top-button-rejected-active"
                            : "issuerDashboard-table-top-button-rejected"
                        }
                      >
                        Payment Rules
                      </button>
                    </div>
                  </div>
                </div>

                <div className="TrancheHeadBtns movement-header-creation">
                  <div className="investor-heading-container-tranches">
                    <h1 className="headerdashboard1">List of Tranches</h1>
                  </div>
                  <div className="Tranches_right_btns">
                    <div className="">
                      <div className="TranBtns">
                        {
                          <Button
                            variant="outlined"
                            // color="primary"
                            type="button"
                            onClick={() => this.handleDownloadClick(columns)}
                            style={{color:"var(--tableText)"}}
                          >
                            <img src={DownloadLogo} />
                            Template
                          </Button>
                        }
                      </div>
                    </div>
                    <div className="">
                      <div className="TranBtns">
                        <label htmlFor="icon-button-file-id21">
                          <Button
                            variant="contained"
                            color="primary"
                            type="button"
                          >
                            <img src={Upload_Logo} />
                            Upload
                            {this.state.fileUploaded && (
                              <span className="UploadedFileTranche">
                                <img
                                  src={checkedImg}
                                  alt="upload_Icon"
                                  className="UploadedFileImgTranche"
                                />
                              </span>
                            )}
                          </Button>
                        </label>
                        <input
                          id="icon-button-file-id21"
                          type="file"
                          accept=".csv,.xlsx,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          style={{
                            position: "relative",
                            width: "90%",
                            height: "2.5rem",
                            cursor: "pointer",
                            left: "7px",
                            top: "-47px",
                            right: "0px",
                            opacity: "0",
                            border: "1.2px solid #212121",
                          }}
                          onChange={(e) => this.handleFileUpload(e)}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        {
                          <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={() => this.onOpenModal()}
                          >
                            Add Tranche
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <React.Fragment>
                  <div>
                  <Table
                    className="custom-antds-table"
                    dataSource={displayData.map((item, index) => ({
                      ...item,
                      key: index, // Add a unique key for each row
                    }))}
                    columns={columns} // Use the refactored Ant Design-compatible columns
                    pagination={false}
                    scroll={{ x: "max-content", y: 600 }} // Enable horizontal and vertical scroll

                    rowKey={(record) => record.key} // Use unique key for rows
                  />
                   {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                overflow: "hidden",
                position : "relative",
                bottom:"3rem"
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={displayData.length}
                onChange={this.handlePaginationChange}
              />
            </div> */}
                  </div>
                </React.Fragment>
                <>
                  <div className="btn_move">
                    <div className="btn_prev" onClick={(e) => this.showPrev(e)}>
                      Previous
                    </div>
                    <button
                      className="btn_next"
                      onClick={(e) => this.showNext(e)}
                    >
                      Next
                    </button>
                  </div>
                </>
              </div>

              <>
                <ReactModal
                  isOpen={this.state.open1}
                  onRequestClose={this.onCloseModal}
                  contentLabel="Minimal Modal Example"
                  style={customStylesautoTranches}
                  appElement={document.getElementById("root")}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>Add Tranche</h2>
                      <button
                        type="button"
                        className="closePopup"
                        style={{ minWidth: "30px" }}
                        onClick={this.onCloseModal}
                      >
                        <CloseIcon></CloseIcon>{" "}
                      </button>

                      {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                      <div className="modalshiftcontent">
                        <form
                          className="form-container-tranches"
                          onSubmit={this.AddSubmit}
                        >
                          <div className="input-container">
                            <label className="label"> Name</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    Name: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData.Name}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">CUSIP</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    Cusip: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData.Cusip}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Original Principal Balance
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Original Principal Balance":
                                      formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Original Principal Balance"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Class Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Class Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Class Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Senior">Senior</option>
                              <option value="Mezzanine">Mezzanine</option>
                              <option value="Subordinate">Subordinate</option>
                              <option value="PO">PO</option>
                              <option value="IO">IO</option>
                              <option value="Excess Interest">
                                Excess Interest
                              </option>
                              <option value="Residual">Residual</option>
                              <option value="Combination Notes">
                                Combination Notes
                              </option>
                            </select>
                          </div>

                          <div className="input-container">
                            <label className="label">Interest Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Interest Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Interest Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Fixed">Fixed</option>
                              <option value="Floating">Floating</option>
                              <option value="Excess Interest">
                                Excess Interest
                              </option>
                            </select>
                          </div>
                          {/* <div className="input-container">
                          <div className="radio-input-container">
                            <div className="radio1-container">
                              <p>Alternate Interest Basis</p>
                              <Switch
                                className="addswitch"
                                checked={this.state.addswitchtoggle}
                                onChange={this.handleChange1}
                                name="togglevat"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </div>
                          </div>

                          <input
                            
                            placeholder="Type Expression here"
                            className="input"
                            type="text"
                            disabled={
                              this.state.addswitchtoggle === false
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                formData: {
                                  ...this.state.formData,
                                  AlternateInterestBasis: e.target.value,
                                },
                              });
                            }}
                            value={this.state.formData.AlternateInterestBasis}
                            // 
                          />
                        </div> */}

                          <div className="input-container">
                            <label className="label">Interest Rate</label>
                            <input
                              placeholder="Type Rate or Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Interest Rate": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Interest Rate"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Index Code</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Index Code": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Index Code"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Margin</label>
                            <div className="flex input">
                              <input
                                placeholder="Type here"
                                className="input-none"
                                type="number"
                                onKeyDown={this.blockInvalidChar}
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      Margin: e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData.Margin}
                              />
                              <p>%</p>
                            </div>
                          </div>

                          <div className="input-container">
                            <label className="label">Day Count Method</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Day Count Method": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Day Count Method"]}
                            >
                              <option value="">Select any one</option>
                              <option value="30/360">30/360</option>
                              <option value="30E/360">30E/360</option>
                              <option value="Act/360">Act/360</option>
                              <option value="Act/365">Act/365</option>
                              <option value="Act/365L">Act/365L</option>
                            </select>
                          </div>
                          <div
                            className="input-generalContainer-Date"
                            style={{
                              left: "0.1rem",
                              bottom: "0.2rem",
                              position: "relative",
                            }}
                          >
                            <label
                              className="label"
                              style={{ left: "-0.2rem", position: "relative" }}
                            >
                              Maturity Date
                            </label>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                className="input-Datepickercss-tranches"
                                disableToolbar
                                margin="normal"
                                id="date1"
                                value={
                                  this.state.formData["Maturity Date"] ?? ""
                                }
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? moment(date, "MM/DD/YYYY", true).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "";
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Maturity Date": formattedDate,
                                    },
                                  });
                                }}
                                keyboard
                                placeholder="MM/DD/YYYY"
                                format={"MM/DD/YYYY"}
                                disableopenonenter
                                animateYearScrolling={false}
                                autoOk={true}
                                clearable
                                variant="filled"
                                helperText={""}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">
                                Loss Allocation Method
                              </label>
                              <input
                                placeholder="Type Expression"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Loss Allocation Method": e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData["Loss Allocation Method"]
                                }
                              />
                            </div>
                          )}

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">Coupon Cap1</label>
                              <input
                                placeholder="Type Expression"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Coupon Cap1": e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData["Coupon Cap1"]}
                              />
                            </div>
                          )}

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">Coupon Cap2</label>
                              <input
                                placeholder="Type Expression"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Coupon Cap2": e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData["Coupon Cap2"]}
                              />
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Interest on Shortfall Flag
                            </label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Interest On Shortfall Flag":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Interest On Shortfall Flag"
                                ]
                              }
                            >
                              <option value="">Select any one</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">
                                Interest on WAC Shortfall Flag
                              </label>
                              <select
                                className="input-select"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Interest On WAC Shortfall Flag":
                                        e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData[
                                    "Interest On WAC Shortfall Flag"
                                  ]
                                }
                              >
                                <option value="">Select any one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Deferral Allocation Method
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Deferral Allocation Method":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Deferral Allocation Method"
                                ]
                              }
                            />
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">Stepup Margin</label>
                              <div className="flex input">
                                <input
                                  placeholder="Type here"
                                  className="input-none"
                                  type="number"
                                  onKeyDown={this.blockInvalidChar}
                                  onChange={(e) => {
                                    this.setState({
                                      formData: {
                                        ...this.state.formData,
                                        "Stepup Margin": e.target.value,
                                      },
                                    });
                                  }}
                                  value={this.state.formData["Stepup Margin"]}
                                />
                                <p>%</p>
                              </div>
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Index Determination Date Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Index Determination Date Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Index Determination Date Logic"
                                ]
                              }
                            />
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div
                              className="input-generalContainer-Date"
                              style={{
                                left: "0.1rem",
                                bottom: "0.2rem",
                                position: "relative",
                              }}
                            >
                              <label
                                className="label"
                                style={{
                                  left: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Stepup Date
                              </label>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  className="input-Datepickercss-tranches"
                                  disableToolbar
                                  margin="normal"
                                  id="date1"
                                  value={
                                    this.state.formData["Stepup Date"] ?? ""
                                  }
                                  onChange={(date) => {
                                    const formattedDate = date
                                      ? moment(date, "MM/DD/YYYY", true).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "";
                                    this.setState((prevState) => ({
                                      formData: {
                                        ...prevState.formData,
                                        ["Stepup Date"]: formattedDate,
                                      },
                                    }));
                                  }}
                                  keyboard
                                  placeholder="MM/DD/YYYY"
                                  format={"MM/DD/YYYY"}
                                  disableopenonenter
                                  animateYearScrolling={false}
                                  autoOk={true}
                                  clearable
                                  variant="filled"
                                  helperText={""}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          )}

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">
                                Loss Allocation Sequence
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Loss Allocation Sequence":
                                        e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData[
                                    "Loss Allocation Sequence"
                                  ]
                                }
                              />
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Interest Accrual Start Date Logic
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Interest Accrual Start Date Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Interest Accrual Start Date Logic"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Interest Accrual End Date Logic
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Interest Accrual End Date Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Interest Accrual End Date Logic"
                                ]
                              }
                            />
                          </div>

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">Facility Type</label>
                              <select
                                className="input-select"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Facility Type": e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData["Facility Type"]}
                              >
                                <option value="">Select any one</option>
                                <option value="Term">Term</option>
                                <option value="Revolving">Revolving</option>
                              </select>
                            </div>
                          ) : null}

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">
                                Original Commitment Balance
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onKeyDown={this.blockInvalidChar}
                                onChange={(e) => {
                                  let removeCharC = e.target.value.replace(
                                    /[^0-9\.]/g,
                                    ""
                                  );
                                  let formattedValue = removeCharC.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  );
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Original Commitment Balance":
                                        formattedValue,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData[
                                    "Original Commitment Balance"
                                  ]
                                }
                              />
                            </div>
                          ) : null}

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">
                                Commitment Fee Rate
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Commitment Fee Rate": e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData["Commitment Fee Rate"]
                                }
                              />
                            </div>
                          ) : null}

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">
                                Commitment Fee Basis
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData: {
                                      ...this.state.formData,
                                      "Commitment Fee Basis": e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData["Commitment Fee Basis"]
                                }
                              />
                            </div>
                          ) : null}

                          <div className="input-container">
                            <label className="label">Notional Flag</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Notional Flag": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Notional Flag"]}
                            >
                              <option value="NA">Select any one</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Original Notional Balance
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Original Notional Balance": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData["Original Notional Balance"]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning Notional Balance Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Beginning Notional Balance Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Beginning Notional Balance Logic"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Ending Notional Balance Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Ending Notional Balance Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Ending Notional Balance Logic"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Record Date Logic</label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Record Date Logic": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData["Record Date Logic"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning Cumulative Loss
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Beginning Cumulative Loss": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData["Beginning Cumulative Loss"]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Beginning Shortfall</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Beginning Shortfall": formattedValue,
                                  },
                                });
                              }}
                              value={this.state.formData["Beginning Shortfall"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning WAC Shortfall
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Beginning WAC Shortfall": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData["Beginning WAC Shortfall"]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning Principal Balance
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Beginning Principal Balance":
                                      formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData[
                                  "Beginning Principal Balance"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Ending Shortfall Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData: {
                                    ...this.state.formData,
                                    "Ending Shortfall Logic": e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData["Ending Shortfall Logic"]
                              }
                            />
                          </div>

                          {this.state.isESMA === "Yes" ? (
                            <>
                              <div className="Accordian">
                                <img
                                  src={AccordIcon}
                                  alt="Accordian"
                                  className={
                                    this.state.isAccordian
                                      ? "AccordImg"
                                      : "AccordImgReverse"
                                  }
                                  onClick={this.handleAccordian}
                                />
                              </div>

                              {!this.state.isAccordian ? (
                                <>
                                  <div className="input-container">
                                    <label className="label">
                                      International Securities Identification
                                      Number
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "International Securities Identification Number":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "International Securities Identification Number"
                                        ]
                                      }
                                    />
                                  </div>
                                  <div className="input-container">
                                    <label className="label">
                                      Tranche/Bond Type
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Tranche/Bond Type": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData["Tranche/Bond Type"]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="HBUL">HBUL</option>
                                      <option value="SBUL">SBUL</option>
                                      <option value="SAMO">SAMO</option>
                                      <option value="CAMM">CAMM</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">Currency</label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            Currency: e.target.value,
                                          },
                                        });
                                      }}
                                      value={this.state.formData["Currency"]}
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Coupon Floor
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Coupon Floor": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData["Coupon Floor"]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">Coupon Cap</label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Coupon Cap": e.target.value,
                                          },
                                        });
                                      }}
                                      value={this.state.formData["Coupon Cap"]}
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Business Day Convention
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Business Day Convention":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Business Day Convention"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="FWNG">FWNG</option>
                                      <option value="MODF">MODF</option>
                                      <option value="NEAR">NEAR</option>
                                      <option value="PREC">PREC</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Current Interest Rate Index
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Current Interest Rate Index":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Current Interest Rate Index"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="MAAA">MAAA</option>
                                      <option value="FUSW">FUSW</option>
                                      <option value="LIBI">LIBI</option>
                                      <option value="LIBO">LIBO</option>
                                      <option value="SWAP">SWAP</option>
                                      <option value="TREA">TREA</option>
                                      <option value="EURI">EURI</option>
                                      <option value="PFAN">PFAN</option>
                                      <option value="EONA">EONA</option>
                                      <option value="EONS">EONS</option>
                                      <option value="EUUS">EUUS</option>
                                      <option value="EUCH">EUCH</option>
                                      <option value="TIBO">TIBO</option>
                                      <option value="ISDA">ISDA</option>
                                      <option value="GCFR">GCFR</option>
                                      <option value="STBO">STBO</option>
                                      <option value="BBSW">BBSW</option>
                                      <option value="JIBA">JIBA</option>
                                      <option value="BUBO">BUBO</option>
                                      <option value="CDOR">CDOR</option>
                                      <option value="CIBO">CIBO</option>
                                      <option value="MOSP">MOSP</option>
                                      <option value="NIBO">NIBO</option>
                                      <option value="PRBO">PRBO</option>
                                      <option value="TLBO">TLBO</option>
                                      <option value="WIBO">WIBO</option>
                                      <option value="BOER">BOER</option>
                                      <option value="ECBR">ECBR</option>
                                      <option value="LDOR">LDOR</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Current Interest Rate Index Tenor
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Current Interest Rate Index Tenor":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Current Interest Rate Index Tenor"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="MNTH">MNTH</option>
                                      <option value="TOMN">TOMN</option>
                                      <option value="QUTR">QUTR</option>
                                      <option value="FOMN">FOMN</option>
                                      <option value="SEMI">SEMI</option>
                                      <option value="YEAR">YEAR</option>
                                      <option value="ONDE">ONDE</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Extension Clause
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Extension Clause": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData["Extension Clause"]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="ISUR">ISUR</option>
                                      <option value="NHLD">NHLD</option>
                                      <option value="ISNH">ISNH</option>
                                      <option value="NOPT">NOPT</option>
                                    </select>
                                  </div>

                                  <div
                                    className="input-generalContainer-Date"
                                    style={{
                                      left: "0.1rem",
                                      bottom: "0.2rem",
                                      position: "relative",
                                    }}
                                  >
                                    <label
                                      className="label"
                                      style={{
                                        left: "-0.2rem",
                                        position: "relative",
                                      }}
                                    >
                                      Next Call Date
                                    </label>
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        className="input-Datepickercss-tranches"
                                        disableToolbar
                                        margin="normal"
                                        id="date1"
                                        value={
                                          this.state.formData[
                                            "Next Call Date"
                                          ] ?? ""
                                        }
                                        onChange={(date) => {
                                          const formattedDate = date
                                            ? moment(
                                                date,
                                                "MM/DD/YYYY",
                                                true
                                              ).format("MM/DD/YYYY")
                                            : "";
                                          this.setState((prevState) => ({
                                            formData: {
                                              ...prevState.formData,
                                              ["Next Call Date"]: formattedDate,
                                            },
                                          }));
                                        }}
                                        keyboard
                                        placeholder="MM/DD/YYYY"
                                        format={"MM/DD/YYYY"}
                                        disableopenonenter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                        clearable
                                        variant="filled"
                                        helperText={""}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Clean-Up Call Threshold
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Clean-Up Call Threshold":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Clean-Up Call Threshold"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>

                                  <div
                                    className="input-generalContainer-Date"
                                    style={{
                                      left: "0.1rem",
                                      bottom: "0.2rem",
                                      position: "relative",
                                    }}
                                  >
                                    <label
                                      className="label"
                                      style={{
                                        left: "-0.2rem",
                                        position: "relative",
                                      }}
                                    >
                                      Next Put date
                                    </label>
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        className="input-Datepickercss-tranches"
                                        disableToolbar
                                        margin="normal"
                                        id="date1"
                                        value={
                                          this.state.formData[
                                            "Next Put date"
                                          ] ?? ""
                                        }
                                        onChange={(date) => {
                                          const formattedDate = date
                                            ? moment(date)
                                                .format("MM/DD/YYYY")
                                                .toString()
                                            : "";
                                          this.setState((prevState) => ({
                                            formData: {
                                              ...prevState.formData,
                                              ["Next Put date"]: formattedDate,
                                            },
                                          }));
                                        }}
                                        keyboard
                                        placeholder="MM/DD/YYYY"
                                        format={"MM/DD/YYYY"}
                                        disableopenonenter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                        clearable
                                        variant="filled"
                                        helperText={""}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Settlement Convention
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Settlement Convention":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Settlement Convention"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="TONE">TONE</option>
                                      <option value="TTWO">TTWO</option>
                                      <option value="TTRE">TTRE</option>
                                      <option value="ASAP">ASAP</option>
                                      <option value="ENDC">ENDC</option>
                                      <option value="MONT">MONT</option>
                                      <option value="FUTU">FUTU</option>
                                      <option value="NXTD">NXTD</option>
                                      <option value="REGU">REGU</option>
                                      <option value="TFIV">TFIV</option>
                                      <option value="TFOR">TFOR</option>
                                      <option value="WHIF">WHIF</option>
                                      <option value="WDIS">WDIS</option>
                                      <option value="WISS">WISS</option>
                                      <option value="WHID">WHID</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Current Attachment Point
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Current Attachment Point":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Current Attachment Point"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Original Attachment Point
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Original Attachment Point":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Original Attachment Point"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Credit Enhancement Formula
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Credit Enhancement Formula":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Credit Enhancement Formula"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Pari-Passu Tranches
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Pari-Passu Tranches":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Pari-Passu Tranches"
                                        ]
                                      }
                                    />
                                  </div>
                                  <div className="input-container">
                                    <label className="label">
                                      Senior Tranches
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Senior Tranches": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData["Senior Tranches"]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Outstanding Principal Deficiency Ledger
                                      Balance
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Outstanding Principal Deficiency Ledger Balance":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Outstanding Principal Deficiency Ledger Balance"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Guarantor Legal Entity Identifier
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Guarantor Legal Entity Identifier":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Guarantor Legal Entity Identifier"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Guarantor Name
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Guarantor Name": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData["Guarantor Name"]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Guarantor ESA Subsector
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Guarantor ESA Subsector":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData[
                                          "Guarantor ESA Subsector"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Protection Type
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData: {
                                            ...this.state.formData,
                                            "Protection Type": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData["Protection Type"]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="CDSX">CDSX</option>
                                      <option value="CLKN">CLKN</option>
                                      <option value="FGUA">FGUA</option>
                                      <option value="CINS">CINS</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end1">
                                  <button
                                    type="button"
                                    className="popupbutton22"
                                    onClick={this.onCloseModal}
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    Add
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="22px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                </ReactModal>
              </>

              <>
                <ReactModal
                  isOpen={this.state.open3}
                  onRequestClose={this.onCloseModal2}
                  contentLabel="Minimal Modal Example"
                  style={customStylesautoTranches}
                  appElement={document.getElementById("root")}
                >
                  <React.Fragment>
                    <div className="modalPopup">
                      <h2>Edit Tranche</h2>
                      <button
                        type="button"
                        className="closePopup"
                        style={{ minWidth: "30px" }}
                        onClick={this.onCloseModal2}
                      >
                        <CloseIcon></CloseIcon>{" "}
                      </button>

                      {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                      <div className="modalshiftcontent">
                        <form
                          className="form-container-tranches"
                          onSubmit={this.EditSubmit}
                        >
                          {/* <div className="input-container">
                          <label className="label">ID</label>
                          <input
                            disabled
                            placeholder="Type here"
                            className="input"
                            type="text"
                            onChange={(e) => {
                              this.setState({
                                formData1: {
                                  ...this.state.formData1,
                                  id: e.target.value,
                                },
                              });
                            }}
                            value={this.state.formData1.id}
                          />
                        </div> */}
                          <div className="input-container">
                            <label className="label"> Name</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    Name: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1.Name}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">CUSIP</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    Cusip: e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1.Cusip}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Original Principal Balance
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                // let remove = removeCharC.replace(/\./g, "");
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );

                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Original Principal Balance":
                                      formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Original Principal Balance"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Class Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Class Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Class Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Senior">Senior</option>
                              <option value="Mezzanine">Mezzanine</option>
                              <option value="Subordinate">Subordinate</option>
                              <option value="PO">PO</option>
                              <option value="IO">IO</option>
                              <option value="Excess Interest">
                                Excess Interest
                              </option>
                              <option value="Residual">Residual</option>
                              <option value="Combination Notes">
                                Combination Notes
                              </option>
                            </select>
                          </div>

                          <div className="input-container">
                            <label className="label">Interest Type</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Interest Type": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Interest Type"]}
                            >
                              <option value="">Select any one</option>
                              <option value="Fixed">Fixed</option>
                              <option value="Floating">Floating</option>
                              <option value="Excess Interest">
                                Excess Interest
                              </option>
                              <option value="WAC">WAC</option>
                              <option value="Residual">Residual</option>
                            </select>
                          </div>
                          {/* <div className="input-container">
                          <div className="radio-input-container">
                            <div className="radio1-container">
                              <p>Alternate Interest Basis</p>
                              <Switch
                                className="addswitch"
                                checked={this.state.addswitchtoggle}
                                onChange={this.handleChange1}
                                name="togglevat"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </div>
                          </div>

                          <input
                            
                            placeholder="Type Expression here"
                            className="input"
                            type="text"
                            disabled={
                              this.state.addswitchtoggle === false
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                formData1: {
                                  ...this.state.formData1,
                                  AlternateInterestBasis: e.target.value,
                                },
                              });
                            }}
                            value={this.state.formData1.AlternateInterestBasis}
                            // 
                          />
                        </div> */}

                          <div className="input-container">
                            <label className="label">Interest Rate</label>
                            <input
                              placeholder="Type Rate or Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Interest Rate": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Interest Rate"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Index Code</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Index Code": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Index Code"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Margin</label>
                            <div className="flex input">
                              <input
                                placeholder="Type here"
                                className="input-none"
                                type="number"
                                onKeyDown={this.blockInvalidChar}
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      Margin: e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData1.Margin}
                              />
                              <p>%</p>
                            </div>
                          </div>

                          <div className="input-container">
                            <label className="label">Day Count Method</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Day Count Method": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Day Count Method"]}
                            >
                              <option value="">Select any one</option>
                              <option value="30/360">30/360</option>
                              <option value="30E/360">30E/360</option>
                              <option value="Act/360">Act/360</option>
                              <option value="Act/365">Act/365</option>
                              <option value="Act/365L">Act/365L</option>
                            </select>
                          </div>

                          <div
                            className="input-generalContainer-Date"
                            style={{
                              left: "0.1rem",
                              bottom: "0.2rem",
                              position: "relative",
                            }}
                          >
                            <label
                              className="label"
                              style={{ left: "-0.2rem", position: "relative" }}
                            >
                              Maturity Date
                            </label>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                className="input-Datepickercss-tranches"
                                disableToolbar
                                margin="normal"
                                id="date1"
                                value={
                                  this.state.formData1["Maturity Date"] ?? ""
                                }
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? moment(date).format("MM/DD/YYYY")
                                    : "";
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Maturity Date": formattedDate,
                                    },
                                  });
                                }}
                                keyboard
                                placeholder="MM/DD/YYYY"
                                format={"MM/DD/YYYY"}
                                disableopenonenter
                                animateYearScrolling={false}
                                autoOk={true}
                                clearable
                                variant="filled"
                                helperText={""}
                              />
                            </MuiPickersUtilsProvider>
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">
                                Loss Allocation Method
                              </label>
                              <input
                                placeholder="Type Expression"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Loss Allocation Method": e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData1["Loss Allocation Method"]
                                }
                              />
                            </div>
                          )}

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">Coupon Cap1</label>
                              <input
                                placeholder="Type Expression"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Coupon Cap1": e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData1["Coupon Cap1"]}
                              />
                            </div>
                          )}

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">Coupon Cap2</label>
                              <input
                                placeholder="Type Expression"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Coupon Cap2": e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData1["Coupon Cap2"]}
                              />
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Interest on Shortfall Flag
                            </label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Interest On Shortfall Flag":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Interest On Shortfall Flag"
                                ]
                              }
                            >
                              <option value="">Select any one</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">
                                Interest on WAC Shortfall Flag
                              </label>
                              <select
                                className="input-select"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Interest On WAC Shortfall Flag":
                                        e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData1[
                                    "Interest On WAC Shortfall Flag"
                                  ]
                                }
                              >
                                <option value="">Select any one</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Deferral Allocation Method
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Deferral Allocation Method":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Deferral Allocation Method"
                                ]
                              }
                            />
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">Stepup Margin</label>
                              <div className="flex input">
                                <input
                                  placeholder="Type here"
                                  className="input-none"
                                  type="number"
                                  onKeyDown={this.blockInvalidChar}
                                  onChange={(e) => {
                                    this.setState({
                                      formData1: {
                                        ...this.state.formData1,
                                        "Stepup Margin": e.target.value,
                                      },
                                    });
                                  }}
                                  value={this.state.formData1["Stepup Margin"]}
                                />
                                <p>%</p>
                              </div>
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Index Determination Date Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Index Determination Date Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Index Determination Date Logic"
                                ]
                              }
                            />
                          </div>

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div
                              className="input-generalContainer-Date"
                              style={{
                                left: "0.1rem",
                                bottom: "0.2rem",
                                position: "relative",
                              }}
                            >
                              <label
                                className="label"
                                style={{
                                  left: "-0.2rem",
                                  position: "relative",
                                }}
                              >
                                Stepup Date
                              </label>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                  className="input-Datepickercss-tranches"
                                  disableToolbar
                                  margin="normal"
                                  id="date1"
                                  value={
                                    this.state.formData1["Stepup Date"] ?? ""
                                  }
                                  onChange={(date) => {
                                    const formattedDate = date
                                      ? moment(date, "MM/DD/YYYY", true).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "";
                                    this.setState((prevState) => ({
                                      formData1: {
                                        ...prevState.formData1,
                                        ["Stepup Date"]: formattedDate,
                                      },
                                    }));
                                  }}
                                  keyboard
                                  placeholder="MM/DD/YYYY"
                                  format={"MM/DD/YYYY"}
                                  disableopenonenter
                                  animateYearScrolling={false}
                                  autoOk={true}
                                  clearable
                                  variant="filled"
                                  helperText={""}
                                />
                              </MuiPickersUtilsProvider>
                            </div>
                          )}

                          {this.state.isSecuritisation !==
                          "Securitisation" ? null : (
                            <div className="input-container">
                              <label className="label">
                                Loss Allocation Sequence
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Loss Allocation Sequence":
                                        e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData1[
                                    "Loss Allocation Sequence"
                                  ]
                                }
                              />
                            </div>
                          )}

                          <div className="input-container">
                            <label className="label">
                              Interest Accrual Start Date Logic
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Interest Accrual Start Date Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Interest Accrual Start Date Logic"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Interest Accrual End Date Logic
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Interest Accrual End Date Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Interest Accrual End Date Logic"
                                ]
                              }
                            />
                          </div>

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">Facility Type</label>
                              <select
                                className="input-select"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Facility Type": e.target.value,
                                    },
                                  });
                                }}
                                value={this.state.formData1["Facility Type"]}
                              >
                                <option value="">Select any one</option>
                                <option value="Term">Term</option>
                                <option value="Revolving">Revolving</option>
                              </select>
                            </div>
                          ) : null}

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">
                                Original Commitment Balance
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onKeyDown={this.blockInvalidChar}
                                onChange={(e) => {
                                  let removeCharC = e.target.value.replace(
                                    /[^0-9\.]/g,
                                    ""
                                  );
                                  let formattedValue = removeCharC.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  );
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Original Commitment Balance":
                                        formattedValue,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData1[
                                    "Original Commitment Balance"
                                  ]
                                }
                              />
                            </div>
                          ) : null}

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">
                                Commitment Fee Rate
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Commitment Fee Rate": e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData1["Commitment Fee Rate"]
                                }
                              />
                            </div>
                          ) : null}

                          {this.state.isSecuritisation !== "Securitisation" ? (
                            <div className="input-container">
                              <label className="label">
                                Commitment Fee Basis
                              </label>
                              <input
                                placeholder="Type here"
                                className="input"
                                type="text"
                                onChange={(e) => {
                                  this.setState({
                                    formData1: {
                                      ...this.state.formData1,
                                      "Commitment Fee Basis": e.target.value,
                                    },
                                  });
                                }}
                                value={
                                  this.state.formData1["Commitment Fee Basis"]
                                }
                              />
                            </div>
                          ) : null}

                          <div className="input-container">
                            <label className="label">Notional Flag</label>
                            <select
                              className="input-select"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Notional Flag": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Notional Flag"]}
                            >
                              <option value="NA">Select any one</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Original Notional Balance
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Original Notional Balance": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Original Notional Balance"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning Notional Balance Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Beginning Notional Balance Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Beginning Notional Balance Logic"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Ending Notional Balance Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Ending Notional Balance Logic":
                                      e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Ending Notional Balance Logic"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Record Date Logic</label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Record Date Logic": e.target.value,
                                  },
                                });
                              }}
                              value={this.state.formData1["Record Date Logic"]}
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning Cumulative Loss
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Beginning Cumulative Loss": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Beginning Cumulative Loss"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">Beginning Shortfall</label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Beginning Shortfall": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1["Beginning Shortfall"]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning WAC Shortfall
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Beginning WAC Shortfall": formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1["Beginning WAC Shortfall"]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Beginning Principal Balance
                            </label>
                            <input
                              placeholder="Type here"
                              className="input"
                              type="text"
                              onKeyDown={this.blockInvalidChar}
                              onChange={(e) => {
                                let removeCharC = e.target.value.replace(
                                  /[^0-9\.]/g,
                                  ""
                                );
                                let formattedValue = removeCharC.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Beginning Principal Balance":
                                      formattedValue,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1[
                                  "Beginning Principal Balance"
                                ]
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label className="label">
                              Ending Shortfall Logic
                            </label>
                            <input
                              placeholder="Type Expression"
                              className="input"
                              type="text"
                              onChange={(e) => {
                                this.setState({
                                  formData1: {
                                    ...this.state.formData1,
                                    "Ending Shortfall Logic": e.target.value,
                                  },
                                });
                              }}
                              value={
                                this.state.formData1["Ending Shortfall Logic"]
                              }
                            />
                          </div>

                          {this.state.isESMA === "Yes" ? (
                            <>
                              <div className="Accordian">
                                <img
                                  src={AccordIcon}
                                  alt="Accordian"
                                  className={
                                    this.state.isAccordian
                                      ? "AccordImg"
                                      : "AccordImgReverse"
                                  }
                                  onClick={this.handleAccordian}
                                />
                              </div>

                              {!this.state.isAccordian ? (
                                <>
                                  <div className="input-container">
                                    <label className="label">
                                      International Securities Identification
                                      Number
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "International Securities Identification Number":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "International Securities Identification Number"
                                        ]
                                      }
                                    />
                                  </div>
                                  <div className="input-container">
                                    <label className="label">
                                      Tranche/Bond Type
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Tranche/Bond Type": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Tranche/Bond Type"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="HBUL">HBUL</option>
                                      <option value="SBUL">SBUL</option>
                                      <option value="SAMO">SAMO</option>
                                      <option value="CAMM">CAMM</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">Currency</label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            Currency: e.target.value,
                                          },
                                        });
                                      }}
                                      value={this.state.formData1["Currency"]}
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Coupon Floor
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Coupon Floor": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1["Coupon Floor"]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">Coupon Cap</label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Coupon Cap": e.target.value,
                                          },
                                        });
                                      }}
                                      value={this.state.formData1["Coupon Cap"]}
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Business Day Convention
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Business Day Convention":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Business Day Convention"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="FWNG">FWNG</option>
                                      <option value="MODF">MODF</option>
                                      <option value="NEAR">NEAR</option>
                                      <option value="PREC">PREC</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Current Interest Rate Index
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Current Interest Rate Index":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Current Interest Rate Index"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="MAAA">MAAA</option>
                                      <option value="FUSW">FUSW</option>
                                      <option value="LIBI">LIBI</option>
                                      <option value="LIBO">LIBO</option>
                                      <option value="SWAP">SWAP</option>
                                      <option value="TREA">TREA</option>
                                      <option value="EURI">EURI</option>
                                      <option value="PFAN">PFAN</option>
                                      <option value="EONA">EONA</option>
                                      <option value="EONS">EONS</option>
                                      <option value="EUUS">EUUS</option>
                                      <option value="EUCH">EUCH</option>
                                      <option value="TIBO">TIBO</option>
                                      <option value="ISDA">ISDA</option>
                                      <option value="GCFR">GCFR</option>
                                      <option value="STBO">STBO</option>
                                      <option value="BBSW">BBSW</option>
                                      <option value="JIBA">JIBA</option>
                                      <option value="BUBO">BUBO</option>
                                      <option value="CDOR">CDOR</option>
                                      <option value="CIBO">CIBO</option>
                                      <option value="MOSP">MOSP</option>
                                      <option value="NIBO">NIBO</option>
                                      <option value="PRBO">PRBO</option>
                                      <option value="TLBO">TLBO</option>
                                      <option value="WIBO">WIBO</option>
                                      <option value="BOER">BOER</option>
                                      <option value="ECBR">ECBR</option>
                                      <option value="LDOR">LDOR</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Current Interest Rate Index Tenor
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Current Interest Rate Index Tenor":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Current Interest Rate Index Tenor"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="MNTH">MNTH</option>
                                      <option value="TOMN">TOMN</option>
                                      <option value="QUTR">QUTR</option>
                                      <option value="FOMN">FOMN</option>
                                      <option value="SEMI">SEMI</option>
                                      <option value="YEAR">YEAR</option>
                                      <option value="ONDE">ONDE</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Extension Clause
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Extension Clause": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1["Extension Clause"]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="ISUR">ISUR</option>
                                      <option value="NHLD">NHLD</option>
                                      <option value="ISNH">ISNH</option>
                                      <option value="NOPT">NOPT</option>
                                    </select>
                                  </div>

                                  <div
                                    className="input-generalContainer-Date"
                                    style={{
                                      left: "0.1rem",
                                      bottom: "0.2rem",
                                      position: "relative",
                                    }}
                                  >
                                    <label
                                      className="label"
                                      style={{
                                        left: "-0.2rem",
                                        position: "relative",
                                      }}
                                    >
                                      Next Call Date
                                    </label>
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        className="input-Datepickercss-tranches"
                                        disableToolbar
                                        margin="normal"
                                        id="date1"
                                        value={
                                          this.state.formData1[
                                            "Next Call Date"
                                          ] ?? ""
                                        }
                                        onChange={(date) => {
                                          const formattedDate = date
                                            ? moment(
                                                date,
                                                "MM/DD/YYYY",
                                                true
                                              ).format("MM/DD/YYYY")
                                            : "";
                                          this.setState((prevState) => ({
                                            formData1: {
                                              ...prevState.formData1,
                                              ["Next Call Date"]: formattedDate,
                                            },
                                          }));
                                        }}
                                        keyboard
                                        placeholder="MM/DD/YYYY"
                                        format={"MM/DD/YYYY"}
                                        disableopenonenter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                        clearable
                                        variant="filled"
                                        helperText={""}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Clean-Up Call Threshold
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Clean-Up Call Threshold":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Clean-Up Call Threshold"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </div>

                                  <div
                                    className="input-generalContainer-Date"
                                    style={{
                                      left: "0.1rem",
                                      bottom: "0.2rem",
                                      position: "relative",
                                    }}
                                  >
                                    <label
                                      className="label"
                                      style={{
                                        left: "-0.2rem",
                                        position: "relative",
                                      }}
                                    >
                                      Next Put date
                                    </label>
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDatePicker
                                        className="input-Datepickercss-tranches"
                                        disableToolbar
                                        margin="normal"
                                        id="date1"
                                        value={
                                          this.state.formData1[
                                            "Next Put date"
                                          ] ?? ""
                                        }
                                        onChange={(date) => {
                                          const formattedDate = date
                                            ? moment(
                                                date,
                                                "MM/DD/YYYY",
                                                true
                                              ).format("MM/DD/YYYY")
                                            : "";
                                          this.setState((prevState) => ({
                                            formData1: {
                                              ...prevState.formData1,
                                              ["Next Put date"]: formattedDate,
                                            },
                                          }));
                                        }}
                                        keyboard
                                        placeholder="MM/DD/YYYY"
                                        format={"MM/DD/YYYY"}
                                        disableopenonenter
                                        animateYearScrolling={false}
                                        autoOk={true}
                                        clearable
                                        variant="filled"
                                        helperText={""}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Settlement Convention
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Settlement Convention":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Settlement Convention"
                                        ]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="TONE">TONE</option>
                                      <option value="TTWO">TTWO</option>
                                      <option value="TTRE">TTRE</option>
                                      <option value="ASAP">ASAP</option>
                                      <option value="ENDC">ENDC</option>
                                      <option value="MONT">MONT</option>
                                      <option value="FUTU">FUTU</option>
                                      <option value="NXTD">NXTD</option>
                                      <option value="REGU">REGU</option>
                                      <option value="TFIV">TFIV</option>
                                      <option value="TFOR">TFOR</option>
                                      <option value="WHIF">WHIF</option>
                                      <option value="WDIS">WDIS</option>
                                      <option value="WISS">WISS</option>
                                      <option value="WHID">WHID</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Current Attachment Point
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Current Attachment Point":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Current Attachment Point"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Original Attachment Point
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Original Attachment Point":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Original Attachment Point"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Credit Enhancement Formula
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Credit Enhancement Formula":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Credit Enhancement Formula"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Pari-Passu Tranches
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Pari-Passu Tranches":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Pari-Passu Tranches"
                                        ]
                                      }
                                    />
                                  </div>
                                  <div className="input-container">
                                    <label className="label">
                                      Senior Tranches
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Senior Tranches": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1["Senior Tranches"]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Outstanding Principal Deficiency Ledger
                                      Balance
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Outstanding Principal Deficiency Ledger Balance":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Outstanding Principal Deficiency Ledger Balance"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Guarantor Legal Entity Identifier
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Guarantor Legal Entity Identifier":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Guarantor Legal Entity Identifier"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Guarantor Name
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Guarantor Name": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1["Guarantor Name"]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Guarantor ESA Subsector
                                    </label>
                                    <input
                                      placeholder="Type here"
                                      className="input"
                                      type="text"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Guarantor ESA Subsector":
                                              e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1[
                                          "Guarantor ESA Subsector"
                                        ]
                                      }
                                    />
                                  </div>

                                  <div className="input-container">
                                    <label className="label">
                                      Protection Type
                                    </label>
                                    <select
                                      className="input-select"
                                      onChange={(e) => {
                                        this.setState({
                                          formData1: {
                                            ...this.state.formData1,
                                            "Protection Type": e.target.value,
                                          },
                                        });
                                      }}
                                      value={
                                        this.state.formData1["Protection Type"]
                                      }
                                    >
                                      <option value="NA">Select any one</option>
                                      <option value="CDSX">CDSX</option>
                                      <option value="CLKN">CLKN</option>
                                      <option value="FGUA">FGUA</option>
                                      <option value="CINS">CINS</option>
                                      <option value="OTHR">OTHR</option>
                                    </select>
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end1">
                                  <button
                                    type="button"
                                    className="popupbutton22"
                                    onClick={this.onCloseModal2}
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                  >
                                    Save
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="22px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </React.Fragment>
                </ReactModal>

                <div id="modal1">
                  <ReactModal
                    isOpen={this.state.open4}
                    onRequestClose={this.onCloseModal3}
                    contentLabel="Minimal Modal Example"
                    style={customStylesautosmallmodal1}
                    appElement={document.getElementById("root")}
                  >
                    <React.Fragment>
                      <div className="modalPopup">
                        <h3 className="popupheading">
                          Are you sure, you want to Delete this Tranche "
                          {this.state.deleteRowVal.Name}"?
                        </h3>

                        {/* {this.state.popupLoader === true ? <CircularProgress size="30px" color="secondary" /> : ' '} */}

                        <div className="modalshiftcontent">
                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end11">
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.onCloseModal3}
                                  >
                                    No
                                  </button>

                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.dealCreationDelete}
                                  >
                                    Yes, Delete it
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="25px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.getLoansLoader === false ? (
                            ""
                          ) : (
                            <FormLoader></FormLoader>
                          )}
                          {/* {this.state.openPopup === true ? <React.Fragment><Snackbar msg={"Processor addded Successfully" || this.state.message} open="true" /> </React.Fragment> : ' '} */}
                        </div>
                      </div>
                    </React.Fragment>
                  </ReactModal>
                </div>
              </>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(Tranches_DealCreation);
