import React, { useState } from "react";
import { Table, Menu, Select, Radio, Button, Divider } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CaretDownFilled,
} from "@ant-design/icons";
import "./CustomFilterComponent.css";

const { Option } = Select;

const CustomFilterComponent = ({
  columnKey,
  onSort,
  onFilter,
  columnValues,
  closeDropdown,
  tableName,
  data,
}) => {
  const [filterCondition1, setFilterCondition1] = useState("equals");
  const [filterValue1, setFilterValue1] = useState(null);
  const [logicalOperator, setLogicalOperator] = useState("OR");
  const [filterCondition2, setFilterCondition2] = useState("equals");
  const [filterValue2, setFilterValue2] = useState(null);

  const handleSort = (order) => {
    onSort(tableName, columnKey, order);
    closeDropdown();
  };

  const handleFilterSubmit = () => {
    onFilter(tableName, data, columnKey, {
      condition1: filterCondition1,
      value1: filterValue1,
      operator: logicalOperator,
      condition2: filterCondition2,
      value2: filterValue2,
    });
    closeDropdown();
  };

  const handleCancel = () => {
    setFilterCondition1("equals");
    setFilterValue1(null);
    setLogicalOperator("OR");
    setFilterCondition2("equals");
    setFilterValue2(null);

    // Clear any applied filters
    onFilter(tableName, data, columnKey, null);

    // Close the dropdown
    closeDropdown();
  };
  const suffixIcon = <CaretDownFilled style={{ color: "#4bafa6" }} />;

  return (
    <Menu className="custom-filter-menu">
      <div style={{ display: "flex", gap: 10, marginBottom: 8 }}>
        <Menu.Item
          onClick={() => handleSort("ascend")}
          style={{ color: "#4bafa6", fontWeight: "bold", background: "none" }}
        >
          <ArrowUpOutlined /> Sort A to Z
        </Menu.Item>
        <Divider
          type="vertical"
          style={{ margin: 0, height: "auto", borderColor: "#d9d9d9" }}
        />
        <Menu.Item
          onClick={() => handleSort("descend")}
          style={{ color: "#4bafa6", fontWeight: "bold", background: "none" }}
        >
          <ArrowDownOutlined /> Sort Z to A
        </Menu.Item>
      </div>
      <Divider style={{ margin: "-8px 0px 10px", borderColor: "#d9d9d9" }} />
      <div style={{ padding: "0px 16px 16px" }}>
        <div style={{ fontWeight: "bold", color: "var(--tableText)" }}>
          Custom Filter
        </div>

        <div style={{ display: "flex", gap: 10, marginBottom: 8 }}>
          <Select
            value={filterCondition1}
            onChange={setFilterCondition1}
            suffixIcon={suffixIcon}
            style={{ width: 112, background: "var(--white)" }}
          >
            <Option value="equals">Equals</Option>
            <Option value="less_than">Less than</Option>
            <Option value="greater_than">Greater than</Option>
            <Option value="not_equals">Not equals</Option>
          </Select>
          <Select
            showSearch
            value={filterValue1}
            onChange={setFilterValue1}
            style={{ width: 112 }}
            suffixIcon={suffixIcon}
            placeholder="Select"
            className="custom-select-placeholder"
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {columnValues.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </div>

        <Radio.Group
          onChange={(e) => setLogicalOperator(e.target.value)}
          value={logicalOperator}
          style={{ marginBottom: 8, color: "#ffc000" }}
        >
          <Radio value="AND" style={{ color: "var(--tableText)" }}>
            AND
          </Radio>
          <Radio value="OR" style={{ color: "var(--tableText)" }}>
            OR
          </Radio>
        </Radio.Group>

        <div style={{ display: "flex", gap: 8, marginBottom: 8 }}>
          <Select
            value={filterCondition2}
            onChange={setFilterCondition2}
            suffixIcon={suffixIcon}
            style={{ width: 112, background: "var(--white)" }}
          >
            <Option value="equals" style={{ color: "var(--tableText)" }}>
              Equals
            </Option>
            <Option value="less_than" style={{ color: "var(--tableText)" }}>
              Less than
            </Option>
            <Option value="greater_than" style={{ color: "var(--tableText)" }}>
              Greater than
            </Option>
            <Option value="not_equals" style={{ color: "var(--tableText)" }}>
              Not equals
            </Option>
          </Select>
          <Select
            showSearch
            value={filterValue2}
            onChange={setFilterValue2}
            suffixIcon={suffixIcon}
            style={{ width: 112, color: "var(--tableText)" }}
            placeholder="Select"
            className="custom-select-placeholder"
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
          >
            {columnValues.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="text"
            onClick={handleCancel}
            style={{ color: "var(--tableText)" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleFilterSubmit}
            style={{
              color: "#000",
              backgroundColor: "#ffc000",
              borderColor: "#000",
              borderRadius: "20px",
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </Menu>
  );
};

export default CustomFilterComponent;
