import React, { useEffect, useRef, useState } from "react";
import { Table, Typography, Button, Spin, Dropdown, Menu } from "antd";
import {
  CaretDownOutlined,
  FilterFilled,
  CaretUpOutlined,
} from "@ant-design/icons";
import { useSnackbar } from "notistack";
import { FilterDataOnFilteredResults } from "../../../../../servies/services";
import NumberComp2 from "../../../../../components/NumberComp2";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import * as XLSX from "xlsx";

const Performance = ({
  selectedPrePayments,
  selectedDefaults,
  selectedLosses,
  selectedCreditMetrics,
  selectedDelinquencies,
  selectedCashflows,
  selectedDeals,
  selectedDownloadPrePayments,
  selectedDownloadDefaults,
  selectedDownloadLosses,
  selectedDownloadCreditMetrics,
  selectedDownloadDelinquencies,
  selectedDownloadCashflows,
  startDate: propStartDate,
  endDate: propEndDate,
}) => {
  const [getLoansLoader, setGetLoansLoader] = useState(false);
  const [tableDataPrepayments, setTableDataPrepayments] = useState([]);
  const [tableDataPrepaymentsForExcel, setTableDataPrepaymentsForExcel] =
    useState([]);
  const [tableKeys, setTableKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [dates, setDates] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // New state to manage expanded rows
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [filteredClrChangeColumns, setFilteredClrChangeColumns] = useState({});
  const [currentBatch, setCurrentBatch] = useState(1);
  const [sortOrder, setSortOrder] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const itemsPerBatch = 45;
  const tableRef = useRef(null);

  const handleExcelDownload = async () => {
    try {
      // Call the API to fetch and filter data
      setLoadingExcel(true);
      // console.log({ selectedDownloadLosses });

      // console.log("LoadingExcel set to true", loadingExcel);
      // Pass a flag to indicate this is for Excel download
      await filterDataOnFilteredResults(true);
      // Once the API call is successful, proceed to download the Excel
      await downloadExcel();
    } catch (error) {
      console.error("Error while downloading Excel:", error);
      enqueueSnackbar("Failed to download Excel. Please try again.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Generate Excel file
  const downloadExcel = async () => {
    // Extract table data for export
    const exportData = [];

    // Step 1: Include the main table data
    const mainData = [];
    mainData.forEach((row) => {
      const rowData = { Metric: row.metric };
      dates.forEach((date) => {
        rowData[date] = row[date] || "";
      });
      exportData.push(rowData);
    });

    // Step 2: Include the expandable data grouped by category
    const expandableCategories = [
      "PrePayments",
      "Default",
      "Losses",
      "Credit Metrics",
      "Delinquencies",
      "Cash Flows",
    ];

    expandableCategories.forEach((category) => {
      // Add a single row for the category
      const categoryRow = { Metric: category };
      exportData.push(categoryRow);

      // console.log({ category });
      // console.log(
      //   "Keys in tableDataPrepayments:",
      //   tableDataPrepayments,
      //   Object.keys(tableDataPrepayments)
      // );

      // Add the rows under the category
      const expandedData = createExpandableData(
        category,
        tableDataPrepaymentsForExcel
      );
      // console.log(`Data for ${category}:`, expandedData);
      expandedData.forEach((row) => {
        const rowData = { Metric: `   ${row.metric}` }; // Indent metrics under category
        dates.forEach((date) => {
          rowData[date] = row[date] || "";
        });
        exportData.push(rowData);
      });
    });

    // console.log({ expandableCategories, exportData });

    // Step 3: Create a worksheet and export
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Optional: Adjust the width of the columns for better readability
    const colWidths = [{ wch: 30 }, ...dates.map(() => ({ wch: 15 }))];
    worksheet["!cols"] = colWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Performance");

    // Step 4: Export the workbook
    XLSX.writeFile(workbook, "Performance.xlsx");
  };

  const handleCsvDownload = async () => {
    try {
      setLoadingCSV(true);
      // Call the API to fetch and filter data
      await filterDataOnFilteredResults(true);

      // Once the API call is successful, proceed to download the CSV
      downloadCsv();
    } catch (error) {
      console.error("Error while downloading CSV:", error);
      enqueueSnackbar("Failed to download CSV. Please try again.", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  // Generate CSV file
  const downloadCsv = () => {
    // Extract table data for export
    const exportData = [];

    // Step 1: Include the main table data
    const mainData = [];
    mainData.forEach((row) => {
      const rowData = { Metric: row.metric };
      dates.forEach((date) => {
        rowData[date] = row[date] || "";
      });
      exportData.push(rowData);
    });

    // Step 2: Include the expandable data grouped by category
    const expandableCategories = [
      "PrePayments",
      "Default",
      "Losses",
      "Credit Metrics",
      "Delinquencies",
      "Cash Flows",
    ];

    expandableCategories.forEach((category) => {
      // Add a single row for the category
      const categoryRow = { Metric: category };
      exportData.push(categoryRow);

      // Add the rows under the category
      const expandedData = createExpandableData(
        category,
        tableDataPrepaymentsForExcel
      );
      expandedData.forEach((row) => {
        const rowData = { Metric: `   ${row.metric}` }; // Indent metrics under category
        dates.forEach((date) => {
          rowData[date] = row[date] || "";
        });
        exportData.push(rowData);
      });
    });

    // Step 3: Create a CSV string
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

    // Step 4: Trigger file download
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "Performance.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getUniqueValues = (dataSource, columnKey) => {
    const uniqueValues = new Set();

    Object.values(dataSource).forEach((categoryData) => {
      categoryData.forEach((row) => {
        if (row[columnKey] !== null && row[columnKey] !== undefined) {
          uniqueValues.add(row[columnKey]);
        }
      });
    });

    return Array.from(uniqueValues).sort((a, b) => a - b); // Sorted values for convenience
  };

  const formatDate = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const startDate = formatDate(propStartDate);
  const endDate = formatDate(propEndDate);

  const selectSpecificPrePayemnts = selectedPrePayments.map(
    (item) => item.label
  );
  const selectSpecificDefaults = selectedDefaults.map((item) => item.label);
  const selectSpecificLosses = selectedLosses.map((item) => item.label);
  const selectSpecificCreditMetrics = selectedCreditMetrics.map(
    (item) => item.label
  );
  const selectSpecificDelinquencies = selectedDelinquencies.map(
    (item) => item.label
  );
  const selectSpecificCashflows = selectedCashflows.map((item) => item.label);

  // Download for specific Excel

  const selectSpecificDownloadPrePayemnts = selectedDownloadPrePayments.map(
    (item) => item.label
  );
  const selectSpecificDownloadDefaults = selectedDownloadDefaults.map(
    (item) => item.label
  );
  const selectSpecificDownloadLosses = selectedDownloadLosses.map(
    (item) => item.label
  );
  const selectSpecificDownloadCreditMetrics = selectedDownloadCreditMetrics.map(
    (item) => item.label
  );
  const selectSpecificDownloadDelinquencies = selectedDownloadDelinquencies.map(
    (item) => item.label
  );
  const selectSpecificDownloadCashflows = selectedDownloadCashflows.map(
    (item) => item.label
  );

  const selectDeals =
    selectedDeals && selectedDeals.length > 0 ? selectedDeals[0].value : "";

  // Manage loading states based on loadingExcel
  useEffect(() => {
    if (loadingExcel || loadingCSV) {
      // If Excel download is in progress, disable general loaders
      setGetLoansLoader(false);
      setLoading(false);
    }
  }, [loadingExcel, loadingCSV]);

  const filterDataOnFilteredResults = async (isExcelDownload = false) => {
    const PrePaymentsVar = isExcelDownload
      ? selectSpecificDownloadPrePayemnts
      : selectSpecificPrePayemnts;
    const DefaultsVar = isExcelDownload
      ? selectSpecificDownloadDefaults
      : selectSpecificDefaults;
    const LossesVar = isExcelDownload
      ? selectSpecificDownloadLosses
      : selectSpecificLosses;
    const CreditMetricsVar = isExcelDownload
      ? selectSpecificDownloadCreditMetrics
      : selectSpecificCreditMetrics;
    const DeliquenciesVar = isExcelDownload
      ? selectSpecificDownloadDelinquencies
      : selectSpecificDelinquencies;
    const CashflowsVar = isExcelDownload
      ? selectSpecificDownloadCashflows
      : selectSpecificCashflows;

    const data = {
      userName: sessionStorage.getItem("user_name"),
      dealId: selectDeals,
      startDate: startDate,
      endDate: endDate,
      cprVar: PrePaymentsVar,
      cdrVar: DefaultsVar,
      lossesVar: LossesVar, // Use the appropriate losses variable
      creditVar: CreditMetricsVar,
      accVar: DeliquenciesVar,
      cashflowVar: CashflowsVar,
    };

    console.log({ data });

    // Only set general loading states if Excel or CSV download is not in progress
    if (!loadingExcel || !loadingCSV) {
      setGetLoansLoader(true);
      setLoading(true);
    }

    try {
      const APIResponse = await FilterDataOnFilteredResults(data);
      if (APIResponse.status === 200) {
        const parsedResult = APIResponse.data.result.map((item) => ({
          date: item.id,
          type: item.colname,
          value: item.colvalue,
          category: item.par,
        }));

        const groupedData = parsedResult.reduce((acc, item) => {
          if (!acc[item.category]) acc[item.category] = {};
          if (!acc[item.category][item.type])
            acc[item.category][item.type] = {};
          acc[item.category][item.type][item.date] = item.value;
          return acc;
        }, {});

        // console.log({ groupedData });

        const tableKeys = Object.keys(groupedData);
        const uniqueDates = Array.from(
          new Set(parsedResult.map((item) => item.date))
        ).sort((a, b) => new Date("1-" + a) - new Date("1-" + b));

        setDates(uniqueDates);
        if (isExcelDownload) {
          setTableDataPrepaymentsForExcel(groupedData);
        } else {
          setTableDataPrepayments(groupedData);
        }
        setTableKeys(tableKeys);

        setDisplayData(parsedResult);

        // Set all rows to be expanded by default
        const expandedKeys = constructMainTableData().map((item) => item.key);
        setExpandedRowKeys(expandedKeys);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      // Reset loading states
      if (!loadingExcel || !loadingCSV) {
        setGetLoansLoader(false);
        setLoading(false);
      }
      setLoadingExcel(false);
      setLoadingCSV(false);
    }
  };

  useEffect(() => {
    if (
      selectedPrePayments.length > 0 ||
      selectedDefaults.length > 0 ||
      selectedLosses.length > 0 ||
      selectedCreditMetrics.length > 0 ||
      selectedDelinquencies.length > 0 ||
      selectedCashflows.length > 0
    ) {
      const timeoutId = setTimeout(() => {
        filterDataOnFilteredResults();
      }, 0); // Small delay to ensure multiple changes are captured simultaneously

      return () => clearTimeout(timeoutId); // Clean up the timeout if the component unmounts
    }
  }, [
    selectedPrePayments,
    selectedDefaults,
    selectedLosses,
    selectedCreditMetrics,
    selectedDelinquencies,
    selectedCashflows,
    startDate,
    endDate,
  ]);

  // useEffect(() => {
  //   if (
  //     selectedDownloadPrePayments.length > 0 ||
  //     selectedDownloadDefaults.length > 0 ||
  //     selectedDownloadLosses.length > 0 ||
  //     selectedDownloadCreditMetrics.length > 0 ||
  //     selectedDownloadDelinquencies.length > 0 ||
  //     selectedDownloadCashflows.length > 0
  //   ) {
  //     const timeoutId = setTimeout(() => {
  //       filterDataOnFilteredResults(true);
  //     }, 0); // Small delay to ensure multiple changes are captured simultaneously

  //     return () => clearTimeout(timeoutId); // Clean up the timeout if the component unmounts
  //   }
  // }, [
  //   selectedDownloadPrePayments,
  //   selectedDownloadDefaults,
  //   selectedDownloadLosses,
  //   selectedDownloadCreditMetrics,
  //   selectedDownloadDelinquencies,
  //   selectedDownloadCashflows,
  //   startDate,
  //   endDate,
  // ]);

  const handleFilter = (tableName, data, columnKey, criteria) => {
    const updatedCriteria = { ...filterCriteria };

    if (criteria) {
      // Add or update filter criteria
      updatedCriteria[columnKey] = { value1: criteria };
      setFilteredClrChangeColumns((prev) => ({ ...prev, [columnKey]: true }));
    } else {
      // Remove filter criteria if no criteria provided
      delete updatedCriteria[columnKey];
      setFilteredClrChangeColumns((prev) => ({ ...prev, [columnKey]: false }));
    }

    setFilterCriteria(updatedCriteria);
    // console.log({ tableDataPrepayments });

    // Apply all active filters on tableDataPrepayments
    const filteredData = Object.entries(tableDataPrepayments).map(
      ([category, categoryData]) => {
        const filteredCategoryData = Object.entries(categoryData).reduce(
          (acc, [key, value]) => {
            // Check if the columnKey is in the nested category
            if (columnKey.includes(key)) {
              // Apply the filter based on criteria (for nested values)
              if (value === criteria) {
                acc[key] = value; // Add this value if it matches the criteria
              }
            } else {
              acc[key] = value; // Retain values that don't need filtering
            }
            return acc;
          },
          {}
        );

        return {
          [category]: filteredCategoryData, // Return the filtered data for this category
        };
      }
    );

    console.log({ filteredData });

    // Sort data after filtering (if needed)
    let finalData = filteredData;
    if (sortOrder.columnKey) {
      finalData = [...filteredData].sort((a, b) => {
        const vA = a[sortOrder.columnKey];
        const vB = b[sortOrder.columnKey];

        if (!isNaN(vA) && !isNaN(vB))
          return sortOrder.order === "ascend" ? vA - vB : vB - vA;
        if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB)))
          return sortOrder.order === "ascend"
            ? new Date(vA) - new Date(vB)
            : new Date(vB) - new Date(vA);

        return vA?.localeCompare(vB) || 0;
      });
    }

    // Update table data with filtered and sorted results
    setDisplayData(finalData.slice(0, currentBatch * itemsPerBatch));
  };

  const handleSort = (tableName, columnKey, order) => {
    const sortedData = [...tableData].sort((a, b) => {
      const vA = a[columnKey];
      const vB = b[columnKey];

      if (!isNaN(vA) && !isNaN(vB))
        return order === "ascend" ? vA - vB : vB - vA;
      if (!isNaN(Date.parse(vA)) && !isNaN(Date.parse(vB)))
        return order === "ascend"
          ? new Date(vA) - new Date(vB)
          : new Date(vB) - new Date(vA);

      return vA?.localeCompare(vB) || 0;
    });

    // setTableData(sortedData);
    setTableData(sortedData.slice(0, itemsPerBatch));
  };

  const columns = [
    {
      title: "",
      dataIndex: "metric",
      key: "metric",
      width: 150,
      fixed: "left",
      render: (text) => <span>{text}</span>,
    },
    ...dates.map((date) => ({
      title: date,
      dataIndex: date,
      key: date,
      width: 150,
      onHeaderCell: (column) => ({
        style: {
          textAlign: "center", // Adjust as needed
        },
      }),
      render: (text) => {
        const isTextUndefined =
          text === undefined || text === null || text === "";
        const isWholeNumber = /^[+-]?[1-9]\d*$/.test(text);
        const isDecimal =
          /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text) ||
          /^[+-]?\d+\.\d+$/.test(text);

        const alignmentStyle = {
          textAlign:
            isTextUndefined || isWholeNumber || isDecimal
              ? "right"
              : text
              ? "center"
              : "left",
        };

        return (
          <div style={alignmentStyle}>
            <NumberComp2 value={text} />
          </div>
        );
      },
      // filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => {
      //   const dataMapping = {
      //     Prepayments: createExpandableData("PrePayments"),
      //     Default: createExpandableData("Default"),
      //     Losses: createExpandableData("Losses"),
      //     "Credit Metrics": createExpandableData("Credit Metrics"),
      //     Delinquencies: createExpandableData("Delinquencies"),
      //     "Cash Flows": createExpandableData("Cash Flows"),
      //   };

      //   const dataSource = dataMapping;
      //   return (
      //     <CustomFilterComponent
      //       columnKey={date}
      //       onSort={handleSort}
      //       onFilter={handleFilter}
      //       columnValues={getUniqueValues(dataSource, date)}
      //       closeDropdown={close}
      //     />
      //   );
      // },
      // filterIcon: () => (
      //   <FilterFilled
      //     style={{
      //       color: filteredClrChangeColumns[date] ? "green" : undefined,
      //     }}
      //   />
      // ),
    })),
  ];

  const constructMainTableData = () => {
    const tableData = [
      {
        key: "1",
        metric: "Prepayments",
        isExpandable: selectedPrePayments.length > 0,
      },
      {
        key: "2",
        metric: "Default",
        isExpandable: selectedDefaults.length > 0,
      },
      { key: "3", metric: "Losses", isExpandable: selectedLosses.length > 0 },
      {
        key: "4",
        metric: "Credit Metrics",
        isExpandable: selectedCreditMetrics.length > 0,
      },
      {
        key: "5",
        metric: "Delinquencies",
        isExpandable: selectedDelinquencies.length > 0,
      },
      {
        key: "6",
        metric: "Cash Flows",
        isExpandable: selectedCashflows.length > 0,
      },
    ];

    return tableData.filter((item) => item.isExpandable);
  };

  const mainTableData = constructMainTableData();

  const createExpandableData = (category, isExcelData = false) => {
    const categoryData = isExcelData
      ? tableDataPrepaymentsForExcel[category]
      : tableDataPrepayments[category];

    if (!categoryData) return [];

    return Object.keys(categoryData).map((metric, index) => {
      const dateValues = dates.reduce((acc, date) => {
        acc[date] = categoryData[metric][date] || null;
        return acc;
      }, {});

      return {
        key: `${category}-${metric}-${index + 1}`,
        metric,
        ...dateValues,
      };
    });
  };

  const expandableRowRender = (record) => {
    const dataMapping = {
      Prepayments: createExpandableData("PrePayments"),
      Default: createExpandableData("Default"),
      Losses: createExpandableData("Losses"),
      "Credit Metrics": createExpandableData("Credit Metrics"),
      Delinquencies: createExpandableData("Delinquencies"),
      "Cash Flows": createExpandableData("Cash Flows"),
    };

    const dataSource = dataMapping[record.metric];

    return dataSource && dataSource.length > 0 ? (
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        showHeader={false}
        // className="checkPerformance"
        scroll={handleHorizontalScroll} // Set scroll for inner table
        rowKey="key"
        onRow={(record) => ({
          onClick: () => {
            console.log(record); // Handle row click
          },
        })}
      />
    ) : null;
  };

  const handleHorizontalScroll = (e) => {
    const headerTable = tableRef.current.querySelector(".ant-table-header");
    if (headerTable) {
      headerTable.scrollLeft = e.target.scrollLeft;
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={handleExcelDownload}>
        Excel {loadingExcel && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
      <hr className="custom-inv-ddHr" />
      <Menu.Item key="2" onClick={handleCsvDownload}>
        CSV {loadingCSV && <Spin size="small" style={{ marginLeft: 2 }} />}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {/* <Button
        type="primary"
        onClick={handleExcelDownload}
        style={{
          backgroundColor: "#194d40",
          width: 150,
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      >
        Download Excel
        {loadingExcel && (
          <Spin
            size="small"
            // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
          />
        )}
      </Button>
      <Button
        type="primary"
        onClick={handleCsvDownload}
        style={{
          backgroundColor: "#194d40",
          width: 150,
          marginLeft: "10px",
        }}
      >
        Download CSV
        {loadingCSV && (
          <Spin
            size="small"
            // style={{ marginLeft: "8px" }} // Ensure space between text and spinner
          />
        )}
      </Button> */}

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          // marginBottom: 2,
          position: "relative",
          right: "0.1rem",
          bottom: "1rem",
        }}
      >
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          visible={dropdownVisible}
          onVisibleChange={(visible) => setDropdownVisible(visible)}
          overlayClassName="custom-inv-ddDownload"
        >
          <Button
            type="primary"
            style={{
              backgroundColor: "#ffc000",
              width: 120,
              color: "#000",
            }}
          >
            <span>Download</span>
            {dropdownVisible ? (
              <CaretUpOutlined
                style={{
                  color: "#000",
                }}
              />
            ) : (
              <CaretDownOutlined
                style={{
                  color: "#000",
                }}
              />
            )}
          </Button>
        </Dropdown>
      </div>

      <div ref={tableRef}>
        <Table
          className="performance-table"
          columns={columns}
          dataSource={mainTableData}
          loading={loading}
          expandable={{
            expandedRowRender: expandableRowRender,
            expandedRowKeys: expandedRowKeys, // Set expanded row keys
            onExpand: (expanded, record) => {
              const keys = expanded
                ? [...expandedRowKeys, record.key]
                : expandedRowKeys.filter((key) => key !== record.key);
              setExpandedRowKeys(keys);
            },
          }}
          onScroll={handleHorizontalScroll} // Attach scroll handler
          scroll={{ y: "calc(100vh - 400px)" }} // Set scroll for outer table
          pagination={false}
        />
      </div>
    </>
  );
};

export default Performance;
