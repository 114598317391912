import MUIDataTable from "mui-datatables";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Header from "../../../../../components/header/header";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  investorGetalldeals,
  getDealDetailsByInvestorPaymentDate,
} from "../../../../../servies/services";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../../components/loader";
import NumberComp2 from "../../../../../components/NumberComp2";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as moment from "moment";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ReorderIcon from "@material-ui/icons/Reorder";
import BarChartIcon from "@material-ui/icons/BarChart";
import { data } from "jquery";
import { Servicer } from "../../../../../components/sidebar/menu";
import Iframe from "react-iframe";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ReactModal from "react-modal";
import BeanEater from "../../../../../images/BeanEater.gif";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import { DownOutlined, FilterFilled } from "@ant-design/icons";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import { Pagination } from "antd"; // Import AntD Pagination
import fIlter from "../../../../../images/FilterMappedTape.svg";
import { Table, Select, Input, Spin } from "antd";

// Function to get unique values for a column
const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};

class InvestorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      InvID: sessionStorage.getItem("user_id"),
      paymentDates: [],
      versionSelected: "",
      versions: [],
      tableData: [],
      IssuerArrays: [],
      TrusteeArrays: [],
      AssetArrays: [],
      Issuer: null, // Initially, no value is selected
      Trustee: null,
      "Asset Class": null,
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      loading: false,
      getLoansLoader: false,
      PaymentDate: "",
      screenloader: false,
      LastPaymentDate: "",
      anchorEl: null,
      servicerValues: [],
      isDateSelected: null,
      reorderchart: true,
      searchText: "",
      barchart: false,
      bdbUrl: "",
      showSearchBox: false,
      searchText: "",
      Version: [],
      getlastPaymentDates: [],
      getDashboardVersions: [],
      openModalPayment: false,
      getPaymentDateIndex: null,
      singleRowVersion: {},
      singleRowPaymentDate: {},
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {},
    };
  }

  handleFilter = (tableName, data, columnKey, criteria) => {
    const { filterCriteria, tableData, currentBatch, itemsPerBatch } =
      this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    if (isCriteriaEmpty) {
      // Reset to the first batch of the full dataset if no criteria are selected
      this.setState({
        displayData: tableData.slice(0, currentBatch * itemsPerBatch),
        filteredClrChangeColumns: {
          ...this.state.filteredClrChangeColumns,
          [columnKey]: false,
        },
      });
      return;
    }

    // Apply filters
    const filtered = tableData.filter((item) => {
      return Object.keys(newCriteria).every((key) => {
        const criteria = newCriteria[key];
        if (!criteria) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return false;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });

    console.log({ filtered });

    // Apply sorting after filtering
    const { sortOrder } = this.state;
    const sortedFiltered = [...filtered].sort((a, b) => {
      const valueA = a[sortOrder.columnKey];
      const valueB = b[sortOrder.columnKey];

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return sortOrder.order === "ascend"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder.order === "ascend"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    });

    // Update display data to only show the current batch
    this.setState({
      displayData: sortedFiltered.slice(0, currentBatch * itemsPerBatch),
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handleSort = (tableName, columnKey, order) => {
    const { tableData, itemsPerBatch } = this.state;

    // Determine the chunk size based on the total dataset size
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        if (!isNaN(valueA) && !isNaN(valueB)) {
          return order === "ascend" ? valueA - valueB : valueB - valueA;
        }

        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        return 0;
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        this.setState({
          tableData: sortedData,
          displayData: sortedData.slice(0, itemsPerBatch),
        });
      }
    };

    sortChunk(0);
  };

  handlePaginationChange = (page) => {
    this.setState({ currentPage: page });
  };

  investorGetalldeals = async () => {
    var data = {};
    data.InvID = this.state.InvID;
    data.peer = this.state.peer;

    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await investorGetalldeals(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      this.setState(
        {
          getLoansLoader: false,
          tableData: APIResponse.data,
          displayData: APIResponse.data,
          manipulatedTableData: APIResponse.data,
          IssuerArrays: [
            ...new Set(APIResponse.data.map((item) => item["Issuer"])),
          ],
          TrusteeArrays: [
            ...new Set(APIResponse.data.map((item) => item["Trustee"])),
          ],
          AssetArrays: [
            ...new Set(APIResponse.data.map((item) => item["Asset Class"])),
          ],
          loading: false,
        },
        () => {
          const dealData = APIResponse.data;
          // Check if all deals have "Esma Flag" as "No"
          const allEsmaNo = dealData.every(
            (item) => item["Esma Flag"] === "No"
          );
          const isEsmaFlag = allEsmaNo ? "No" : "Yes";
          sessionStorage.setItem("isESMA_Flag", isEsmaFlag);

          // Filter deals with "Esma Flag" as "Yes"
          const filteredDeals = dealData.filter(
            (item) => item["Esma Flag"] === "Yes"
          );
          const getDealNames = filteredDeals
            .filter((item) => item["Esma Flag"] === "Yes")
            .map((item) => item["Deal Name"]);
          const getPaymentDates = filteredDeals.map(
            (item) => item["Payment Date"]
          )[0];
          // const getServicers = filteredDeals.map((item) => item["Servicer"])[0];
          const getVersions = filteredDeals.map((item) => item["Version"])[0];

          // Check if getDealNames[0] and getPaymentDates[0] are present
          if (getDealNames && getPaymentDates) {
            sessionStorage.setItem("dealname", getDealNames[0]);
            sessionStorage.setItem("isLoansProcessed", "Yes");
            console.log(">>>>>>>>>>>>>>>>>>", getPaymentDates[0]);
            console.log("check", getDealNames[0]);
            sessionStorage.setItem(
              "nextpaymentdate",
              getPaymentDates[getPaymentDates.length - 1]
            );
            sessionStorage.setItem("lastpaymentdate", "");
            sessionStorage.setItem("version", getVersions[0]);
            sessionStorage.setItem(
              "getDashboardDeals",
              JSON.stringify(getDealNames)
            );
            sessionStorage.setItem(
              "getDashboardDates",
              JSON.stringify(getPaymentDates)
            );
            sessionStorage.setItem(
              "getDashboardVersions",
              JSON.stringify(getVersions)
            );
            sessionStorage.setItem("dashChanges", true);

            // sessionStorage.setItem("isdisable", true);
            // let index = this.state.getlastPaymentDates.indexOf(
            //   this.state.PaymentDate
            // );
            // sessionStorage.setItem(
            //   "version",
            //   this.state.versionSelected === ""
            //     ? tableMeta.rowData[2][0]
            //     : this.state.versionSelected
            // );
          } else {
            console.log("Deal Name or Payment Date is missing.");
          }
        }
      );

      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      // variant: "info",
      // autoHideDuration: 3000,
      // });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Check if the PaymentDate state has changed
    if (
      prevState.PaymentDate !== this.state.PaymentDate ||
      prevState.getPaymentDateIndex !== this.state.getPaymentDateIndex
    ) {
      //|| prevState.singleRowPaymentDate !== this.state.singleRowPaymentDate
      // Perform the API call or other logic here
      const { PaymentDate, singleRowPaymentDate } = this.state;
      console.log("rowData", singleRowPaymentDate);

      this.updatePaymentDateValue(PaymentDate, singleRowPaymentDate);
    }
    // if (
    //   this.state.Issuer !== prevState.Issuer ||
    //   this.state.Trustee !== prevState.Trustee ||
    //   this.state.AssetClass !== prevState.AssetClass
    // ) {
    //   // Update the tableData based on the selected filters
    //   const filteredData = this.filterTableData();
    //   this.setState({ tableData: filteredData });
    // }
  }

  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;

    // Reset the page to 1 whenever search is changed
    this.setState({ searchText: searchText, currentPage: 1 });
  };

  searchBar = () => (
    <div className="search-bar-main-container" id="searchBox">
      <div className="tableSearch1">
        <TextField
          value={this.state.searchText}
          onChange={this.onchange}
          placeholder="Search"
          color="#212121"
          variant="standard"
          size="small"
          autoFocus
        />
      </div>
      <button
        type="button"
        onClick={this.onClickCloseSearchBar}
        className="close-mui-icon"
      >
        <CloseIcon />
      </button>
    </div>
  );

  onClickCloseSearchBar = () => {
    this.setState({ searchText: "" });
    this.setState({ showSearchBox: false });
  };
  onClickSearchButton = () => {
    this.setState({ showSearchBox: true });
  };

  async componentDidMount() {
    var component = window.location.pathname;
    // sessionStorage.setItem("component", component);
    // sessionStorage.removeItem('selectservicerdate');
    // sessionStorage.removeItem('servicerdealname');
    // sessionStorage.removeItem('servicerdashboardname');
    // sessionStorage.removeItem("dealname");
    this.investorGetalldeals();
  }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            // padding: "0px 20px !important",
            // width:"20rem",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
            zIndex: "1 !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });

  Recurringreport = (record, index) => {
    console.log("Row Data:", record);

    // Update keys to match dataIndex values in the columns
    const selectedPaymentDate =
      this.state.PaymentDate === ""
        ? record["Payment Date"][0]
        : this.state.PaymentDate;
    const selectedVersion =
      this.state.versionSelected === ""
        ? record["Version"][0]
        : this.state.versionSelected;

    // Set values in sessionStorage
    sessionStorage.setItem("dealname", record["Deal Name"]);
    sessionStorage.setItem("nextpaymentdate", selectedPaymentDate);
    sessionStorage.setItem("version", selectedVersion);
    sessionStorage.setItem(
      "getDashboardDeals",
      JSON.stringify(this.state.tableData.map((item) => item["Deal Name"]))
    );
    sessionStorage.setItem(
      "getDashboardDates",
      JSON.stringify(record["Payment Date"])
    );
    sessionStorage.setItem(
      "getDashboardVersions",
      JSON.stringify(record["Version"])
    );

    // Navigate to the report page
    this.props.history.push({
      pathname: "/investor/report_InvRecurring",
    });
  };

  handleClickReorder = () => {
    this.setState({
      reorderchart: true,
      barchart: false,
    });
  };

  handleClickbarchart = () => {
    this.setState({
      reorderchart: false,
      barchart: true,
    });
  };

  bdbapi = async () => {
    let x = moment(this.state.NextPaymentDate)
      .subtract(1, "months")
      .format("MM/DD/YYYY")
      .toString();
    let month = x.slice(0, 2);
    let year = x.slice(8, 10);
    function getMonthName(monthNumber) {
      const date = new Date();
      date.setMonth(monthNumber - 1);

      return date.toLocaleString("en-US", { month: "short" });
    }

    const getMonth = getMonthName(month); // Jan

    let poolidold = JSON.stringify({
      "Deal Name": {
        type: "in",
        value: [this.state.DealName],
      },
      MonthandYear: {
        type: "in",
        value: [`${getMonth}-${year}`],
      },
    });

    let mailid = this.state.Email;
    let password = this.state.Password;
    const res = await axios.get(
      process.env.react_app_base_url +
        "backendapi_wsfs_bdb/IA/link?" +
        "mailid=" +
        mailid +
        "&password=" +
        password
    );
    if (res.status == 204) {
      this.setState({ bdbUrl: "UrlBdbNew", bdb_loader: false });
    } else {
      let UrlBdbNew =
        "https://analytics.intainabs.com/home/#/opendocument?data=" + res.data;
      this.setState({ bdbUrl: UrlBdbNew, bdb_loader: false });
    }
  };

  handlePaymentDateChange = async (newValue, rowIndex, record) => {
    console.log("newValue", newValue);

    const updatedPaymentDates = [...this.state.paymentDates];
    updatedPaymentDates[rowIndex] = newValue;

    console.log("updatedPaymentDates", updatedPaymentDates);

    this.setState(
      {
        getlastPaymentDates: record.paymentDates,
        isDateChanged: true,
        getPaymentDateIndex: rowIndex,
        PaymentDate: newValue,
        openModalPayment: true,
        paymentDates: updatedPaymentDates,
        singleRowPaymentDate: record,
      },
      () => console.log("rowData", this.state.singleRowPaymentDate)
    );
  };

  handleVersionChange = async (newValue, record, rowIndex) => {
    console.log("newValue", newValue);

    const updatedVersions = [...this.state.versions];
    updatedVersions[rowIndex] = newValue;

    console.log("updatedVersions", rowIndex, updatedVersions);

    this.setState({
      versionSelected: updatedVersions[rowIndex],
      versions: updatedVersions,
      singleRowVersion: record,
      getDashboardVersions: record.versions,
    });
  };

  updatePaymentDateValue = async (newValue, record) => {
    console.log("newValue", newValue, record);

    const input = {
      "Deal Name": record.dealName,
      "Payment Date": record.paymentDates,
      Version: record.versions,
      "Asset Class": record.assetClass,
      Issuer: record.issuer,
      Trustee: record.trustee,
    };

    const data = {
      input,
      selectedDate: newValue,
      peers: this.state.peers,
    };

    console.log("data", data);

    try {
      const APIResponse = await getDealDetailsByInvestorPaymentDate(data);
      console.log("APIResponse", APIResponse);

      if (APIResponse.status === 200) {
        const res = APIResponse.data;

        const modifiedData = this.state.tableData.map((item) =>
          item.dealName === record.dealName ? { ...item, ...res } : item
        );

        console.log("modifiedData", modifiedData);

        this.setState(
          {
            getLoansLoader: false,
            tableData: modifiedData,
            loading: false,
            openModalPayment: false,
          },
          () => {
            console.log("Updated tableData", this.state.tableData);
          }
        );
      } else {
        throw new Error("API Response error");
      }
    } catch (error) {
      console.error("Error updating payment date:", error);

      this.setState({
        getLoansLoader: false,
        loading: false,
        openModalPayment: false,
      });

      // Display error notification
      this.props.enqueueSnackbar("Something went wrong", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleSelectChange = (event, filterType) => {
    console.log("<<<<<", event.target.value);
    this.setState(
      {
        [filterType]: event.target.value,
      },
      () => {
        this.filterTableData();
      }
    );
  };

  filterTableData = () => {
    const {
      displayData,
      manipulatedTableData,
      Issuer,
      Trustee,
      "Asset Class": AssetClass,
    } = this.state;

    console.log({ Issuer, Trustee, AssetClass });

    // Filter data based on selected filters
    let filteredData = manipulatedTableData;

    if (Issuer) {
      filteredData = filteredData.filter((item) => item["Issuer"] === Issuer);
    }

    if (Trustee) {
      filteredData = filteredData.filter((item) => item["Trustee"] === Trustee);
    }

    if (AssetClass) {
      filteredData = filteredData.filter(
        (item) => item["Asset Class"] === AssetClass
      );
    }

    this.setState({ displayData: filteredData });
  };

  render() {
    const { displayData, currentPage, pageSize, loading } = this.state;

    // Paginate data manually
    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const columns = [
      {
        title: "Sr. No",
        dataIndex: "dealName", // Change as per your data source
        key: "srNo",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deal Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Deal Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Deal Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (text, record, index) => <>{index + 1}</>,
      },
      {
        title: "Deal Name",
        dataIndex: "Deal Name",
        key: "Deal Name",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Deal Name"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Deal Name")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Deal Name"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Payment Date",
        dataIndex: "Payment Date",
        key: "Payment Date",
        render: (dates, record, index) => (
          <select
            className="input-select-general-new2"
            value={this.state.paymentDates[index]}
            onChange={(e) =>
              this.handlePaymentDateChange(e.target.value, record, index)
            }
            style={{
              backgroundColor: "transparent",
              color: "var(--button)",
              border: "0px",
              borderRadius: "2px",
              outline: "none",
              paddingRight: "30px",
            }}
          >
            {dates.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        ),
      },
      {
        title: "Version",
        dataIndex: "Version",
        key: "Version",
        render: (versions, record, index) => (
          <select
            className="input-select-general-new2"
            value={this.state.versions[index]}
            onChange={(e) =>
              this.handleVersionChange(e.target.value, record, index)
            }
            style={{
              backgroundColor: "transparent",
              color: "var(--button)",
              border: "0px",
              borderRadius: "2px",
              outline: "none",
              paddingRight: "30px",
            }}
          >
            {versions.map((version) => (
              <option key={version} value={version}>
                {version}
              </option>
            ))}
          </select>
        ),
      },

      {
        title: "Asset Class",
        dataIndex: "Asset Class",
        key: "Asset Class",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Asset Class"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Asset Class")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Asset Class"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Issuer",
        dataIndex: "Issuer",
        key: "Issuer",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Issuer"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Issuer")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Issuer"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Trustee",
        dataIndex: "Trustee",
        key: "Trustee",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Trustee"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Trustee")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["Trustee"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (text, record, index) => (
          <div className="align-actions-investor">
            <button
              className="popupdashboard"
              onClick={() => this.Recurringreport(record, index)}
            >
              View Report
            </button>
          </div>
        ),
      },
    ];
    return (
      <div className="page">
        <Sidebar1 activeComponent={"Consolidated Report"} />
        <div className="content">
          <div className="header">{/* <Header></Header> */}</div>
          <div className="row1">
            <div
              className="col-5 col-sm-6 col-md-3 d-flex hellocard"
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1.5rem",
                width: "90rem",
                marginLeft: "25px",
              }}
            >
              <div style={{ display: "flex", paddingTop: "1rem" }}>
                <h3 className="headerdashboard">CONSOLIDATED REPORT</h3>
              </div>
            </div>
          </div>
          <div className="row row14 row14_Copy">
            <div className="general-InvContainer">
              <div>
                <label className="label">Issuer</label>
                <select
                  className="input-select-general-new1"
                  value={this.state.Issuer || ""}
                  onChange={(event) => this.handleSelectChange(event, "Issuer")}
                >
                  <option value="">Select any one</option>
                  {this.state.IssuerArrays.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="general-InvContainer">
              <div>
                <label className="label">Trustee</label>
                <select
                  className="input-select-general-new1"
                  value={this.state.Trustee || ""}
                  onChange={(event) =>
                    this.handleSelectChange(event, "Trustee")
                  }
                >
                  <option value="">Select any one</option>
                  {this.state.TrusteeArrays.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="general-InvContainer gap-InvContainer">
              <div>
                <label className="label">AssetClass</label>
                <select
                  className="input-select-general-new1"
                  value={this.state["Asset Class"] || ""}
                  onChange={(event) =>
                    this.handleSelectChange(event, "Asset Class")
                  }
                >
                  <option value="">Select any one</option>
                  {this.state.AssetArrays.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "-45px" }}>
            {/* <div style={{ marginTop: "50px" }}></div> */}
            <div style={{ marginTop: "-30px" }}>
              <div className="page-content">
                <React.Fragment>
                  {this.state.reorderchart == true ? (
                    <div>
                      <Table
                        dataSource={displayData.map((item, index) => ({
                          ...item,
                          key: index,
                        }))}
                        columns={columns}
                        className="performance-table"
                        pagination={false}
                        loading={this.state.loading}
                        scroll={{ y: 600, x: "max-content" }}
                      />
                      {/* <div
                   style={{
                     display: "flex",
                     justifyContent: "center",
                     marginTop: "16px",
                     overflow: "hidden",
                   }}
                 >
                   <Pagination
                     current={currentPage}
                     pageSize={pageSize}
                     total={displayData.length}
                     onChange={this.handlePaginationChange}
                   />
                 </div> */}
                    </div>
                  ) : (
                    <div className="workbench-table-container">
                      <React.Fragment></React.Fragment>
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>

          <ReactModal
            isOpen={this.state.openModalPayment}
            contentLabel="Minimal Modal Example"
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the alpha value for desired transparency
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "fixed", // Use fixed positioning for the overlay
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999, // Set a high z-index to ensure the modal appears on top
              },
              content: {
                position: "absolute",
                background: "transparent", // Make the modal content transparent
                border: "none", // Optional: Remove any borders
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                overflow: "hidden",
              },
            }}
          >
            <React.Fragment>
              <img
                src={BeanEater}
                style={{
                  width: "8vw",
                  height: "9vh",
                  backgroundColor: "transparent",
                }}
              />
              <h3 className="dot-loader">Loading.Please wait</h3>
            </React.Fragment>
          </ReactModal>
        </div>
      </div>
    );
  }
}

export default withSnackbar(InvestorDashboard);
