import MUIDataTable from "mui-datatables";
import { withSnackbar } from "notistack";
import React, { Component } from "react";
import Sidebar1 from "../../../../../components/sidebar/sidebar";
import Header from "../../../../../components/header/header";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { TrainRounded } from "@material-ui/icons";
import Loader from "../../../../../components/loader";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  FilesAddDealRecurring,
  FilesRecurring,
  DownloadRowClick,
  getAllVersionsByPaymentDate,
  getDealDetailsByDealName,
} from "../../../../../servies/services";
import * as moment from "moment";
import CustomFilterComponent from "../../../../../components/CustomFilterComponent/CustomFilterComponent.js";
import { Pagination } from "antd"; // Import AntD Pagination
import fIlter from "../../../../../images/FilterMappedTape.svg"
import { Table } from "antd";

const getUniqueValues = (data, columnKey) => {
  // console.log({ data, columnKey });

  return [...new Set(data.map((item) => item[columnKey]))];
};


class Files_InvRecurring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      tableData: [],
      peer: sessionStorage.getItem("peer"),
      loading: false,
      getLoansLoader: false,
      formLoader1: false,
      TableName: "PaymentRules",
      screenloader: false,
      CurrentPaymentDate: "",
      userId: sessionStorage.getItem("user_id"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      peer: sessionStorage.getItem("peer"),

      file1: "",
      filename1: "",
      DealName:
        sessionStorage.getItem("dealname") === null
          ? sessionStorage.getItem("servicerdealname")
          : sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      getDashboardDeals: JSON.parse(
        sessionStorage.getItem("getDashboardDeals")
      ),
      getDashboardVersions: JSON.parse(
        sessionStorage.getItem("getDashboardVersions")
      ),
      getDashboardDates: JSON.parse(
        sessionStorage.getItem("getDashboardDates")
      ),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Version: sessionStorage.getItem("version"),

      anchorEl: null,
      servicerValues: [],
      isDateSelected: null,
      reorderchart: true,
      barchart: false,
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
      isDisable: JSON.parse(sessionStorage.getItem("isdisable")),
      isLoansProcessed: sessionStorage.getItem("isLoansProcessed"),
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {},
      formData: {
        Document: "",
        DocumentType: "",
        FileType: "",
        ReportDate: "",
        Privacy: "UnRestricted",
      },
    };
  }

  showPrev = () => {
    this.props.history.push("/investor/loantape_InvRecurring");
  };

  goBackToDashboard = () => {
    this.props.history.push({
      pathname: "/investor/dashboard",
    });
  };
  handleClickReport = () => {
    this.setState({
      activeInsights1: true,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: false,
    });
    this.props.history.push("/investor/report_InvRecurring");
  };

  handleClickCollateral = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: true,
      activeInsights3: false,
      activeInsights4: false,
    });
    this.props.history.push("/investor/collateral_InvRecurring");
  };
  handleClickLoanTape = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: true,
      activeInsights4: false,
    });
    this.props.history.push("/investor/loantape_InvRecurring");
  };
  handleClickFiles = () => {
    this.setState({
      activeInsights1: false,
      activeInsights2: false,
      activeInsights3: false,
      activeInsights4: true,
    });
    // this.props.history.push("/investor/file_InvRecurring");
  };

  ViewDeal = (value, tableMeta) => {
    console.log(tableMeta.rowData);
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem("assettype", tableMeta.rowData[1]);
    this.props.history.push({
      pathname: "/investor/files",
      state: {
        details:
          this.state.NextPaymentDate === ""
            ? tableMeta.rowData[4]
            : this.state.NextPaymentDate,
        servicerList: this.state.servicerValues,
      },
    });
  };

  AddSubmit = (e) => {
    e.preventDefault();
    console.log(this.state.formData);

    this.setState({
      formData: {
        Document: "",
        DocumentType: "",
        FileType: "",
        ReportDate: "",
        Privacy: "UnRestricted",
      },
      formLoader1: true,
      file1: "",
    });

    this.FilesAddDealRecurring();
  };

  FilesAddDealRecurring = async () => {
    this.setState({ formLoader: true });

    const newdata = new FormData();
    newdata.append("DealName", this.state.DealName);
    newdata.append("Document", this.state.formData.Document);
    newdata.append("DocumentType", this.state.formData.DocumentType);
    newdata.append("FileType", this.state.formData.FileType);
    newdata.append("ReportDate", this.state.formData.ReportDate);
    newdata.append("Privacy", this.state.formData.Privacy);
    newdata.append("File", this.state.file1);
    newdata.append("userId", this.state.userId);
    newdata.append("peers", this.state.peers);
    console.log("newdata", newdata);

    const APIResponse = await FilesAddDealRecurring(newdata);
    console.log("ress", APIResponse);

    if (APIResponse.status === 200) {
      if (APIResponse.data.success === true) {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "info",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
        this.FilesRecurring();
      } else {
        const message = APIResponse.data.message;
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
        this.setState({ formLoader: false });
      }
    } else {
      const message = "Something went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      this.setState({ formLoader: false });
    }
  };

  FilesRecurring = async () => {
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await FilesRecurring(data);

    console.log("FilesRecurring", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tableData: APIResponse.data,
        displayData:APIResponse.data,
        loading: false,
        formLoader1: false,
      });
      // const message = "Deal Document Update Success";
      // this.props.enqueueSnackbar(message, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      // });
      //this.props.history.push("/admin/previewmapfields");
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = "Something Went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  DownloadRowClick = async (tableData) => {
    console.log(tableData);
    this.setState({ loading: true });
    const data = {};
    data.documentID = tableData[7];
    data.peer = this.state.peer;

    const newDoc = tableData[0];
    console.log("datata", data);
    const APIResponse = await DownloadRowClick(data);

    if (APIResponse.status === 200) {
      let fileType = "";

      if (tableData[2] === "PDF") {
        fileType = "application/pdf";
      } else if (tableData[2] === "CSV") {
        fileType = "text/csv";
      } else if (tableData[2] === "Excel") {
        fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      }

      // // Your input string
      // const inputString = APIResponse.data;

      // // Create a TextEncoder
      // const textEncoder = new TextEncoder();

      // // Encode the string into bytes
      // const encodedBytes = textEncoder.encode(inputString);

      // // Convert the bytes to binary representation
      // const binaryString = Array.from(encodedBytes, byte => byte.toString(2)).join(' ');

      // console.log(binaryString);

      // Create a Blob containing the Excel data
      const blob = new Blob([APIResponse.data], { type: fileType });

      // Create a download URL for the Blob
      const downloadUrl = URL.createObjectURL(blob);

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", newDoc);
      document.body.appendChild(link);

      // Trigger a click event to download the file
      link.click();

      // Clean up the URL and remove the link
      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
        formLoader1: false,
      });
      const message = "Something Went Wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);
    if (this.state.Version === "V1" || this.state.Version === "V2") {
      this.setState({ isDisable: this.state.isDisable });
      this.FilesRecurring();
    } else {
      this.setState({ isDisable: false });
      this.FilesRecurring();
    }
  }
  // async componentDidMount() {
  // var component = window.location.pathname;
  // }
  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true,
      },
      overrides: {
        MUIDataTable: {
          root: {
            border: "none !important",
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "rgb(229,229,229,0.3) !important",
            },
            "&.Mui-selected": {
              backgroundColor: "white !important",
            },
          },
          hoverCursor: {
            cursor: "auto !important",
          },
        },
        MuiTableCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            padding: "20px",
            fontSize: "0.980rem !important",
          },
        },
        MuiCircularProgress: {
          colorSecondary: {
            color: "#048c88 !important",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            borderBottom: "none !important",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            fontFamily: "Mulish, sans-serif !important",
            backgroundColor: "rgba(1, 142, 130, 0.1) !important",
            borderBottom: "none !important",
            paddingBottom: "5px !important",
            paddingTop: "5px !important",
            paddingLeft: "15px !important",
          },
          toolButton: {
            fontFamily: "Catamaran !important",
            fontWeight: "600 !important",
            fontSize: "15px !important",
            backgroundColor: "none !important",
            padding: "none !important",
            marginLeft: "none !important",
            textTransform: "none !important",
            border: "none !important",
            borderRadius: "none !important",
          },
        },
        MUIDataTableToolbar: {
          root: {
            fontFamily: "Mulish !important",
            paddingLeft: "5px !important",
            paddingRight: "5px !important",
          },
          titleText: {
            fontFamily: "Mulish !important",
            fontSize: "28px",
            color: "#212121",
            fontWeight: "600",
            fontFamily: "arial",
            marginBottom: "20px",
            marginTop: "20px",
          },
          icon: {
            color: "#018E82",
            paddingRight: "14px !important",
            "&:hover": {
              color: "#018E82 !important",
            },
          },
          iconActive: {
            color: "#018E82 !important",
          },
        },
        MuiButton: {
          contained: {
            backgroundColor: "#FFC000 !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          outlined: {
            backgroundColor: "#fff !important",
            padding: "5px 30px !important",
            marginLeft: "10px !important",
            textTransform: "none !important",
            border: "1.2px solid #212121 !important",
            borderRadius: "20px !important",
            boxShadow: "none !important",
          },
          label: {
            fontSize: "15px !important",
            // padding: "0px 20px !important",
            // width:"20rem",
            fontWeight: "600 !important",
            fontFamily: "Mulish !important",
          },
          textPrimary: {
            color: "#018E82 !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            borderBottom: "none !important",
          },
          navContainer: {
            justifyContent: "center",
          },
          toolbar: {
            paddingLeft: "50px !important",
          },
        },
        MuiTableSortLabel: {
          icon: {
            color: "#018E82 !important",
          },
          active: {
            color: "#018E82 !important",
          },
        },
        MuiTablePagination: {
          caption: {
            color: "#8C8C8C",
            marginRight: `${
              this.state.currentPage >= 1 && this.state.currentPage <= 9
                ? "-138"
                : this.state.currentPage >= 10
                ? "-142"
                : "-130"
            }px`,
            fontSize: "0.80rem",
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "#018E82 !important",
            zIndex: "1000",
            marginRight: "60px",
            paddingLeft: "-25px",
          },
        },
        MUIDataTable: {
          paper: {
            boxShadow: "none !important",
          },
          responsiveBase: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
          },
        },
        MuiInput: {
          root: {
            border: "1px solid #212121 !important",
            borderRadius: "10px !important",
            padding: "5px !important",
          },
          underline: {
            "&:after": {
              borderBottom: "none !important",
            },
            "&:before": {
              borderBottom: "none !important",
            },
          },
        },
      },
    });
  Recurring = (value, tableMeta) => {
    console.log(tableMeta.rowData);
    sessionStorage.setItem("dealname", value);
    sessionStorage.setItem(
      "nextpaymentdate",
      this.state.CurrentPaymentDate === ""
        ? tableMeta.rowData[3]
        : this.state.CurrentPaymentDate
    );
    // sessionStorage.setItem("lastpaymentdate", tableMeta.rowData[3]);
    this.props.history.push({
      pathname: "/investor/general_recurring",
    });
  };

  handleClickReorder = () => {
    this.setState({
      reorderchart: true,
      barchart: false,
    });
  };

  handleClickbarchart = () => {
    this.setState({
      reorderchart: false,
      barchart: true,
    });
  };

  handleDealChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        DealName: newValue,
        screenloader: true,
      },
      async () => {
        sessionStorage.setItem("dealname", newValue);
        await this.getDealDetailsByDealName();
        // await this.FilesRecurring();

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.FilesRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            await this.FilesRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              {
                isVersionChange: false,
                publishVersion: Version,
              },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.FilesRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getDealDetailsByDealName = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getDealDetailsByDealName(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem(
        "nextpaymentdate",
        APIResponse.data.PaymentDates[0]
      );
      sessionStorage.setItem("version", APIResponse.data.Versions[0]);
      sessionStorage.setItem(
        "getDashboardDates",
        JSON.stringify(APIResponse.data.PaymentDates)
      );
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Versions)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardDates: JSON.parse(
            sessionStorage.getItem("getDashboardDates")
          ),
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handlePaymentDateChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        NextPaymentDate: newValue,
        screenloader: true,
        // openModalPayment: true
      },
      async () => {
        console.log("pppppp", this.state.NextPaymentDate);
        sessionStorage.setItem("nextpaymentdate", newValue);
        await this.getAllVersionsByPaymentDate();

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isVersionChange: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.FilesRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (Version !== latestVersion) {
            this.setState({
              isVersionChange: this.state.isVersionChange,
              publishVersion: Version,
            });
            this.FilesRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isVersionChange: false, publishVersion: Version },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.FilesRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  getAllVersionsByPaymentDate = async () => {
    this.setState({ getLoansLoader: true, loading: true });
    console.log("ssssssssssssssssssssss", this.state.NextPaymentDate);
    var data = {};
    data.DealName = this.state.DealName;
    data.selectedDate = this.state.NextPaymentDate;
    data.peer = this.state.peer;

    console.log("datata", data);
    const APIResponse = await getAllVersionsByPaymentDate(data);

    console.log("PaymentgetAllVersionsByPaymentDate", APIResponse.data);
    if (APIResponse.status === 200) {
      sessionStorage.setItem("version", APIResponse.data.Version[0]);
      sessionStorage.setItem(
        "getDashboardVersions",
        JSON.stringify(APIResponse.data.Version)
      );

      this.setState(
        {
          getLoansLoader: false,
          loading: false,
          getDashboardVersions: JSON.parse(
            sessionStorage.getItem("getDashboardVersions")
          ),
          Version: sessionStorage.getItem("version"),
        },
        () => console.log("vvvvvvvvvvvvvvv", this.state.getDashboardVersions)
      );
    } else if (APIResponse.status === 201) {
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({ screenloader: false });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  handleVersionChange = async (event) => {
    const newValue = event.target.value;

    // Update the NextPaymentDate state with the selected value
    this.setState(
      {
        Version: newValue,
        screenloader: true,
      },
      async () => {
        console.log("pppppp", this.state.Version);
        sessionStorage.setItem("version", newValue);

        const { Version, getDashboardVersions } = this.state;

        if (
          (Version === "WIP" || Version === "V1") &&
          getDashboardVersions.length === 1
        ) {
          this.setState(
            { isDisable: false, publishVersion: Version, Version: "WIP" },
            async () => {
              await this.FilesRecurring();
            }
          );
          const links = document.querySelectorAll(".linkToTable a");

          links.forEach(function (link) {
            link.addEventListener("click", function (e) {
              e.preventDefault();

              const targetId = link.getAttribute("href").substring(1);
              const targetElement = document.getElementById(targetId);

              if (targetElement) {
                window.scrollTo({
                  top: targetElement.offsetTop,
                  behavior: "smooth",
                });
              }
            });
          });
        } else {
          function getMaxVersion(versions) {
            let highestNumber = -1;
            let prefix = "";

            for (const version of versions) {
              const numberMatch = version.match(/\d+/); // Extract the number using regex
              if (numberMatch) {
                const number = parseInt(numberMatch[0]);
                if (number > highestNumber) {
                  highestNumber = number;
                  prefix = version.replace(/\d+/g, ""); // Extract the prefix from the current version
                }
              }
            }

            if (highestNumber >= 0) {
              return prefix + highestNumber;
            } else {
              // Handle the case where no valid version numbers were found
              return null;
            }
          }

          const latestVersion = getMaxVersion(getDashboardVersions);
          if (sessionStorage.getItem("version") !== latestVersion) {
            this.setState({
              isDisable: this.state.isDisable,
              publishVersion: sessionStorage.getItem("version"),
            });
            this.FilesRecurring();
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          } else {
            this.setState(
              { isDisable: false, publishVersion: Version },
              async () => {
                sessionStorage.setItem("version", "WIP");
                await this.FilesRecurring();
              }
            );
            const links = document.querySelectorAll(".linkToTable a");

            links.forEach(function (link) {
              link.addEventListener("click", function (e) {
                e.preventDefault();

                const targetId = link.getAttribute("href").substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                  window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: "smooth",
                  });
                }
              });
            });
          }
        }
      }
    );
  };

  handleFilter = (tableName, data, columnKey, criteria) => {

    const { filterCriteria, tableData, currentBatch, itemsPerBatch } =
      this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    if (isCriteriaEmpty) {
      // Reset to the first batch of the full dataset if no criteria are selected
      this.setState({
        displayData: tableData.slice(0, currentBatch * itemsPerBatch),
        filteredClrChangeColumns: {
          ...this.state.filteredClrChangeColumns,
          [columnKey]: false,
        },
      });
      return;
    }

    // Apply filters
    const filtered = tableData.filter((item) => {
      return Object.keys(newCriteria).every((key) => {
        const criteria = newCriteria[key];
        if (!criteria) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return false;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });

    console.log({ filtered });

    // Apply sorting after filtering
    const { sortOrder } = this.state;
    const sortedFiltered = [...filtered].sort((a, b) => {
      const valueA = a[sortOrder.columnKey];
      const valueB = b[sortOrder.columnKey];

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return sortOrder.order === "ascend"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder.order === "ascend"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    });

    // Update display data to only show the current batch
    this.setState({
      displayData: sortedFiltered.slice(0, currentBatch * itemsPerBatch),
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handleSort = (tableName, columnKey, order) => {
    const { tableData, itemsPerBatch } = this.state;

    // Determine the chunk size based on the total dataset size
    const chunkSize = tableData.length; // Max 1000 rows or 10% of the dataset
    const sortedData = [];

    const sortChunk = (start) => {
      const end = Math.min(start + chunkSize, tableData.length);
      const chunk = tableData.slice(start, end).sort((a, b) => {
        const valueA = a[columnKey];
        const valueB = b[columnKey];

        if (!isNaN(valueA) && !isNaN(valueB)) {
          return order === "ascend" ? valueA - valueB : valueB - valueA;
        }

        if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
          return order === "ascend"
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
        }

        if (typeof valueA === "string" && typeof valueB === "string") {
          return order === "ascend"
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }

        if (valueA == null || valueB == null) {
          return valueA == null ? 1 : -1;
        }

        return 0;
      });

      sortedData.push(...chunk);

      if (end < tableData.length) {
        setTimeout(() => sortChunk(end), 0); // Process the next chunk
      } else {
        // Update state after all chunks are sorted
        this.setState({
          tableData: sortedData,
          displayData: sortedData.slice(0, itemsPerBatch),
        });
      }
    };

    sortChunk(0);
  };

  handlePaginationChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const { displayData, currentPage, pageSize, loading } = this.state;
    const sortedDeals = [...this.state.getDashboardDeals].sort();

    // Paginate data manually
    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    const columns = [
      {
        title: "Document",
        dataIndex: "Document",
        key: "Document",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Document"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Document")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["Document"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "Document Type",
        dataIndex: "Document Type",
        key: "Document Type",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Document Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Document Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["Document Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "File Type",
        dataIndex: "File Type",
        key: "File Type",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="File Type"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "File Type")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["File Type"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "Report Date",
        dataIndex: "Report Date",
        key: "Report Date",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Report Date"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Report Date")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["Report Date"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "File Status",
        dataIndex: "File Status",
        key: "File Status",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="File Status"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "File Status")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["File Status"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "File Size",
        dataIndex: "File Size",
        key: "File Size",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="File Size"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "File Size")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["File Size"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "Privacy",
        dataIndex: "Privacy",
        key: "Privacy",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Privacy"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Privacy")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["Privacy"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "Document ID",
        dataIndex: "Document ID",
        key: "Document ID",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="Document ID"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tableData, "Document ID")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
               width:"15px", // Adjust size as needed
              height:"10px",
              color: this.state.filteredClrChangeColumns["Document ID"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <span>{value}</span>, // Render plain text for "Document"
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <React.Fragment>
            <div className="">
              <button
                className="popupbutton1"
                onClick={() =>
                  this.DownloadRowClick([
                    record.document,
                    record.documentType,
                    record.fileType,
                    record.reportDate,
                    record.fileStatus,
                    record.fileSize,
                    record.privacy,
                    record.documentID,
                  ])
                }
              >
                Download
              </button>
            </div>
          </React.Fragment>
        ),
      },
      
    ];
    return (
      <div className="page">
        <Sidebar1 activeComponent={"Consolidated Report"} />
        <div className="content">
          <div className="header">{/* <Header></Header> */}</div>
          <div className="page-contentofpool1">
            <div className="row1">
              <div
                className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "1.5rem",
                  width: "90rem",
                  position: "relative",
                }}
              >
                <div style={{ display: "flex" }}>
                  <KeyboardBackspaceIcon
                    onClick={this.goBackToDashboard}
                    className="left-arrow-muis1 left-arrow-servicer"
                  ></KeyboardBackspaceIcon>
                  <h3 className="headerdashboard">CONSOLIDATED REPORT</h3>
                </div>
              </div>
            </div>
            <div className="row row13">
              <div className="general-InvContainer">
                <div>
                  <label className="label">Deal Name</label>
                  <select
                    className="input-select-general-new1-deal"
                    value={this.state.DealName}
                    onChange={this.handleDealChange}
                  >
                    {sortedDeals.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="general-InvContainer">
                <div>
                  <label className="label">Payment Date</label>
                  <select
                    className="input-select-general-new1"
                    value={this.state?.NextPaymentDate}
                    onChange={this.handlePaymentDateChange}
                  >
                    {this.state.getDashboardDates.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="general-InvContainer">
                <div>
                  <label className="label">Version</label>
                  <select
                    className="input-select-general-new1"
                    value={this.state.Version}
                    onChange={this.handleVersionChange}
                  >
                    {this.state.getDashboardVersions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "-35px" }}>
              <div className="row row1">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "-15px",
                  }}
                >
                  <div>
                    <button
                      type="button"
                      onClick={() => this.handleClickReport()}
                      className={
                        this.state.activeInsights1 == true
                          ? "issuerDashboard-table-top-button-insights-active"
                          : "issuerDashboard-table-top-button-insights"
                      }
                    >
                      Report
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickCollateral()}
                      className={
                        this.state.activeInsights2 == true
                          ? "issuerDashboard-table-top-button-workbench-active"
                          : "issuerDashboard-table-top-button-workbench"
                      }
                    >
                      Collateral
                    </button>

                    <button
                      type="button"
                      onClick={() => this.handleClickLoanTape()}
                      className={
                        this.state.activeInsights3 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Loan Tape
                    </button>
                    <button
                      type="button"
                      onClick={() => this.handleClickFiles()}
                      className={
                        this.state.activeInsights4 == true
                          ? "issuerDashboard-table-top-button-rejected-active"
                          : "issuerDashboard-table-top-button-rejected"
                      }
                    >
                      Files
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
              <div style={{ marginTop: "-30px", marginLeft: "-25px" }}>
                <div className="page-content">
                  <React.Fragment>
                    {this.state.reorderchart == true ? (
                      <div>
                         <Table
                  style={{position:"relative" , top:"1rem"}}
                    className="custom-antds-table"
                    dataSource={this.state.tableData.map((item, index) => ({
                      ...item,
                      key: index, // Add a unique key for each row
                    }))}
                    columns={columns} // Use the refactored Ant Design-compatible columns
                    pagination={false}
                    scroll={{ x: "max-content", y: 600 }} // Enable horizontal and vertical scroll
                    rowKey={(record) => record.key} // Use unique key for rows
                  />
                      </div>
                    ) : (
                      <div>
                        <React.Fragment></React.Fragment>
                      </div>
                    )}
                    <>
                      <div className="btn_move_Needed">
                        <div className="btn_prevs" onClick={this.showPrev}>
                          Previous
                        </div>

                        <button
                          className="btn_next"
                          onClick={this.showNext}
                          disabled
                        >
                          Next
                        </button>
                      </div>
                    </>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(Files_InvRecurring);
