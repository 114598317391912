/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Sidebar1 from "../../../components/sidebar/sidebar";
import { withSnackbar } from "notistack";
import { customStylesautosmallmodal1 } from "../../../components/customscripts/customscript";
import edit from "../../../images/edit.svg";
import dElete from "../../../images/delete.svg";
import {
  getdefinition,
  deletedefinition,
  updatedefinition,
} from "../../../servies/services";
import CloseIcon from "@material-ui/icons/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./stdFields.css";
import ReactModal from "react-modal";
import { customStylesauto } from "../../../components/customscripts/customscript";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Table } from "antd";
import CustomFilterComponent from "../../../components/CustomFilterComponent/CustomFilterComponent.js";
import { Pagination } from "antd"; // Import AntD Pagination
import fIlter from "../../../images/FilterMappedTape.svg";
const getUniqueValues = (data, columnKey) => {
  if (!Array.isArray(data)) {
    console.warn("getUniqueValues: Invalid data provided, expected an array.", {
      data,
      columnKey,
    });
    return [];
  }
  return [...new Set(data.map((item) => item[columnKey]))];
};

class StdFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: sessionStorage.getItem("token"),
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      AssetType: sessionStorage.getItem("assettype"),
      loading: true,
      isStdFields: true,
      getLoansLoader: false,
      message: "",
      open1: false,
      open2:false,
      tabledata: [
        {
          id: "",
          def: "",
          fieldcode: "",
          section: "",
          descp: "",
          "ND1-ND4 allowed?": "",
          "ND5 allowed?": "",
          format: "",
          fieldtype: "",
          datatype: "",
        },
      ],
      editdata: [
        {
          def: "",
          section: "",
          descp: "",
          "ND1-ND4 allowed?": "",
          "ND5 allowed?": "",
          format: "",
          fieldtype: "",
          datatype: "",
        },
      ],
      newField: {
        id: "",
        def: "",
        fieldcode: "",
        section: "",
        descp: "",
        "ND1-ND4 allowed?": "",
        "ND5 allowed?": "",
        format: "",
        fieldtype: "",
        datatype: "",
      },
      updatedtable: [],
      ApproveStatus: "",
      OrgName: "",
      status: "",
      selectedOption: "",
      isTableVisible: false,
      LastUpdate: "",
      currentlyEditedRow: -1,
      editingRows: [],
      showSearchBox: false,
      searchText: "",
      currentPage: 0,
      isEditMode: "none",
      selectableRows: false,
      originalTabledata: [],
      selectedAssetClass: "",
      assetClassCounters: {},
      fieldsDisabled: true,
      originalfieldcode: "",
      originalsection: "",
      options: [],
      originalRowData: Array.from({ length: 10 }, () => ({})),
      filterCriteria: {}, // Stores filter conditions
      sortOrder: {}, // Stores sort column and order
      displayData: [], // Filtered and sorted data for display
      currentBatch: 1, // Current batch of data to display
      itemsPerBatch: 50, // Number of items per batch
      currentPage: 1, // Current page
      pageSize: 10, // Number of rows per page
      filteredClrChangeColumns: {},
    };
  }

  onOpenModal = () => {
    console.log("Add Fields");
    this.setState({
      open1: true,
      tabledata: {
        id: "",
        def: "",
        fieldcode: "",
        section: "",
        descp: "",
        "ND1-ND4 allowed?": "",
        "ND5 allowed?": "",
        format: "",
        fieldtype: "",
        datatype: "",
      },
    });
  };

  getdata = async (selectedOption) => {
    this.setState({ getLoansLoader: true, tabledata: [], loading: true }); // let x = this.state?.NextPaymentDate; // let month = x.slice(0, 2); // let year = x.slice(6, 10);//dealrecurring
    var data = {};
    data.AssetType = selectedOption;
    data.peer = this.state.peer;
    console.log("datata", data);
    const APIResponse = await getdefinition(data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tabledata: APIResponse.data,
        displayData: APIResponse.data,
        originalfieldcode: APIResponse.data[0].fieldcode,
        originalsection: APIResponse.data[0].section,
        loading: false,
        isEditMode: true,
        LastUpdate: APIResponse.data[0].lastupdate,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = "Couldn't create the deal";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  handleAssetClassChange = async (event, APIResponse) => {
    const selectedAssetType = event.target.value;
    this.setState({
      selectedOption: selectedAssetType,
      fieldsDisabled: false,
    });
    if (selectedAssetType) {
      if (!this.state.assetClassCounters[selectedAssetType]) {
        this.setState((prevState) => ({
          assetClassCounters: {
            ...prevState.assetClassCounters,
            [selectedAssetType]: 0,
          },
        }));
      }
      const defaultRow = {
        def: "",
        fieldcode: "",
        section: "",
        descp: "",
        "ND1-ND4 allowed?": "",
        "ND5 allowed?": "",
        format: "",
        fieldtype: "",
        datatype: "",
      };
      let updatedTabledata = [...this.state.tabledata];
      if (updatedTabledata.length === 0) {
        updatedTabledata = [defaultRow];
      } else {
        updatedTabledata[0] = defaultRow;
      }
      this.setState({
        tabledata: updatedTabledata,
      });
      await this.getdata(selectedAssetType);
    }
  };
  handleRowEdit = (rowIndex) => {
    this.setState({
      currentlyEditedRow: rowIndex,
      isEditMode: "none",
    });
  };
  Edit = (index) => {
    const originalRowData = [...this.state.originalRowData];
    originalRowData[index] = { ...this.state.tabledata[index] };
    const originalTabledata = [...this.state.tabledata];
    const selectedSection = originalTabledata[index].section;
    this.setState({
      originalRowData,
      originalTabledata,
      currentlyEditedRow: index,
      selectedSection, // Store the selected section value
    });
    this.handleRowEdit(index);
  };
  handleFieldChange = (rowIndex, fieldName, value) => {
    const updatedTabledata = [...this.state.tabledata];
    updatedTabledata[rowIndex][fieldName] = value;
    this.setState({
      tabledata: updatedTabledata,
      displayData: updatedTabledata,
    });
  };
  DeleteData = async (index) => {
    const { selectedOption, peers, token, tabledata } = this.state;

    // Extract the row to be deleted based on the index
    const updatedRow = tabledata[index];

    // Ensure the index is valid
    if (index >= 0 && index < tabledata.length) {
      console.log("Row to be deleted:", updatedRow);

      // Prepare the request payload
      const requestData = {
        AssetType: selectedOption,
        peers: peers, // Ensure peers is an array of relevant peer strings
        tabledata: [
          {
            id: updatedRow.id,
            def: updatedRow.def,
            fieldcode: updatedRow.fieldcode,
            section: updatedRow.section,
            descp: updatedRow.descp,
            "ND1-ND4 allowed?": updatedRow["ND1-ND4 allowed?"],
            "ND5 allowed?": updatedRow["ND5 allowed?"],
            format: updatedRow.format,
            fieldtype: updatedRow.fieldtype,
            datatype: updatedRow.datatype,
          },
        ],
      };

      // Display loader
      this.setState({ getLoansLoader: true, loading: true });

      try {
        // Make the API call
        const APIResponse = await deletedefinition(requestData);

        console.log("DeleteData API Response:", APIResponse.data);

        // Handle the success response
        if (APIResponse.status === 200 && APIResponse.data.success) {
          const message =
            APIResponse.data.result || "Field deleted successfully!";
          this.props.enqueueSnackbar(message, {
            variant: "success",
            autoHideDuration: 3000,
          });

          // Refresh the table data or remove the deleted row locally
          const updatedTableData = tabledata.filter((_, idx) => idx !== index);
          this.setState({ tabledata: updatedTableData , displayData:updatedTableData});

          // Optionally fetch updated data from the server
          await this.getDefinitionAfterSave(selectedOption);
        } else {
          // Handle failure responses
          const message =
            APIResponse.data.result || "Failed to delete the field!";
          this.props.enqueueSnackbar(message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        console.error("Error while deleting data:", error);

        // Handle error scenarios
        const message = "An error occurred while deleting the field.";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      } finally {
        // Hide loader
        this.setState({ getLoansLoader: false, loading: false });
      }
    } else {
      console.warn("Invalid index provided for deletion:", index);
    }
  };

  HIt = (idx) => {
    setTimeout(() => {
      console.log("setTimeout", Date().toLocaleString());
    }, 1000);
  };
  SaveData = async (index) => {
    const { selectedOption } = this.state;
    const updatedTabledata = [...this.state.tabledata];
    const updatedRow = [updatedTabledata[index]];
    const newIndex = index;
    if (index >= 0 && index < updatedTabledata.length) {
      console.log("updatedtabledata", updatedTabledata);
      this.setState({
        currentlyEditedRow: -1,
        isEditMode: "none",
        originalTabledata: [],
      });
      var senddata = [];
      senddata[updatedRow] = [
        {
          id: updatedTabledata[index].id,
          def: updatedTabledata[index].def,
          fieldcode: (updatedRow.fieldcode = `${this.state.originalfieldcode}${
            newIndex + 1
          }`),
          section: updatedTabledata[index].section,
          descp: updatedTabledata[index].descp,
          "ND1-ND4 allowed?": updatedTabledata[index]["ND1-ND4 allowed?"], // Ensure proper bracket notation for access
          "ND5 allowed?": updatedTabledata[index]["ND5 allowed?"], // Proper bracket notation
          format: updatedTabledata[index].format,
          fieldtype: updatedTabledata[index].fieldtype,
          datatype: updatedTabledata[index].datatype,
        },
      ];
    }
    this.setState({ getLoansLoader: true, loading: true, tabledata: [] });
    var data = {};
    data.AssetType = selectedOption;
    data.peers = this.state.peers;
    data.trigger = "true";
    data.token = this.state.token;
    data.tabledata = senddata[updatedRow];
    const APIResponse = await updatedefinition(data);
    console.log("SaveData", APIResponse.data);
    if (APIResponse.status === 200) {
      const message = "Updated Successfully";
      this.props.enqueueSnackbar(message, {
        variant: "info",
        autoHideDuration: 3000,
      });
      await this.getDefinitionAfterSave(selectedOption);
    } else {
    }
  };
  getDefinitionAfterSave = async (selectedOption) => {
    const APIResponse = await getdefinition({
      AssetType: selectedOption,
      peer: this.state.peer,
    });
    console.log("getDefinition", APIResponse.data);
    if (APIResponse.status === 200) {
      this.setState({
        getLoansLoader: false,
        tabledata: APIResponse.data,
        displayData: APIResponse.data,
        originalfieldcode: APIResponse.data[0].fieldcode,
        originalsection: APIResponse.data[0].section,
        loading: false,
        LastUpdate: APIResponse.data[0].lastupdate,
      });
    } else if (APIResponse.status === 201) {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = APIResponse.data.message;
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      this.setState({
        getLoansLoader: false,
        loading: false,
      });
      const message = "Couldn't create the deal";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  Cancel = (index) => {
    const updatedTabledata = [...this.state.tabledata];
    const originalRowData = [...this.state.originalRowData];
    if (index >= 0 && index < updatedTabledata.length) {
      updatedTabledata[index] = { ...originalRowData[index] };
      this.setState({
        tabledata: updatedTabledata,
        displayData: updatedTabledata,
        currentlyEditedRow: -1,
        isEditMode: "none",
      });
    }
  };
  handleAddFields = () => {
    const selectedAssetClass = this.state.selectedOption;
    const currentCounter = this.state.assetClassCounters[selectedAssetClass];
    this.setState((prevState) => ({
      assetClassCounters: {
        ...prevState.assetClassCounters,
        [selectedAssetClass]: currentCounter + 1,
      },
    }));
    const newRow = {
      id: "",
      def: "",
      fieldcode: "",
      section: "",
      descp: "",
      "ND1-ND4 allowed?": "",
      "ND5 allowed?": "",
      format: "",
      fieldtype: "",
      datatype: "",
    };
    const totalRows = this.state.tabledata.length + 1;
    const rowsPerPage = 10;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const newPage = totalPages - 1;
    this.setState({ currentPage: newPage });
    this.setState((prevState) => ({
      tabledata: [...prevState.tabledata, newRow],
      displayData: [...prevState.tabledata, newRow],
    }));
  };
  onchange = (e) => {
    e.preventDefault();
    const searchText = e.target.value;
    this.setState({ searchText: searchText });
    console.log("search: e.target.value", this.state.searchText);
  };

  onCloseModal = () => {
    this.setState({ open1: false });
  };

  onCloseModaledit = () => {
    this.setState({ open2: false });
  };

  handleAddFields = () => {
    // Open the modal and reset the newField state
    this.setState({
      open1: true,
      newField: {
        id: "",
        def: "",
        fieldcode: "",
        section: "",
        descp: "",
        "ND1-ND4 allowed?": "",
        "ND5 allowed?": "",
        format: "",
        fieldtype: "",
        datatype: "",
      },
    });
  };

  handleFieldChangeInModal = (fieldName, value) => {
    // Update the specific field in the modal
    this.setState({
      newField: {
        ...this.state.newField,
        [fieldName]: value,
      },
    });
  };

  handleSaveField = () => {
    const { newField, tabledata } = this.state;

    // Validation: Check if required fields are filled
    if (
      !newField.def ||
      !newField.section ||
      !newField.descp ||
      !newField["ND1-ND4 allowed?"] ||
      !newField["ND5 allowed?"] ||
      !newField.format ||
      !newField.fieldtype ||
      !newField.datatype
    ) {
      this.props.enqueueSnackbar("Please fill all fields before saving.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    // Add the new field to the table data
    this.setState(
      {
        tabledata: [...tabledata, newField],
        displayData:[...tabledata, newField],
        open1: false, // Close the modal
      },
      () => {
        // Call the SaveData method for the new field
        this.SaveData(tabledata.length); // Pass the new field's index
      }
    );
  };

  handleSaveEditedField = () => {
    const { currentlyEditedRow, tabledata, newField } = this.state;
    if (
      !newField.def ||
      !newField.section ||
      !newField.descp ||
      !newField["ND1-ND4 allowed?"] ||
      !newField["ND5 allowed?"] ||
      !newField.format ||
      !newField.fieldtype ||
      !newField.datatype
    ) {
      this.props.enqueueSnackbar("Please fill all fields before saving.", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    // Update the specific row in the table
    const updatedTabledata = [...tabledata];
    updatedTabledata[currentlyEditedRow] = { ...newField };

    // Save the updated data and close the modal
    this.setState(
      {
        tabledata: updatedTabledata,
        displayData:updatedTabledata,
        open1: false,
        currentlyEditedRow: -1,
      },
      () => {
        // Call the SaveData method for the edited row
        this.SaveData(currentlyEditedRow);
      }
    );
  };
  onCloseModal2 = () => {
    this.setState({ open3: false }); // Close the delete confirmation modal
  };

  handleFilter = (tableName, data, columnKey, criteria) => {
    const { filterCriteria, tabledata, currentBatch, itemsPerBatch } =
      this.state;

    const newCriteria = { ...filterCriteria, [columnKey]: criteria };
    this.setState({ filterCriteria: newCriteria });

    // Check if all criteria are empty
    const isCriteriaEmpty = Object.values(newCriteria).every(
      (criteria) => !criteria || !criteria.value1
    );

    if (isCriteriaEmpty) {
      // Reset to the first batch of the full dataset if no criteria are selected
      this.setState({
        displayData: tabledata.slice(0, currentBatch * itemsPerBatch),
        filteredClrChangeColumns: {
          ...this.state.filteredClrChangeColumns,
          [columnKey]: false,
        },
      });
      return;
    }

    // Apply filters
    const filtered = tabledata.filter((item) => {
      return Object.keys(newCriteria).every((key) => {
        const criteria = newCriteria[key];
        if (!criteria) return true;

        const { condition1, value1, operator, condition2, value2 } = criteria;

        const applyCondition = (value, condition, target) => {
          switch (condition) {
            case "equals":
              return value === target;
            case "less_than":
              return value < target;
            case "greater_than":
              return value > target;
            case "not_equals":
              return value !== target;
            default:
              return false;
          }
        };

        const condition1Met = applyCondition(item[key], condition1, value1);
        const condition2Met = applyCondition(item[key], condition2, value2);

        return operator === "AND"
          ? condition1Met && condition2Met
          : condition1Met || condition2Met;
      });
    });

    console.log({ filtered });

    // Apply sorting after filtering
    const { sortOrder } = this.state;
    const sortedFiltered = [...filtered].sort((a, b) => {
      const valueA = a[sortOrder.columnKey];
      const valueB = b[sortOrder.columnKey];

      if (!isNaN(valueA) && !isNaN(valueB)) {
        return sortOrder.order === "ascend" ? valueA - valueB : valueB - valueA;
      }

      if (!isNaN(Date.parse(valueA)) && !isNaN(Date.parse(valueB))) {
        return sortOrder.order === "ascend"
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
      }

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder.order === "ascend"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return 0;
    });

    // Update display data to only show the current batch
    this.setState({
      displayData: sortedFiltered.slice(0, currentBatch * itemsPerBatch),
      filteredClrChangeColumns: {
        ...this.state.filteredClrChangeColumns,
        [columnKey]: !!criteria,
      },
    });
  };

  handlePaginationChange = (page) => {
    this.setState({ currentPage: page });
  };

  render() {
    const handleEditField = (rowIndex) => {
      const rowData = this.state.tabledata[rowIndex];
      this.setState({
        open2: true, // Open the modal
        currentlyEditedRow: rowIndex, // Keep track of the row being edited
        newField: { ...rowData }, // Pre-fill the modal with the row's data
      });
    };

    const handleDeleteField = (rowIndex) => {
      const rowData = this.state.tabledata[rowIndex];
      this.setState({
        open3: true, // Open the delete confirmation modal
        deleteRowVal: rowData, // Store the row data to display in the modal
        currentlyDeletedRow: rowIndex, // Keep track of the row being deleted
      });
    };
    const { displayData, currentPage, pageSize, loading, tableData } =
      this.state;

    // Paginate data manually
    const paginatedData = displayData.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
    const { open1, open2 ,newField, tabledata } = this.state;
    const { open3, deleteRowVal, formLoader } = this.state;
    const options1 = [
      "Residential Real Estate",
      "Commercial Mortgage",
      "Corporate Loans",
      "Auto Loans",
      "Consumer Loans",
      "Credit Cards",
      "Leasing",
      "Esoteric",
      "Non Performing Loans",
      "Default Fields",
    ];
    const options2 = [
      "Underlying exposures information section",
      "Collateral information section",
      "Tenant information section",
      "Historical collections information section",
      "Securitisation information section",
      "Cash-flow information section",
      "Asset Servicing Information",
    ];
    const { selectedOption } = this.state;
    const textFieldStyle = { width: "300px" };
    const columns = [
      {
        title: "Field Name",
        dataIndex: "def",
        key: "fieldName",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="def"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tabledata || [], "def")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["def"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div className="input-text">{value}</div>,
      },
      {
        title: "Field Code",
        dataIndex: "fieldcode",
        key: "fieldCode",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="fieldcode"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tabledata || [],
              "fieldcode"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["fieldcode"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => (
          <div className="custom-header">
            <strong>{value}</strong>
          </div>
        ),
      },
      {
        title: "Section",
        dataIndex: "section",
        key: "section",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="section"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tabledata || [],
              "section"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["section"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => <div className="input-text">{value}</div>,
      },
      {
        title: "Content To Report",
        dataIndex: "descp",
        key: "contentToReport",
        render: (value) => <div className="content-admin">{value}</div>,
      },
      {
        title: "ND1-ND4 allowed?",
        dataIndex: "ND1-ND4 allowed?",
        key: "nd1ToNd4Allowed",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="ND1-ND4 allowed?"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tabledata || [],
              "ND1-ND4 allowed?"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["ND1-ND4 allowed?"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => (
          <div className="custom-header-new">
            <strong>{value}</strong>
          </div>
        ),
      },
      {
        title: "ND5 allowed?",
        dataIndex: "ND5 allowed?",
        key: "nd5Allowed",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="ND5 allowed?"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tabledata || [],
              "ND5 allowed?"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["ND5 allowed?"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
        render: (value) => (
          <div className="custom-header-new">
            <strong>{value}</strong>
          </div>
        ),
      },
      {
        title: "Format",
        dataIndex: "format",
        key: "format",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="format"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(this.state.tabledata || [], "format")}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["format"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Field Type",
        dataIndex: "fieldtype",
        key: "fieldType",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="fieldtype"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tabledata || [],
              "fieldtype"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["fieldtype"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Data Type",
        dataIndex: "datatype",
        key: "dataType",
        filterDropdown: ({ setSelectedKeys, confirm, clearFilters, close }) => (
          <CustomFilterComponent
            columnKey="datatype"
            onSort={this.handleSort} // Replace with your sorting logic
            onFilter={this.handleFilter} // Replace with your filtering logic
            columnValues={getUniqueValues(
              this.state.tabledata || [],
              "datatype"
            )}
            closeDropdown={close}
          />
        ),
        filterIcon: () => (
          <img
            src={fIlter}
            alt="Custom Filter Icon"
            style={{
              width: "15px", // Adjust size as needed
              height: "10px",
              color: this.state.filteredClrChangeColumns["datatype"]
                ? "#018e82"
                : undefined, // Optional: Apply conditional styling
            }}
          />
        ),
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record, index) => (
          <div style={{ display: "flex", gap: "1rem" }}>
            <img
              alt=""
              src={edit}
              style={{ cursor: "pointer" }}
              height="25px"
              width="25px"
              onClick={() => handleEditField(index)}
            />
            <img
              alt=""
              src={dElete}
              style={{ cursor: "pointer" }}
              height="25px"
              width="25px"
              onClick={() => handleDeleteField(index)}
            />
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="page">
          <Sidebar1 activeComponent={"SetUpDashBoard"} />
          <div className="content">
            <div className="header">{/* <Header></Header> */}</div>
            <div className="row row1">
              <div
                className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", paddingBottom: "1.6rem" }}>
                  <h3 className="headerdashboard-mastertape">
                    Intain Master Tape
                  </h3>
                  <div>
                    {" "}
                    <h5 className="paymentdatestdfields">Asset Class:</h5>
                    <div>
                      <select
                        className="input-select-general-stdfields-assetclass"
                        value={selectedOption}
                        onChange={this.handleAssetClassChange}
                        label="Select an Asset Class"
                      >
                        {/* Add a dummy option */}
                        <option value="" disabled>
                          Select an Asset Class
                        </option>
                        {options1.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <h5 className="paymentdatelastupdate">Last Update:</h5>
                    <div>
                      <input
                        className="input-select-general-stdfields-lastupdate"
                        placeholder="Last Update"
                        value={this.state.LastUpdate}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="search-bar-outer-container uw-search-button-container-stdfields">
                    {this.state.showSearchBox == false && (
                      <button
                        className="search-mui-icons"
                        type="button"
                        onClick={this.onClickSearchButton}
                      >
                        <SearchOutlinedIcon />
                      </button>
                    )}
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleAddFields}
                      >
                        Add Fields
                      </Button>

                      <ReactModal
                        isOpen={open1}
                        onRequestClose={() => this.setState({ open1: false })}
                        contentLabel="Add Fields"
                        style={customStylesauto}
                      >
                        <div className="modalPopup">
                          <h2>Add New Field</h2>
                          <button
                            type="button"
                            style={{ minWidth: "30px" }}
                            className="closePopup"
                            onClick={() => this.setState({ open1: false })}
                          >
                            <CloseIcon></CloseIcon>{" "}
                          </button>

                          <div className="modalshiftcontent">
                            <form className="form-container">
                              <div className="input-container">
                                <label className="label">Field Name</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newField.def}
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "def",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="input-container">
                                <label className="label">Section</label>
                                <select
                                  value={newField.section}
                                  className="input-select"
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "section",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="" disabled>
                                    Select a Section
                                  </option>
                                  {options2.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="input-container">
                                <label className="label">Description</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newField.descp}
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "descp",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="input-container">
                                <label>ND1-ND4 allowed?</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newField["ND1-ND4 allowed?"]}
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "ND1-ND4 allowed?",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="input-container">
                                <label>ND5 allowed?</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newField["ND5 allowed?"]}
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "ND5 allowed?",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="input-container">
                                <label>Format</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newField.format}
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "format",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>

                              <div className="input-container">
                                <label>Field Type</label>
                                <input
                                  type="text"
                                  className="input"
                                  value={newField.fieldtype}
                                  onChange={(e) =>
                                    this.handleFieldChangeInModal(
                                      "fieldtype",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="modalsubmit">
                                <div className="submitbuttonbg">
                                  <div className="row">
                                    <div className="row justify-content-end1">
                                      <button
                                        type="button"
                                        className="popupbutton22"
                                        onClick={() =>
                                          this.setState({ open1: false })
                                        }
                                      >
                                        Cancel
                                      </button>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={this.handleSaveField}
                                      >
                                        Add
                                        {this.state.formLoader === true ? (
                                          <CircularProgress
                                            size="22px"
                                            color="primary"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </ReactModal>
                    </>
                  </div>
                </div>
              </div>
            </div>

            {selectedOption && (
              <React.Fragment>
                <div>
                  <Table
                    className="stdfields-antd"
                    dataSource={paginatedData.map((item, index) => ({
                      ...item,
                      key: index, // Add a unique key for each row
                    }))}
                    columns={columns} // Use the refactored Ant Design-compatible columns
                    pagination={false}
                    scroll={{ x: "max-content", y: 600 }} // Enable horizontal and vertical scroll
                    rowKey={(record) => record.key} // Use unique key for rows
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "23px",
                      overflow: "hidden",
                    }}
                  >
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={displayData.length}
                      onChange={this.handlePaginationChange}
                    />
                  </div>
                  <ReactModal
                    isOpen={open2}
                    onRequestClose={() => this.setState({ open2: false })}
                    contentLabel="Edit Field"
                    style={customStylesauto}
                  >
                    <div className="modalPopup">
                      <h2>Edit Field</h2>
                      <button
                        type="button"
                        style={{ minWidth: "30px" }}
                        className="closePopup"
                        onClick={() => this.setState({ open2: false })}
                      >
                        <CloseIcon></CloseIcon>{" "}
                      </button>
                      <div className="modalshiftcontent">
                        <form className="form-container">
                          <div className="input-container">
                            <label>Field Name</label>
                            <input
                              type="text"
                              className="input"
                              value={newField.def}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "def",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label>Section</label>
                            <select
                              className="input-select"
                              value={newField.section}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "section",
                                  e.target.value
                                )
                              }
                            >
                              <option value="" disabled>
                                Select a Section
                              </option>
                              {options2.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="input-container">
                            <label>Description</label>
                            <input
                              type="text"
                              className="input"
                              value={newField.descp}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "descp",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label>ND1-ND4 allowed?</label>
                            <input
                              type="text"
                              className="input"
                              value={newField["ND1-ND4 allowed?"]}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "ND1-ND4 allowed?",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label>ND5 allowed?</label>
                            <input
                              type="text"
                              className="input"
                              value={newField["ND5 allowed?"]}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "ND5 allowed?",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label>Format</label>
                            <input
                              type="text"
                              className="input"
                              value={newField.format}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "format",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div className="input-container">
                            <label>Field Type</label>
                            <input
                              type="text"
                              className="input"
                              value={newField.fieldtype}
                              onChange={(e) =>
                                this.handleFieldChangeInModal(
                                  "fieldtype",
                                  e.target.value
                                )
                              }
                            />
                          </div>

                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end1">
                                  <button
                                    type="button"
                                    className="popupbutton22"
                                    onClick={() =>
                                      this.setState({ open2: false })
                                    }
                                  >
                                    Cancel
                                  </button>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={this.handleSaveEditedField}
                                  >
                                    Save
                                    {this.state.formLoader === true ? (
                                      <CircularProgress
                                        size="22px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </ReactModal>
                  <>
                    {/* Delete Confirmation Modal */}
                    <ReactModal
                      isOpen={open3}
                      onRequestClose={this.onCloseModal2}
                      contentLabel="Delete Confirmation"
                      style={customStylesautosmallmodal1}
                    >
                      <div className="modalPopup">
                        <h3 className="popupheading">
                          Are you sure you want to delete this field
                        </h3>

                        <div className="modalshiftcontent">
                          <div className="modalsubmit">
                            <div className="submitbuttonbg">
                              <div className="row">
                                <div className="row justify-content-end11">
                                  {/* Cancel Button */}
                                  <button
                                    type="button"
                                    className="popupbutton1"
                                    onClick={this.onCloseModal2}
                                  >
                                    No
                                  </button>

                                  {/* Delete Confirmation Button */}
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.dealCreationDelete}
                                  >
                                    Yes, Delete it
                                    {formLoader ? (
                                      <CircularProgress
                                        size="25px"
                                        color="primary"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ReactModal>
                  </>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withSnackbar(StdFields);
